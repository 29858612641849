import Avatar from 'avatar-initials';

export const defaultAvatar = 'https://secure.gravatar.com/avatar/00000000000000000000000000000000?s=60&d=mm&r=g&f=y';

export function personAvatar(person, supportDisplayEmail, fallback = defaultAvatar) {
  if (supportDisplayEmail) {
    return Avatar.gravatarUrl({ email: supportDisplayEmail });
  }
  if (person && person.email) {
    return Avatar.gravatarUrl({ email: person.email });
  }
  return fallback;
}

export function personName(person, fallback = 'Anonymous') {
  if (person && (person.name || person.email)) {
    return person.name || person.email;
  }
  return fallback;
}

export default {
  defaultAvatar,
  personAvatar,
  personName,
};
