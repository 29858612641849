import { createSelector } from 'reselect';

export const getUi = state => state.ui || {};
export const getUiAppDrawerOpen = createSelector(
  getUi,
  ui => ui.appDrawerOpen || false
);
export const getUiCreateQuickResponseModalOpen = createSelector(
  getUi,
  ui => ui.createQuickResponseModalOpen || false
);
export const getUiDownloadsToastVisible = createSelector(
  getUi,
  ui => ui.downloadsToastVisible || false
);
export const getUiDownloadsToastMessage = createSelector(
  getUi,
  ui => ui.downloadsToastMessage || ''
);
export const getDisplayDownloadCenterWelcome = createSelector(
  getUi,
  ui => ui.displayDownloadCenterWelcome || false
);
