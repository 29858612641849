import React from 'react';
import PropTypes from 'prop-types';

const ColData = ({ children, className, title }) => (<td className={`col col--data ${className || ''}`} title={title}>{children}</td>);

ColData.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default ColData;
