/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { featureSettings } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';
import { AppMemberRole } from 'src/reducers/apps.types';
import DownloadCenterNav from '../download_center_nav';
import { ProfileMenu } from '../profile_menu';
import { history } from '../../../store/history';
import AlchemerNavMenu from '../alchemer-nav-menu/alchemer-nav-menu';
import { Icon } from '../../molecules/icons';

// styles
import '../../styles/header.scss';
import '../../styles/profile_menu.scss';
import '../../styles/notifications_menu.scss';

const isActiveLink = (regPath: string) => (_match: never, location: Record<string, any>) =>
  !!location.pathname.match(regPath);

const noop = () => {};

export interface HeaderDisplayProps {
  acknowledgeDownloadCenterWelcome: () => void;
  appClick: () => void;
  appIconUrl: string;
  appId: string;
  appTitle: string;
  avatarUrl: string;
  currentUserId: string;
  displayDownloadCenterWelcome: boolean;
  downloadsToastVisible: boolean;
  handleMouseLeave: () => void;
  hasSeenDownloadCenter: boolean;
  icon: string;
  mode: string;
  notifications: { id: string }[];
  orgName: string;
  platform: string;
  downloadsToastMessage: { msg: string; stats: string };
  numNewDownloads: number;
  isUserLoading: boolean;
  hasNotDownloadedFsExport: boolean;
  updateUIState: (key: string, value: string) => void;
  role: string;
}

interface IState {
  profileMenuOpen: boolean;
  mobileMenuOpen: boolean;
}

class HeaderDisplay extends Component<HeaderDisplayProps> {
  state: IState = {
    profileMenuOpen: false,
    mobileMenuOpen: false,
  };

  profileMenuRef = createRef<HTMLButtonElement>();

  closeProfileMenu = () => {
    this.setState({ profileMenuOpen: false });
  };

  openProfileMenu = () => {
    if (this.state.profileMenuOpen) {
      return;
    }

    this.setState({ profileMenuOpen: true });
  };

  toggleMobileMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  };

  render() {
    const {
      acknowledgeDownloadCenterWelcome = noop,
      appTitle = '',
      downloadsToastVisible = '',
      downloadsToastMessage = '',
      appId = '',
      appClick = noop,
      appIconUrl = '',
      avatarUrl = '',
      currentUserId = '',
      displayDownloadCenterWelcome = '',
      handleMouseLeave = noop,
      hasSeenDownloadCenter = '',
      icon = '',
      mode = '',
      numNewDownloads = '',
      orgName = '',
      platform = '',
      isUserLoading = '',
      hasNotDownloadedFsExport = '',
      updateUIState,
      role = '',
    } = this.props;

    const { mobileMenuOpen, profileMenuOpen } = this.state;

    const hasAlchemerNavMenu = featureSettings.get(featureSettings.Flag.GRID_NAV);

    const isReporter = role === AppMemberRole.Reporter;

    return (
      <header className="header" key={`${appId}-${orgName}`}>
        <div className="nav-left-content">
          {hasAlchemerNavMenu && <AlchemerNavMenu appId={appId} />}
          <div className="app-info">
            {mode !== 'rollup' ? (
              <>
                <div className={`app-icon ${icon}`} onMouseEnter={appClick} onMouseLeave={handleMouseLeave}>
                  <img src={appIconUrl} alt="app icon" />
                </div>
                <div className="app-title" onMouseEnter={appClick} onMouseLeave={handleMouseLeave}>
                  <div className="app-name">{appTitle || '[Unknown Project]'}</div>
                  <div className="app-org">
                    {platform || ''}
                    {` - ${orgName}` || ''}
                  </div>
                </div>
              </>
            ) : (
              <a className="back-link" role="link" onClick={history.goBack}>
                Go back to previous project
              </a>
            )}
          </div>
        </div>
        <nav className={classNames({ 'mobile-menu-open': mobileMenuOpen })}>
          {mode !== 'rollup' ? (
            <>
              <button className={'button-normalize mobile-menu-link'} onClick={this.toggleMobileMenu}>
                Menu
              </button>
              <NavLink
                className="nav-link"
                to={`/apps/${appId}/dashboard`}
                isActive={isActiveLink('dashboard|fan_signals')}
              >
                Summary
              </NavLink>
              <NavLink
                className="nav-link"
                to={`/apps/${appId}/insights`}
                isActive={isActiveLink('insights')}
                gtm-id={GtmID.InsightsSection}
              >
                Insights
              </NavLink>
              {!isReporter && (
                <NavLink
                  className="nav-link"
                  to={`/apps/${appId}/conversations`}
                  isActive={isActiveLink('conversations|group_messages')}
                >
                  Conversations
                </NavLink>
              )}
              <NavLink
                className="nav-link"
                gtm-id={GtmID.InteractionSection}
                to={`/apps/${appId}/interactions`}
                isActive={isActiveLink(
                  'interactions|surveys|events|groups|prompts|love-prompt|love-dialogs|ratings_prompt|interaction-styles',
                )}
              >
                Interactions
              </NavLink>
              {!isReporter && (
                <NavLink
                  className="nav-link"
                  to={`/apps/${appId}/settings`}
                  isActive={isActiveLink('settings|quick_responses')}
                >
                  Settings
                </NavLink>
              )}
            </>
          ) : (
            <NavLink className="nav-link" to={'/apps'} isActive={isActiveLink('apps')}>
              All Projects
            </NavLink>
          )}
        </nav>
        <div className="account-info">
          <Icon name="alchemerLogo" className="alchemer-logo" />
          <DownloadCenterNav
            acknowledgeDownloadCenterWelcome={acknowledgeDownloadCenterWelcome}
            downloadsToastMessage={downloadsToastMessage}
            downloadsToastVisible={downloadsToastVisible}
            displayDownloadCenterWelcome={displayDownloadCenterWelcome}
            hasSeenDownloadCenter={hasSeenDownloadCenter}
            isActiveLink={isActiveLink}
            numNewDownloads={numNewDownloads}
            hasNotDownloadedFsExport={hasNotDownloadedFsExport}
            isUserLoading={isUserLoading}
            currentUserId={currentUserId}
            updateUIState={updateUIState}
          />
          <button
            className={classNames('button-normalize', 'account-profile')}
            onClick={this.openProfileMenu}
            ref={this.profileMenuRef}
          >
            <img src={avatarUrl} className="fs-hide" alt="" width="30px" height="30px" />
            {profileMenuOpen && (
              <ProfileMenu
                isOpen={profileMenuOpen}
                onClose={this.closeProfileMenu}
                ref={this.profileMenuRef.current as any}
              />
            )}
          </button>
        </div>
      </header>
    );
  }
}

export default HeaderDisplay;
