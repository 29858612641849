import { Rest, API } from 'src/api';
import { getNoteWithUploadedImage } from 'src/utils/s3_upload';
import { NoteItem, NoteActionType, NoteModel } from 'src/reducers/notes';
import { BaseService } from './base-service';

type NoteUpdatePayload = Omit<NoteItem, 'actions'> & {
  actions_attributes: NoteItem['actions'],
  is_image_removed?: boolean,
};

export class NotesService extends BaseService {
  static async save(appId: string, note: NoteItem): Promise<NoteItem> {
    let noteToUpload: NoteItem = { ...note };
    const apiVersion = NoteModel.getApiVersion(note);
    const hasFileToUpload = NoteModel.hasImageFile(note.image) && !NoteModel.isImageAlreadyUploaded(note.image);
    if (hasFileToUpload) {
      noteToUpload = await getNoteWithUploadedImage(appId, noteToUpload) as NoteItem;
    }

    const body = prepareNestedAttributes(NoteModel.getParsedHtmlFieldsBeforeSave(noteToUpload));

    if (body.id) {
      if (!body.image) {
        body.is_image_removed = true;
      }

      // https://github.com/apptentive/pupum/blob/61b5a1b27c7ee3ad1ebf09bcdfe9bda2360cdda5/legacy/assets/models/note.js#L107
      return Rest.httpPut(API.PROMPTS.UPDATE(appId, body.id as string, apiVersion), {
        body,
        errorTitle: '[API] Error PUT UPDATE prompt item',
      }).promise;
    }
    return Rest.httpPost(API.PROMPTS.CREATE(appId, apiVersion), {
      body,
      errorTitle: '[API] Error POST CREATE prompt item',
    }).promise;
  }

  static activate(appId: string, noteId: string, active: boolean, apiVersion: string): Promise<NoteItem> {
    return Rest.httpPut(API.PROMPTS.UPDATE(appId, noteId, apiVersion), {
      body: { active },
      errorTitle: '[API] Error PUT activate prompt item',
    }).promise;
  }

  static fetchNotes(appId: string): Promise<NoteItem[]> {
    return Rest.httpGet(API.PROMPTS.ALL(appId), {
      errorTitle: '[API] Error GET notes list',
    }).promise;
  }

  static fetchNote(appId: string, noteId: string): Promise<NoteItem> {
    return Rest.httpGet(API.PROMPTS.GET(appId, noteId), {
      errorTitle: '[API] Error GET prompt item',
    }).promise;
  }

  static deleteNote(appId: string, noteId: string): Promise<any> {
    return Rest.httpDelete(API.PROMPTS.DELETE(appId, noteId), {
      errorTitle: '[API] Error DELETE prompt item',
    }).promise;
  }

  static cloneNoteToApp(appId: string, noteId: string, options: { cloneTargeting: boolean }): Promise<NoteItem> {
    return Rest.httpPost(API.PROMPTS.CLONE(appId, noteId), {
      body: { options },
      errorTitle: '[API] Error POST clone prompt to appId',
    }).promise;
  }
}

function prepareNestedAttributes({ actions, ...note }: NoteItem): NoteUpdatePayload {
  const actions_attributes = actions.filter((action) => action.interaction_type !== NoteActionType.Cancel);
  const data = {
    ...note,
    actions_attributes,
  };
  return data;
}
