import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ColAction from './col_action';

class RowData extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    index: PropTypes.number,
    interactive: PropTypes.bool,
    toggleSelected: PropTypes.func,
  };

  static defaultProps = {
    checked: false,
    disabled: false,
    hidden: false,
  };

  handleInputClick = () => (
    this.props.toggleSelected(this.props.index, !this.props.checked)
  );

  render() {
    const { children, interactive, className, checked, disabled, hidden } = this.props;
    const attributes = { checked, disabled, hidden };

    return (
      <tr className={`${className} row row--data`}>
        {interactive && (
          <ColAction>
            <input type="checkbox" {...attributes} onChange={this.handleInputClick} />
          </ColAction>
        )}
        {children}
      </tr>
    );
  }
}

export default RowData;
