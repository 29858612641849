import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppMember, AppMemberRole } from 'src/reducers/apps.types';
import { State } from 'src/reducers';

// Selectors
import { createOrganization, fetchOrganizations, fetchOrganizationMembers } from 'src/actions/organizations';
import { createApp, clearNewApp } from 'src/actions';
import { getCurrentUserId, getFeatures } from 'src/selectors/user';
import { getDashboardNewAppId } from 'src/selectors/dashboard';
import { getCurrentOrgMembers, getOrgs, getOrgRemainAppsById } from 'src/selectors/organizations';

import analytics from 'src/data/analytics';
import AttachmentDrawer from 'src/components/forms/attachment_drawer';
import AppStoreIdTypeahead from 'src/components/molecules/appStoreIdTypeahead';
import gguid from 'src/utils/gguid';
import { history } from 'src/store/history';
import { RemainAppsCountContainer } from 'src/components/remain-apps-count';

import { Features } from 'src/types/core';
import { Organization, OrganizationMember } from 'src/reducers/organizations';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import { getCurrentAppId, getCurrentAppMembershipRole } from '../../../selectors/current_app';
import { getLabelKey } from './utils';

import '../../../components/styles/onboarding.scss';

const noop = () => {};

interface AppMembers {
  name?: string;
  email?: string;
  role?: AppMemberRole;
  user_id?: string | undefined;
}
interface App {
  platform: string;
  title: string;
  store_id: string | null;
  icon_url: string;
  upload_icon: {
    data: string | ArrayBuffer | null;
    name: string;
    type: string;
  } | null;
  organization_id?: string;
  app_members?: AppMembers[];
}
export interface AppNewProps {
  currentUserId: string;
  features: Features[];
  newAppId: string;
  organizations: Record<string, Organization>;
  organzationMembers: OrganizationMember[];
  role: string;
  currentAppId: string;
  clearNewApp(appId: string): void;
  createApp(orgId: string, data: any): void;
  createOrganization(data: any): void;
  fetchOrganizationMembers(userId: any, orgId: any): void;
  fetchOrganizations(userId: any): void;
  getSelectedOrgRemainAppsLimitById(orgId: string): number | null;
}

interface initialAppState {
  errors: string[];
  showNew: boolean;
  storeApp: Record<string, any> | null;
  appMembers: string[];
  teamMembers: Omit<AppMember, 'user_id'>[];
  title: string;
  selectedOrgId: string;
  uploadIcon: {
    data: string | ArrayBuffer | null;
    name: string;
    type: string;
  } | null;
  submitting: boolean;
}

const initialAppState = {
  errors: [],
  showNew: false,
  storeApp: null,
  appMembers: [],
  teamMembers: [],
  title: '',
  selectedOrgId: '',
  uploadIcon: null,
  submitting: false,
};

export const AppNew: React.FC<AppNewProps> = ({
  currentUserId,
  features,
  newAppId,
  organizations,
  organzationMembers,
  currentAppId,
  role,
  clearNewApp = noop,
  createApp = noop,
  createOrganization = noop,
  fetchOrganizationMembers = noop,
  fetchOrganizations = noop,
  getSelectedOrgRemainAppsLimitById = noop,
}) => {
  const [state, setState] = useState<initialAppState>(initialAppState);
  const [selectedPlatform, setSelectedPlatform] = useState('');

  useEffect(() => {
    setPageTitleOnMount('Create a new Project', { oldTitle: 'Create a new Project' });
    analytics.trackPageView('/apps/new');
    fetchOrganizations(currentUserId);
  }, [currentUserId, fetchOrganizations]);

  useEffect(() => {
    if (newAppId) {
      clearNewApp(newAppId);
      history.replace(`/apps/${newAppId}/dashboard`);
    }
  }, [newAppId, clearNewApp]);

  useEffect(() => {
    const isReporter = role === AppMemberRole.Reporter;
    if (isReporter) {
      history.replace(`/apps/${currentAppId}/dashboard`);
    }
  }, [role, history, currentAppId]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    setState((prevState) => ({ ...prevState, submitting: true, errors: [] }));
    if (state.showNew) {
      if (!(event.target as any).elements.organization_name.value) {
        setState((prevState) => ({
          ...prevState,
          errors: ['Company Name is required.', ...prevState.errors],
          submitting: false,
        }));
        return;
      }
    } else if (!(event.target as any).elements?.organization_id?.value) {
      setState((prevState) => ({
        ...prevState,
        errors: ['Company is required.', ...prevState.errors],
        submitting: false,
      }));
      return;
    }
    if (!(event.target as any).elements.platform.value) {
      setState((prevState) => ({
        ...prevState,
        errors: ['Platform is required.', ...prevState.errors],
        submitting: false,
      }));
      return;
    }
    if (!state.title) {
      setState((prevState) => ({
        ...prevState,
        errors: ['Project Name is required.', ...prevState.errors],
        submitting: false,
      }));
      return;
    }

    analytics.trackEvent('onboarding', 'click', 'completing setup', '1');

    const app: App = {
      platform: (event.target as any).elements.platform.value,
      title: state.title,
      store_id: state.storeApp ? state.storeApp.store_app_id : null,
      icon_url: 'good-mark',
      upload_icon: {
        data: '',
        name: '',
        type: '',
      },
      organization_id: state.showNew ? undefined : (event.target as any).elements.organization_id.value,
      app_members: [],
    };

    // Icon: Upload, AppStore or nothing for the default
    /* istanbul ignore else */
    if (state.uploadIcon) {
      app.upload_icon = state.uploadIcon;
    } else if (state.storeApp && state.storeApp.icon_url) {
      app.icon_url = state.storeApp.icon_url;
    }

    const appMembers: AppMembers[] = [];

    // Build App Members for New Members
    state.teamMembers.forEach((member) => {
      const { name, email, role } = member;
      if (email && name && role) {
        appMembers.push({ name, email, role });
      }
    });

    // Build App Members from Existing Members
    state.appMembers.forEach((user_id) => {
      const foundMember = (organzationMembers.find((member: OrganizationMember) => member.user_id === user_id) ||
        {}) as OrganizationMember;
      const name = foundMember ? foundMember.name : '';
      const email = foundMember ? foundMember.email : '';
      const role = (event.target as any).elements[`role_${user_id}`].value;
      /* istanbul ignore else */
      if (email && name && role) {
        appMembers.push({ name, email, role, user_id });
      }
    });

    if (state.showNew) {
      // Create Org & App
      const organization = {
        name: (event.target as any).elements.organization_name.value,
        apps_attributes: [app],
        app_members: appMembers,
      };
      createOrganization(organization);
    } else {
      // Create App Only
      const orgId = (event.target as any).elements.organization_id.value;
      app.organization_id = orgId;
      app.app_members = appMembers;
      createApp(orgId, app);
    }
  };

  const setIconFile = (files: any[] = []) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setState((prevState) => ({
          ...prevState,
          uploadIcon: {
            data: (reader.result as string).replace(/^data:image\/\w+;base64,/, ''),
            name: files[0].name || 'icon',
            type: files[0].type,
          },
        }));
      };
      reader.onerror = (error) => {
        console.error('Error reading file: ', error);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onChangeOrgId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target?.value || '';
    setState((prevState) => ({ ...prevState, selectedOrgId: selectedValue }));

    if (selectedValue) {
      fetchOrganizationMembers(currentUserId, selectedValue);
    }
  };

  const toggleShowing = useCallback(() => {
    setState((prevState) => ({ ...prevState, showNew: !prevState.showNew }));
  }, [state.showNew, setState]);

  const toggleAppMember =
    (id: string): React.ChangeEventHandler<HTMLInputElement> =>
    (_event) => {
      setState((prevState) => {
        const isIdInMembers = prevState.appMembers.includes(id);
        const updatedMembers = isIdInMembers
          ? prevState.appMembers.filter((member) => member !== id)
          : [...prevState.appMembers, id];

        return {
          ...prevState,
          appMembers: updatedMembers,
        };
      });
    };

  const autocompleteInput = (title = '') => {
    setState((prevState) => ({ ...prevState, title }));
  };

  const autocompleteSelect = (storeApp: any, selectedPlatform: string) => {
    if (storeApp) {
      if (storeApp.platform && storeApp.platform === 'android') {
        selectedPlatform = 'Android';
      } else if (storeApp.platform && storeApp.platform === 'itunes') {
        selectedPlatform = 'iOS';
      }
      setState((prevState) => ({ ...prevState, storeApp, title: storeApp.app_name }));
      setSelectedPlatform(selectedPlatform);
    }
  };

  const addTeamMember = () => {
    setState((prevState) => ({
      ...prevState,
      teamMembers: [...prevState.teamMembers, { id: gguid(), name: '', email: '', role: AppMemberRole.Collaborator }],
    }));
  };

  const updateTeamMember = (id: string, field: string) => {
    let timeoutId: NodeJS.Timeout | null = null;

    return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const updatedMembers = state.teamMembers.map((member) => {
        if (member.id === id) {
          return { ...member, [field]: event.target.value };
        }
        return member;
      });

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          teamMembers: updatedMembers,
        }));
      }, 200);
    };
  };

  const appsLimit = getSelectedOrgRemainAppsLimitById(state.selectedOrgId);
  const appsLimitReached = appsLimit !== null && typeof appsLimit === 'number' ? appsLimit <= 0 : false;

  return (
    <div className="onboarding-container auth new-app-container">
      <div className="container">
        <RemainAppsCountContainer orgId={state.selectedOrgId} />
        <div className="content-block-border content-block-welcome">
          <h1>New Project</h1>
        </div>
        <form acceptCharset="UTF-8" className="app" id="app" name="app" autoComplete="off" onSubmit={handleSubmit}>
          <div className="form-section application-info content-block-border">
            <h2>General Info</h2>
            <label htmlFor="platform">Platform</label>
            <select
              className="platform"
              name="platform"
              value={selectedPlatform}
              required
              onChange={(e) => setSelectedPlatform(e.target.value)}
            >
              <optgroup label="Platform">
                <option disabled value="">
                  Select a Platform...
                </option>
                <option value="iOS">iOS</option>
                <option value="Android">Android</option>
                {features.includes('web_sdk') ? <option value="Web">Web</option> : null}
              </optgroup>
            </select>
            <label htmlFor="title">
              Project Name
              <span className="info">More projects can be added later.</span>
            </label>
            <div className="search-container">
              <AppStoreIdTypeahead
                handleInputChange={autocompleteInput}
                handleOnOptionSelected={autocompleteSelect}
                labelKey={getLabelKey}
                placeholder="Search for your App"
                platform={selectedPlatform}
              />
              <p>We will use your icon from the AppStore, if available.</p>
            </div>
            <div className="icon-block">
              <h2>Upload Icon (Optional)</h2>
              <AttachmentDrawer
                description={'Accepts .PNG, .GIF and .JPG format images under 1MB.'}
                accept={'.gif,.png,.jpg,.jpeg'}
                fileLimit={1}
                multiple={false}
                setFiles={setIconFile}
                title={'Drop the icon here or browse below.'}
                maxSize={1048576}
              />
            </div>
            <div className="org-selection">
              <label htmlFor="organization_name" className={`creating-org ${state.showNew ? '' : 'hide'}`}>
                <button className="org-source button-normalize" type="button" onClick={toggleShowing}>
                  Select Existing Company
                </button>
                <span>Company Name</span>
                <div className="control">
                  <input type="text" placeholder="Company Name" name="organization_name" />
                </div>
              </label>
              <label htmlFor="organization_id" className={`selecting-org ${state.showNew ? 'hide' : ''}`}>
                {features.includes('create_new_app_org') ? (
                  <button className="org-source button-normalize" type="button" onClick={toggleShowing}>
                    Create a New Company
                  </button>
                ) : null}
                <span>Company Name</span>
                <div className="input">
                  <select
                    className="org-dropdown block"
                    aria-invalid="false"
                    value={state.selectedOrgId}
                    name="organization_id"
                    onChange={onChangeOrgId}
                  >
                    <option disabled value="">
                      Select a Organization...
                    </option>
                    {Object.keys(organizations).map((orgId) => (
                      <option key={orgId} value={orgId}>
                        {organizations[orgId].name}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            </div>
          </div>
          <div className="form-section team-members content-block-border">
            <h2>Invite Your Team</h2>
            <div className="existing-team-members">
              {organzationMembers.map((member) => (
                <div className="team-member-row" key={member.user_id}>
                  <input
                    type="checkbox"
                    value={member.email}
                    className="app-member-checkbox"
                    onChange={toggleAppMember(member.user_id)}
                  />
                  <input
                    className="member-name-short fs-hide"
                    placeholder="Name"
                    type="text"
                    readOnly
                    value={member.name}
                  />
                  <input
                    className="member-email fs-hide"
                    placeholder="Email"
                    type="email"
                    readOnly
                    value={member.email}
                  />
                  <select className="role" name={`role_${member.user_id}`}>
                    <option value="collaborator">Collaborator</option>
                    <option value="admin">Admin</option>
                    <option value={AppMemberRole.Reporter}>Reporter</option>
                  </select>
                </div>
              ))}
            </div>
            <hr />
            <div className="team-member-rows">
              {state.teamMembers.map((member) => (
                <div className="team-member-row" key={member.id}>
                  <input
                    className="member-name fs-hide"
                    placeholder="Name"
                    type="text"
                    name="member[name][]"
                    onChange={updateTeamMember(member.id, 'name')}
                  />
                  <input
                    className="member-email fs-hide"
                    placeholder="Email"
                    type="email"
                    name="member[email][]"
                    onChange={updateTeamMember(member.id, 'email')}
                  />
                  <select className="role" name="member[role][]" onChange={updateTeamMember(member.id, 'role')}>
                    <option value="collaborator">Collaborator</option>
                    <option value="admin">Admin</option>
                    <option value={AppMemberRole.Reporter}>Reporter</option>
                  </select>
                </div>
              ))}
            </div>
            <button className="add-team-member button-normalize" type="button" onClick={addTeamMember}>
              Add more team members
            </button>
          </div>
          <div className="form-section content-block">
            {state.errors.length ? (
              <div className="error-text">
                {state.errors.map((error) => (
                  <p key={error}>{error}</p>
                ))}
              </div>
            ) : null}
            <button className="create primary large wide" type="submit" disabled={state.submitting || appsLimitReached}>
              Create the new Project
            </button>
          </div>
        </form>
        <div className="content-block content-block-bottom align-center">
          <p className="terms-of-service">
            <span>By joining, you agree to Apptentive&apos;s </span>
            <a href="https://www.apptentive.com/terms/">Terms & Conditions, </a>
            <a href="https://www.apptentive.com/privacy/">Privacy Policy, </a>
            <a href="https://www.apptentive.com/privacy/#cookies">Cookie Policy.</a>
          </p>
        </div>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (
  state: State,
): Omit<
  AppNewProps,
  'clearNewApp' | 'createApp' | 'createOrganization' | 'fetchOrganizations' | 'fetchOrganizationMembers'
> => ({
  currentUserId: getCurrentUserId(state),
  features: getFeatures(state),
  newAppId: getDashboardNewAppId(state),
  organizations: getOrgs(state),
  organzationMembers: getCurrentOrgMembers(state),
  getSelectedOrgRemainAppsLimitById: (orgId: string) => getOrgRemainAppsById(state, orgId),
  currentAppId: getCurrentAppId(state),
  role: getCurrentAppMembershipRole(state),
});

const mapDispatchToProps = (
  dispatch: Function,
): Pick<
  AppNewProps,
  'clearNewApp' | 'createApp' | 'createOrganization' | 'fetchOrganizations' | 'fetchOrganizationMembers'
> => ({
  clearNewApp: (appId: string) => dispatch(clearNewApp(appId)),
  createApp: (orgId: string, data: any) => dispatch(createApp(orgId, data)),
  createOrganization: (data: any) => dispatch(createOrganization(data)),
  fetchOrganizations: (userId: any) => dispatch(fetchOrganizations(userId)),
  fetchOrganizationMembers: (userId: any, orgId: any) => dispatch(fetchOrganizationMembers(userId, orgId)),
});

export const AppNewContainer = connect(mapStateToProps, mapDispatchToProps)(AppNew);
AppNewContainer.displayName = 'AppNewContainer';
