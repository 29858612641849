import { useState, useEffect, SetStateAction, DependencyList } from 'react';

export function useAsyncFetchEffect<T = any>(asyncFunc: () => Promise<T | undefined>, inputs: DependencyList) {
  const [controller] = useState(() => new AbortController());
  const [loading, setIsLoading] = useState(true);
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    const fetch = async () => {
      if (!loading) {
        setIsLoading(true);
      }

      try {
        const fetchedData = await asyncFunc();
        setData(fetchedData as SetStateAction<T>);
      } finally {
        if (!controller.signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    fetch();
    return () => controller.abort();
  }, [...inputs]);

  return {
    loading,
    data,
    setData,
  };
}
