const regionMap: Record<string, string> = {
  AE: 'UAE',
  AG: 'Antigua And Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AR: 'Argentina',
  AT: 'Austria',
  AU: 'Australia',
  AZ: 'Azerbaijan',
  BB: 'Barbados',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BJ: 'Benin',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CG: 'Congo',
  CH: 'Switzerland',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CV: 'Cape Verde',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  ES: 'Spain',
  FI: 'Finland',
  FJ: 'Fiji',
  FM: 'Micronesia',
  FR: 'France',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GH: 'Ghana',
  GM: 'Gambia',
  GR: 'Greece',
  GT: 'Guatemala',
  GW: 'Guinea-bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HR: 'Croatia',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  IS: 'Iceland',
  IT: 'Italy',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KN: 'St. Kitts and Nevis',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Lao Peoples Democratic Republic',
  LB: 'Lebanon',
  LC: 'St. Lucia',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MD: 'Moldova',
  MG: 'Madagascar',
  MK: 'Macedonia',
  ML: 'Mali',
  MN: 'Mongolia',
  MO: 'Macao',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NE: 'Niger',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russia',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SE: 'Sweden',
  SG: 'Singapore',
  SI: 'Slovenia',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SN: 'Senegal',
  SR: 'Suriname',
  ST: 'Sao Tome & Principe',
  SV: 'El Salvador',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos',
  TD: 'Chad',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VC: 'St. Vincent & The Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VN: 'Vietnam',
  YE: 'Yemen',
  ZA: 'South Africa',
  ZW: 'Zimbabwe',
  'L:AF': 'Afrikaans',
  'L:AM': 'Amharic',
  'L:AR': 'Arabic',
  'L:AZ': 'Azerbaijani',
  'L:BE': 'Belarusian',
  'L:BG': 'Bulgarian',
  'L:BN': 'Bengali',
  'L:CA': 'Catalan',
  'L:CS': 'Czech',
  'L:DA': 'Danish',
  'L:DE': 'German',
  'L:EL': 'Greek',
  'L:EN': 'English',
  'L:ES': 'Spanish',
  'L:ET': 'Estonian',
  'L:EU': 'Basque',
  'L:FA': 'Persian',
  'L:FI': 'Finnish',
  'L:FL': 'Filipino',
  'L:FIL': 'Filipino',
  'L:FR': 'French',
  'L:GL': 'Galician',
  'L:HE': 'Hebrew',
  'L:HI': 'Hindi',
  'L:HR': 'Croatian',
  'L:HU': 'Hungarian',
  'L:HY': 'Armenian',
  'L:ID': 'Indonesian',
  'L:IS': 'Icelandic',
  'L:IT': 'Italian',
  'L:JA': 'Japanese',
  'L:KA': 'Georgian',
  'L:KM': 'Central Khmer',
  'L:KN': 'Kannada',
  'L:KO': 'Korean',
  'L:KY': 'Kirghiz',
  'L:LO': 'Lao',
  'L:LT': 'Lithuanian',
  'L:LV': 'Latvian',
  'L:MK': 'Macedonian',
  'L:ML': 'Malayalam',
  'L:MN': 'Mongolian',
  'L:MR': 'Marathi',
  'L:MS': 'Malay',
  'L:MY': 'Burmese',
  'L:NE': 'Nepali',
  'L:NL': 'Dutch',
  'L:NO': 'Norwegian',
  'L:PL': 'Polish',
  'L:PT': 'Portuguese',
  'L:RM': 'Romansh',
  'L:RO': 'Romanian',
  'L:RU': 'Russian',
  'L:SI': 'Sinhala',
  'L:SK': 'Slovak',
  'L:SL': 'Slovenian',
  'L:SR': 'Serbian',
  'L:SV': 'Swedish',
  'L:SW': 'Swahili',
  'L:TA': 'Tamil',
  'L:ZH': 'Chinese',
  'L:ZH-HANS': 'Chinese',
  'L:ZH-HANTT': 'Chinese',
};

export const isCountryExists = (regionKey: string) => !!regionMap[regionKey.toUpperCase()]
export const getRegionMap = (regionKey: string) => regionKey ? (regionMap[regionKey.toUpperCase()] || '') : ''

export default regionMap;
