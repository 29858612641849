import React from 'react';
import { connect } from 'react-redux';

import { fetchOrganizationApps } from 'src/actions/organizations';
import { getCurrentAppId, getCurrentAppMembershipRole, getCurrentOrgId } from 'src/selectors/current_app';
import { getCurrentUserId } from 'src/selectors/user';
import { getOrgsPure, getOrgRemainAppsById } from 'src/selectors/organizations';
import { getUiAppDrawerOpen } from 'src/selectors/ui';
import { State } from 'src/reducers';

import { AppDrawerDisplay, AppDrawerDisplayProps } from './app_drawer_display';

const mapStateToProps = (state: State) => ({
  appDrawerOpen: getUiAppDrawerOpen(state),
  currentApp: getCurrentAppId(state),
  organizations: getOrgsPure(state),
  userId: getCurrentUserId(state),
  remainAppsCount: getOrgRemainAppsById(state, getCurrentOrgId(state)),
  currentAppRole: getCurrentAppMembershipRole(state),
});

const handleClickOutside = () => ({ type: 'APP_DRAWER_TOGGLE' });
const mapDispatcherToProps = {
  handleClickOutside,
  fetchOrganizationApps,
};

type StateProps = 'appDrawerOpen' | 'currentApp' | 'remainAppsCount' | 'organizations' | 'userId' | 'currentAppRole';

export const AppDrawerContainer = connect<
  Pick<AppDrawerDisplayProps, StateProps>,
  Omit<AppDrawerDisplayProps, StateProps>
>(
  mapStateToProps,
  mapDispatcherToProps
)(AppDrawerDisplay);
