import React from 'react';
import { connect } from 'react-redux';
import { State } from 'src/reducers';
import { getOrgRemainAppsById } from 'src/selectors/organizations';
import { usePopoverProps } from 'src/components/molecules';
import { AppLimitPopover } from 'src/components/app_drawer/app-limit-popover';
import { bemPrefix } from 'src/utils';

import './remain-apps-count.scss';

export interface RemainAppsCountProps {
  className?: string;
  orgId: string;
  remainAppsCount: number | null;
}

const bem = bemPrefix('remain-apps-count');

export const RemainAppsCount: React.FC<RemainAppsCountProps> = ({ className = '', orgId, remainAppsCount }) => {
  const { isOpenPopover, targetRef, showPopover, closePopover } = usePopoverProps<HTMLDivElement>();

  if (!orgId || remainAppsCount === null) {
    return null;
  }

  return (
    <div
      className={`${bem('', { limitReached: remainAppsCount <= 0 })} ${className}`}
      ref={targetRef}
      onClick={showPopover}
    >
      <span className={bem('value')}>{remainAppsCount}</span>
      <span className={bem('text')}>apps remaining</span>
      {isOpenPopover && <AppLimitPopover targetEl={targetRef.current} onClose={closePopover} />}
    </div>
  );
};

RemainAppsCount.displayName = 'RemainAppsCount';

export const RemainAppsCountContainer = connect<
  Omit<RemainAppsCountProps, 'orgId' | 'className'>,
  null,
  Pick<RemainAppsCountProps, 'orgId' | 'className'>
>((state: State, { orgId }) => ({
  remainAppsCount: getOrgRemainAppsById(state, orgId),
}))(RemainAppsCount);
