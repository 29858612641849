import React from 'react';
import { ToastContainer, ToastContainerProps, toast, ToastContent } from 'react-toastify';
import { Icon } from '../molecules';
import { bemPrefix } from '../../utils';

import 'react-toastify/dist/ReactToastify.css';
import './notification-toasts.scss';

const bem = bemPrefix('notification-toasts');

export const NotificationToasts: React.FC<ToastContainerProps> = (props) => (
  <ToastContainer
    className={bem()}
    closeButton={<Icon name="close" className={bem('close-btn')} />}
    newestOnTop
    {...props}
  />
);

export const notify = {
  info: (message: ToastContent) => toast.info(Message({ title: getTitle('info'), message })),
  success: (message: ToastContent) => toast.success(Message({ title: getTitle('success'), message })),
  error: (message: ToastContent) => toast.error(Message({ title: getTitle('error'), message }), { autoClose: false }),
};

function Message({ title, message }: { title: React.ReactNode; message: ToastContent }) {
  if (Array.isArray(message) && typeof message[0] === 'string') {
    message = message.map((text) => <div>{text}</div>);
  }

  return (
    <div className={bem('content')}>
      {title && <h3 className={bem('title')}>{title}</h3>}
      {message && <div className={bem('message')}>{message as never}</div>}
    </div>
  );
}

function getTitle(type: keyof typeof notify): React.ReactNode {
  switch (type) {
    case 'success':
      return (
        <>
          <Icon name="success" className={bem('title-icon')} />
          <span>Success</span>
        </>
      );
    case 'error':
      return (
        <>
          <Icon name="error" className={bem('title-icon')} />
          <span>Error</span>
        </>
      );
    case 'info':
      return (
        <>
          <Icon name="info" className={bem('title-icon')} />
          <span>Info</span>
        </>
      );
    default:
      return type;
  }
}
