import { Store, Action, Dispatch } from 'redux';
import { StoreActions } from './store-actions';

export function storeActionsMiddleware(_store: Store) {
  const emitter = StoreActions.getInstance();
  return (next: Dispatch<Action>) => (action: Action) => {
    emitter.emit(action);
    return next(action);
  };
}
