try {
  // Create the CSS
  const styleText = document.createTextNode(`@keyframes snow {
    0% { background-position: 0px 0px, 0px 0px, 0px 0px }
    100% { background-position: 500px 1000px, 400px 400px, 300px 300px; }
  }
  body.heart-snow {
    background-image:
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsCAMAAABOo35HAAAAclBMVEX////fJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjepqKlSAAAAJnRSTlMAKlhkSAoLUTtaTAQ8VkYUFWFgKxAdZjEWSVcGCCA5XENEAwUfMq8Mq3MAAAGmSURBVHgB7MGDFcBAFATAjW3b/beYEv49BzsDIiIiei1NN0wLpMJ2XM3zIaMgjOIEaQYZhXkRemWVQ0Z1A7RJZ4JkZl8NGOsJJBtmfVm3ZYcKOs7Vu+727tAKgBAGoiD9V41cH/PNTAUI4L3sJccDAAC5lFxKLiWXkkshl5JLyaUAQE2sJlYTq4nVxKiJ1cRq4gEAAAAAAAAArXNa57TOaZ3TOqd1Dq1zWucAAADJoeRQcig5lBwiOZQcSg4BAJAvyZfkS/Il5EvyJfmSfAkAABEVIioRlYhKRCWiElEhogIlAUoCJYGS4HBdKAl2XSgJLteFkuB+XSgJJAgSBNeFBAEAAAAAAAAA9Pxa9Zr4dCo3TXyHHRKsOmjiC3bIVt038SX7Olh10MQX7Outum/ii/Z1sOqgiS/Y11u15lTsEAAAAAAAAAAAAAAAAAAAAPDPLbyz653dnQfv7J7Pg4czD+fBw5mH8+DfRDsP/k3kIVnnwb+6AAAA8BXEVxBfQaS+DV9BpL5SX6kvAAAAAAAAAAAAgFEHow5GHYw6GHXAqINRB6MOtQ94Mjmhl1gM3AAAAABJRU5ErkJggg=='),
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQCAMAAAC3Ycb+AAABF1BMVEUAAAD/AACAAADfIED/AADfJTjeJja/QEDVK0DjHDnVKyv/MzPeJzbfJzfdJjfZJjneITfgJjfdIjPfKzXfJjbeJjjeJzbcIzXYJzvfJjcAAADbJDfeJjbgJTjhJjffJjfdIjncIzngJzfeJzfgJzbMMzPfJTjjKzngJDbcIzrfJTfeJjffIDDgJzbfJjjhIzfeJjnfJDffJjffJzbgJTfdJTjfJTjfJzjfJzjgJDjPMEDjKDndJDfdJjfdJTbdJTbeJTbeJzjfJTbdKTfeJzfdJDjgJTbhJjneLDf/AEDcIzTfJDjeJDfhJjjeJjfgJTjVKyvdJjXfJjjqK0DcIzriJDrgKTjjKTfhHjz/QEDeJTb/MzPoLi5G4HM6AAAAXXRSTlMAAQIIAm4vBAwJBgV1cDwbF3MPGH9lVToNeQEObFJ+fi0kYn1jBWgSOSxhcxBCXzM2cIFoU1Jgd2gyEC1iallabXxZJVxpdUMXBCxAVGVrKQw1gAwWIzIlEQR7CgtQz104AAADHUlEQVR4AezBg4EDARAAwD3bjm07b9v+9F9IujjuDAAQnJA3eZEGFAtEc1B991hNoCEGECG2K8VC3xlqIsQAoqyxK5fK9QbLExA9pKu14MNoGUzHpCF6qNuzd4ozUhjvgIboocl0Nl8sV+vN1icgeujwKDg+OT07v7ikCIgestSra+bGlm/v7iEGEKU/PD49v7ivbyTEACKlz69v9uf37x9iAeVIjuJ9gdu3d+dGCANBAARPj6cMIDTIPw6weD3eMboDkKUqVHt7wzQqmM4GAAAAAAAAAAAAAAAAXE3TPO1GBNeejZdCzyb1UujZiBw9mQo9G5Gjqy3Qswm8FBnTIdCzCbwUGdP+Dz2bwEuRNR3/0LMJvBRd2x96NoGXomt97tn0Xwo9m/JLoWcTeCn0bESOvBQAAAAAAAAAAAAAAAAAAIDAIAKDqpMIDKpOIjCoOonAoOokAoMIDKpOIjAoMAgA4FAVh6oOVXGo6lAVh6oOVXGoikNVh6o4VHWoikNVh6oAAAAAAAB2pe1KY1cau9J2pbErbVcau9J2pbErbVcau9KGS+/vShsu2ZU2XMJwyXAJwyXDJQyXDJcwXDJcwnDJqMlwqTlqMlwyajJqwqjJqGn49jFq6n/7GDX1v32MmgrfPkZNgW8fo6bAt49RU/KYzaipesxm1OSYDd8+jtnw7aN3jW8fAAAAAAAAAAAAAAAApRj826dSDCp5SjF+dFKlGD86gVKMH51AKcaPTqwU08qTKcXE8mRKMa08mVJMLk+mFNPPkynFyJPJk/XJkyFPJk8GAADWwgLPtBaWeqa1sNYzrYW1nmktrPVMa2GxZ1oLaz3TWljsmdbCYs+0FlZ7phP62DOd0Nee6YQ+9kwn9E79AQAAAAAAAAAAAAAUKOjXIlCLUKBALUKB4h2oRShQoBahQPE+1CL8RwRqEQoUAAAAAAAAAAAAgIvELhLjIjEuErtIjIvELhLjIrGLxLhI7CIxLhLjIrGLxAAAAAAAAAAAAAAAAAAAgBQiUohSiEghIoUohYgUohQiUohSiEghSiEihYgUIgAAAAAnNJAn4aX8a8kAAAAASUVORK5CYII='),
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAH0CAMAAAD8CC+4AAAAz1BMVEUAAADfJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJjffJje/BohOAAAARXRSTlMAX8rw6KwpAYL/9uMhg9ZwGznBqgRYjWgyFPIOuPoGyfvXVFq9huvg/T5/0mel+AU/TMwHJ/zlDNgweZRAwmvTHWnDMYVUY7+6AAAD/0lEQVR4Ae3dRWK0RwwE0DJbZmZmZvzNcP8zZXaBZTjp9y7xjWpK6vALAADQ1d3T29cf2jEwWDVUNTwS2jE6Nj4xOVXToREzs3PzC0kWe5fSBpZXqmp1bi1Z30gbGKzNre2d2s3E0l5ow/7BYZKj45zUaWjDWc95Oi4yVZehDVc1f52Om9u7+9CGy9t6GH1MftRTaMXz9FnVy+tbHYZ2vH98VtXDeZrC1/fDUdoFAAAAAAAAlnKwlIOlHCzlYCkHSzlYysFSDpZysJSDpRws5WApBwAAAAAAAAAAAAAAnM3B2RyczcHZHJzNwdkcnM3B2RyczcHZHGdzcDYHZ3MAANC+QPsC7Qu0L9C+QPsC7Qu0L9C+QPsC7QsRp/aFiFP7QsSJiBMRJyJORJx/nIgTESciTkSciDgRcQIAAACoKKKiiIoiKoqoKKKiiIqi0UtF0ejVQEXR6KWiaPRSUTR6qSgavVQU/zlGL4xeGL0wemH0sh2G7TAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAASLq6e3r7+tMOBgarhqqGR9IORsfGJyanajqNYGZ2bn4hyWLvUtrA8kpVrc6tJesbaQODtbm1vVO7mVjaSxvYPzhMcnSckzpNGzjrOU/HRabqMm3gquav03Fze3efNnB5Ww+jj8mPekoreJ4+q3p5favDtIP3j8+qejhPU/j6fjjKvwoAAAAAgNYyWstoLaO17FOstexTrLXsU6y17FOstWyB6L/SWhY7/PZT3EBrWezw209xA61lscNvP8WNtJbFDr/9FGst/4diB59iy9IWiCxLWyASO1ggsiyN3zr4rWNZWvZrWVr2i+wX2S+yX1TOUDlD5Uz2i+wX2S+yX2S/AAAAAAAA4AARDhBhCQVLKFhCwRIKllCwhIIlFCyhWELBEgqWUAAAQP8E/RP0T9A/Qf8E/RP0T9A/Qf8E/RPhmP6JcEz/RDimfyIcQziGcAzhGMIxhGMIxxCOIRxDOAYAAAAAAAAAAAAAAAAAAACei8JzUXguykVcXxMXcX1NXMT1NXER1311F3HdV3cR1311F3HdV//vX8R1X91FXPfVXcR1Xx331XFf3dcEAAAAAAAAAAAAAAAAAAAAAAAAAAAAALw3ifcm8d4k3pv0ejFeL8brxXi9mL9kUPbepEHZe5MGZe9NGpS9N2lQ9t6kQRmDsjHGoGyMMSj7v6+BQdkYY1D2f59B2RhjUDbGYIzBGIMxBmOMRNMYI9E0xkg0kWgi0USiiURToolEE4kmEk0UM5BoAgAAAAAAAAAAAAAA4D4T7jPhPhPuM+E+E+4z4T4T7jO5z4T7TLR1nwn3mXCfCQAAAAAAAAAAAAAAAADgJ8aKB7gJG/RxAAAAAElFTkSuQmCC');
    animation: 20s infinite linear snow;
  }`);
  // Create and add the <style> tag to the header
  const style = document.createElement('style');
  style.appendChild(styleText);
  document.head.appendChild(style);

  // Only the keys we need to check for.
  const allowedKeys = {
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    65: 'a',
    66: 'b',
  };

  // The 'Official' Konami Code input sequence
  const konamiCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];

  // A counter to store the 'position' the user has reached
  let konamiCodePosition = 0;

  // Add keydown event listener
  document.addEventListener('keydown', (event) => {
    // get the value of the key code from the key map
    const key = allowedKeys[event.which || event.keyCode || event.charCode || 0];
    // get the value of the required key from the konami code
    const requiredKey = konamiCode[konamiCodePosition];

    // compare the key with the required key
    if (key === requiredKey) {
      // move to the next key in the konami code sequence
      konamiCodePosition += 1;

      // if the last key is reached, activate cheats
      if (konamiCodePosition === konamiCode.length) {
        // Add the class to the page
        document.body.classList.toggle('heart-snow');
        // Reset the counter
        konamiCodePosition = 0;
      }
    } else {
      // Reset the counter
      konamiCodePosition = 0;
      // Remove the class
      document.body.classList.remove('heart-snow');
    }
  });
} catch (e) {
  console.error('Easter Egg Error:', e);
}
