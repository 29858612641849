import React, { useState, useCallback, useEffect, useRef } from 'react';
import { USE_FORM_TRIGGER_EVENT } from 'src/hooks';

import { InputText, InputTextProps } from './input-text';

type InputValuedNumberProps = Pick<
  InputTextProps,
  | 'className'
  | 'min'
  | 'max'
  | 'value'
  | 'disabled'
  | 'onChange'
  | 'errorText'
  | 'label'
  | 'helpText'
  | 'step'
  | 'required'
  | 'onBlur'
  | 'preventDefault'
>;

const noop = () => null;

export const InputValuedNumber: React.FC<InputValuedNumberProps> = ({
  className,
  value,
  min = 0,
  onChange = noop,
  onBlur = noop,
  step = '1',
  required = true,
  ...props
}) => {
  const refInput = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState(value || '');

  const triggerUseFormEvent = useCallback(() => {
    if (refInput.current) {
      refInput.current.dispatchEvent(new Event(USE_FORM_TRIGGER_EVENT, { bubbles: true }));
    }
  }, [refInput.current]);

  const onChangeInput = useCallback(
    (data: React.SetStateAction<string | number | readonly string[]>) => {
      const shouldChange = data && Number(data) >= Number(min);
      if (shouldChange || data === '') {
        onChange(data);
      }
      setInputValue(data);
      if (!shouldChange) {
        triggerUseFormEvent();
      }
    },
    [onChange, triggerUseFormEvent],
  );

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <InputText
      className={`input-valued-number ${className}`}
      innerRef={refInput}
      {...props}
      type="number"
      min={min}
      step={step}
      value={inputValue}
      onChange={onChangeInput}
      required={required}
      onBlur={onBlur}
    />
  );
};
