import React from 'react';

import { Time } from 'src/utils';
import { InteractionType, getInteractionTypeLabel } from 'src/types/core';

import './response-summary.scss';

export interface InteractionResponseSummaryProps {
  className?: string;
  type: InteractionType;
  responseRate: string | number | undefined;
  responseRateCachedTime: string | undefined;
  responseCount: string | number;
}

const getRate = (value: InteractionResponseSummaryProps['responseRate'] = 0) => {
  const rate = Number(value) * 100;
  const dec = Math.max(4 - rate.toString().split('.')[0].length, 1);
  return `${rate.toFixed(dec)}%`.replace(/\.000|\.00/g, '.0');
};

export const InteractionResponseSummary: React.FC<InteractionResponseSummaryProps> = ({
  className = '',
  type,
  responseRate,
  responseRateCachedTime,
  responseCount,
}) => (
  <div className={`response-summary ${className}`}>
    <div className="segment response_rate">
      <span>
        <span className="rate">{getRate(responseRate)}</span> {getInteractionTypeLabel(type)} Response Rate
      </span>
      {responseRateCachedTime && (
        <span className="time"> (as of about {Time.create(responseRateCachedTime).fromNow()})</span>
      )}
    </div>
    <div className="segment response_count">
      <span className="count">{responseCount}</span> Respondents
    </div>
  </div>
);
