import React from 'react';
import { Icon } from 'src/components/molecules';
import facts from '../utils/facts';
import './styles/loading.scss';

const EmptyWrapper = () => {
  const { fact, source } = facts[Math.floor(Math.random() * facts.length)];

  return (
    <div className="background-wrap">
      <div className="custom-alchemer-line" />
      <div className="loading-wrap">
        <Icon name="alchemerLoadFactLogo" className="loading-logo" />
        <div className="loading-fact">
          <span className="fact">{fact}</span>
          <span className="source">{source}</span>
        </div>
      </div>
    </div>
  );
};

export default EmptyWrapper;
