import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getOrgExpired,
  getOrgTier,
} from '../../selectors/organizations';

export class ValidateAccountPresentational extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]),
    expired: PropTypes.bool,
    tier: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    expired: false,
    tier: '',
  };

  componentDidMount() {
    this.checkExpired(this.props);
  }

  componentDidUpdate(_prevProps) {
    this.checkExpired(this.props);
  }

  checkExpired = (props) => {
    const { expired, tier } = props;
    if ((expired || tier === 'expired') && window.location.pathname !== '/trial-expired') {
      window.location.assign('/trial-expired');
    }
  };

  render() {
    const { expired, tier } = this.props;
    if ((expired || tier === 'expired') && window.location.pathname !== '/trial-expired') {
      return null;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  expired: getOrgExpired(state),
  tier: getOrgTier(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateAccountPresentational);
