import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AppMemberRole } from 'src/reducers/apps.types';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import historyProp from '../../proptypes/history-prop';

import { getCurrentAppMembershipRole } from '../../selectors/current_app';

import './styles/account.scss';
import '../../components/styles/molecules/tables.scss';

export class SubcontractorDisclosure extends React.Component {
  static displayName = 'SubcontractorDisclosure';

  static propTypes = {
    history: historyProp,
    role: PropTypes.string,
  };

  componentDidMount() {
    setPageTitleOnMount('Sub-contractor Disclosure', { oldTitle: 'Sub-contractor Disclosure | Alchemer' });
    const isReporter = this.props.role === AppMemberRole.Reporter;
    if (isReporter) {
      this.props.history.replace('/profile');
    }
  }

  render() {
    return (
      <div className="account index">
        <div className="subcontractors-container">
          <h2 className="separator-no-line">Alchemer Mobile Sub-contractors</h2>
          <p>
            <em>Alchemer Mobile Confidential - Distributed under NDA</em>
          </p>
          <p>
            Alchemer Mobile relies on a number of sub-contractors as part of providing our product and service to you.
            The majority of these sub-contractors do not have access to end-consumer data or to the infrastructure
            containing end-customer data. For a small number of sub-contractors, access to end-consumer data is
            necessary as part of providing the required functionality. The list below details all sub-contractors with
            access to end-consumer data, the categories of data accessed, and the business-need for the
            sub-contractor&apos;s access. Each sub-contractor has undergone a careful selection processes and is
            contractually held to materially equivalent security and data privacy standards and practices as established
            in your contract with Alchemer Mobile.
          </p>
          <ul>
            <li>
              <p>
                <em>Amazon Web Services</em> - All Alchemer Mobile infrastructure is hosted by Amazon Web Services
                within their US-EAST-1 (Virginia, USA) region. Amazon, by virtue of access to the physical and network
                infrastructure powering Alchemer Mobile, has access to all Alchemer Mobile data, including end-consumer
                data.
              </p>
            </li>
            <li>
              <p>
                <em>Go Interactive</em> - Several members of our engineering team are employed through Go Interactive
                and are based in Kiev, Ukraine. These engineers are responsible for feature development and operations,
                including participating in on-call rotation. As their roles necessitate, they have access to Alchemer
                Mobile infrastructure, data, and end-consumer data. These engineers undergo the materially equivalent
                hiring processes, background checks, employment agreements, security and job function training, and
                oversight as our direct, US-based employees.
              </p>
            </li>
            <li>
              <p>
                <em>Sendgrid</em> - Alchemer Mobile uses Sendgrid for email delivery. This includes emails containing
                end-consumer feedback, survey responses, and custom data to members of your team. When configured, this
                can also include email to end-consumers with your team&apos;s responses to their feedback. Sendgrid only
                has access to the data explicitly sent to it as part of email delivery.
              </p>
            </li>
            <li>
              <p>
                <em>CloudMailin</em> - Alchemer Mobile uses CloudMailin to process inbound email replies to feedback by
                your team. CloudMailin only has access to the content of these emails.
              </p>
            </li>
            <li>
              <p>
                <em>Sentry</em> - Alchemer Mobile uses Sentry for error tracking and reporting (exception handling).
                Sentry may incidentally receive end-consumer data only when and if an error occurs and end-consumer is
                included as part of the context of that error. For example, if an error occurred during request handling
                for an inbound survey reply, Sentry would capture the body of the request.
              </p>
            </li>
            <li>
              <p>
                <em>3rd Party Integrations</em> - Alchemer Mobile provides integrations with a number of 3rd party
                solutions including Zendesk, Salesforce, Slack, and others. These are optional integrations that your
                team can enable and configure. If you chose to do so, Alchemer Mobile will send the content of
                end-consumer feedback, including custom data as configured, to your instance within these systems. Note:
                We do not consider these systems to be sub-processors / sub-contractors and do not have an explicit data
                processing or confidentiality agreements in place with these sub-contractors.
              </p>
            </li>
            <li>
              <p>
                <em>Pusher</em> - Pusher is a tool used within the dashboard to provide notifications to users on the
                status of asynchronous server-side tasks (for example, file exports). No customer or consumer data is
                shared with this partner.
              </p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  role: getCurrentAppMembershipRole(state),
});

/* istanbul ignore next */
const Subcontractor = connect(mapStateToProps, {})(SubcontractorDisclosure);

export default Subcontractor;
