import React from 'react';
import PropTypes from 'prop-types';

import ColAction from './col_action';

import LeftArrow from '../assets/left-arrow.jsx.svg';
import RightArrow from '../assets/right-arrow.jsx.svg';

const RowAction = ({ children, navDisabled, has_next, has_prev, interactive, tableIndex, tableSize, total, togglePage }) => {
  const multiplier = 50 * tableIndex;
  const minPaginationNumber = multiplier + 1;
  const maxPaginationNumber = (multiplier + tableSize) > total ? total : multiplier + tableSize;

  return (
    <tr className="row row--action">
      {children}
      {interactive && (
        <ColAction colSpan={3}>
          <div className="button-container">
            {tableSize > 0 && <span>{minPaginationNumber} - { maxPaginationNumber} of {total}</span>}
            <button className="button-normalize page-prev" disabled={navDisabled || !has_prev} onClick={() => togglePage(false)} aria-label="Previous">
              <LeftArrow />
            </button>
            <button className="button-normalize page-next" disabled={navDisabled || !has_next || (maxPaginationNumber === total)} onClick={() => togglePage()} aria-label="Next">
              <RightArrow />
            </button>
          </div>
        </ColAction>
      )}
    </tr>
  );
};

RowAction.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  navDisabled: PropTypes.bool,
  has_next: PropTypes.bool,
  has_prev: PropTypes.bool,
  interactive: PropTypes.bool,
  tableIndex: PropTypes.number,
  tableSize: PropTypes.number,
  total: PropTypes.number,
  togglePage: PropTypes.func,
};

RowAction.defaultProps = {
  has_next: false,
  has_prev: false,
};

export default RowAction;
