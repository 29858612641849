import { zip } from 'ramda';
import { GetState } from 'src/reducers';
import { getCurrentOrgId } from 'src/selectors/current_app';
import { DruidService } from 'src/services/druid-service';
import { SdkVersion, ALL_SDK_VERSIONS, ReachByAppAndVersion, ReachByVersion } from 'src/types/core';
import { ReachActions, ReachDispatch } from './reach.action-types';

export const fetchSdkVersionReachByApps =
  (appIds: string[], sdkVersion: SdkVersion) => (dispatch: ReachDispatch, getState: GetState) => {
    const state = getState();
    const orgId = getCurrentOrgId(state);
    dispatch({
      type: ReachActions.FETCH_SDK_VERSION_REACH_BY_APPS_PENDING,
      meta: { orgId, sdkVersion },
      payload: { appIds },
    });
    return DruidService.fetchMultipleReachByVersion(orgId, appIds, sdkVersion)
      .then((json) =>
        dispatch({
          type: ReachActions.FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS,
          payload: json,
          meta: { orgId, sdkVersion },
        }),
      )
      .catch((error) =>
        dispatch({
          type: ReachActions.FETCH_SDK_VERSION_REACH_BY_APPS_FAILURE,
          meta: { orgId, appIds, sdkVersion },
          payload: { appIds },
          error,
        }),
      );
  };

export const fetchAllSdkVersionsReachByApps =
  (appIds: string[]) => async (dispatch: ReachDispatch, getState: GetState) => {
    const state = getState();
    const orgId = getCurrentOrgId(state);
    dispatch({ type: ReachActions.FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_PENDING, meta: { orgId }, payload: { appIds } });

    try {
      const allVersionReaches = zip(
        ALL_SDK_VERSIONS,
        await Promise.all(
          ALL_SDK_VERSIONS.map((sdkVersion) => DruidService.fetchMultipleReachByVersion(orgId, appIds, sdkVersion)),
        ),
      );

      const allVersionReachByApp: ReachByAppAndVersion = {};

      for (const [sdkVersion, reach] of allVersionReaches) {
        for (const { app_id, reach_percentage } of reach) {
          (allVersionReachByApp[app_id] ??= {} as ReachByVersion)[sdkVersion] = reach_percentage;
        }
      }

      dispatch({
        type: ReachActions.FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_SUCCESS,
        meta: { orgId },
        payload: allVersionReachByApp,
      });
    } catch (error) {
      dispatch({
        type: ReachActions.FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_FAILURE,
        meta: { orgId, appIds },
        payload: { appIds },
        error: error as Error,
      });
    }
  };
