import { Rest, API } from 'src/api';
import { CriteriaUnifiedTargeting } from 'src/interactions/newinteractions/targeting/multi-app-segment-builder/types';
import { BaseService } from './base-service';

export class UnifiedInteractionsService extends BaseService {
  static async getTargeting(orgId: string, unifiedInteractionId: string): Promise<CriteriaUnifiedTargeting> {
    return Rest.httpGet(API.UNIFIED_INTERACTIONS.GET_TARGETING(orgId, unifiedInteractionId), {
      errorTitle: '[API] Error GET unified interaction targeting',
    }).promise;
  }
}
