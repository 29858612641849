import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';
import { intersection, identity, uniq, values } from 'ramda';
import { Dialog, DownloadOption } from 'src/components/molecules';
import { FeatureFlag, featureSettings } from 'src/utils';
import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { ImtPermisson } from 'src/reducers/imt/imt.model';
import {
  TableInteractive,
  TableHead,
  TableBody,
  RowData,
  RowHeader,
  ColData,
  ColHeader,
} from '../../components/tables';

class DownloadsTable extends Component {
  static propTypes = {
    className: PropTypes.string,
    currentUserId: PropTypes.string,
    deleteDownload: PropTypes.func,
    downloads: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    retryDownload: PropTypes.func,
    setSelectedItems: PropTypes.func,
    showDeleteDownloads: PropTypes.func,
    startDownloads: PropTypes.func,
    initiateDownload: PropTypes.func,
    toggleDeleteDownloads: PropTypes.func,
    updateDownload: PropTypes.func,
    // eslint-disable-next-line
    imtSurveys: PropTypes.array,
    imtSurveysLoading: PropTypes.bool,
    // eslint-disable-next-line
    allApps: PropTypes.array,
  };

  static defaultProps = {
    className: '',
    currentUserId: '',
    deleteDownload: () => {},
    downloads: [],
    retryDownload: () => {},
    setSelectedItems: () => {},
    showDeleteDownloads: () => {},
    startDownloads: () => {},
    initiateDownload: () => {},
    toggleDeleteDownloads: () => {},
    updateDownload: () => {},
  };

  isImtFeatureEnabled = featureSettings.get(FeatureFlag.INTERACTIONS_MULTITARGET);

  state = {
    search: '',
    selected: [],
    sortBy: 'id',
    sortDirection: 'asc',
    tableFilter: {
      enjoymentDialog: true,
      interactions: true,
      starRating: true,
    },
  };

  toggleIndividualSelected = (index, checked) => {
    const { downloads, setSelectedItems } = this.props;
    const { selected } = this.state;
    let new_selections;

    if (typeof checked === 'boolean' && downloads[index]) {
      new_selections = checked ? [...selected, downloads[index].id] : selected.filter((i) => i !== downloads[index].id);
      new_selections = uniq(new_selections);
      this.setState({ selected: new_selections });
      setSelectedItems(new_selections);
    }
  };

  toggleSelectAll = () => {
    const { downloads, setSelectedItems } = this.props;
    const { selected } = this.state;

    if (downloads.length === selected.length) {
      this.setState({ selected: [] });
      setSelectedItems([]);
    } else {
      const new_selected = downloads.map((d) => d.id);
      this.setState({ selected: new_selected });
      setSelectedItems(new_selected);
    }
  };

  toggleSort = (sortBy, sortDirection) => {
    this.setState({ sortBy, sortDirection });
  };

  updateSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  clearSearch = () => {
    this.setState({ search: '' });
  };

  toggleFilterOpen = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  toggleFilterSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.toggleFilterOpen();
  };

  toggleFilterAll = (event) => {
    this.setState({
      tableFilter: {
        enjoymentDialog: event.target.checked,
        interactions: event.target.checked,
        starRating: event.target.checked,
      },
    });
  };

  toggleFilter = (key) => (event) => {
    this.setState({
      tableFilter: {
        ...this.state.tableFilter,
        [key]: event.target.checked,
      },
    });
  };

  sortDownloads = (sortBy, sortDirection, downloads) => {
    const lowerCaseSort = (key) => {
      return (a, b) => {
        if (sortDirection === 'asc') {
          return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
        }
        return b[key].toLowerCase().localeCompare(a[key].toLowerCase());
      };
    };

    const numberSort = (key) => {
      return (a, b) => {
        return sortDirection === 'asc' ? a[key] - b[key] : b[key] - a[key];
      };
    };

    const dateSort = (key) => {
      return (a, b) => {
        const date_a = new Date(a[key]);
        const date_b = new Date(b[key]);
        return sortDirection === 'asc' ? date_a - date_b : date_b - date_a;
      };
    };

    const sortFunctions = {
      filesize: numberSort('filesize'),
      date_requested: dateSort('date_requested'),
      period: (a, b) => {
        /* istanbul ignore next */
        if (!a.parameters) {
          a.parameters = {};
        } else if (typeof a.parameters === 'string') {
          a.parameters = JSON.parse(a.parameters);
        }
        /* istanbul ignore next */
        if (!b.parameters) {
          b.parameters = {};
        } else if (typeof b.parameters === 'string') {
          b.parameters = JSON.parse(b.parameters);
        }
        if (a.parameters.start_date > b.parameters.start_date) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        if (a.parameters.start_date < b.parameters.start_date) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
      },
      bundle: (a, b) => {
        /* istanbul ignore next */
        if (!a.parameters) {
          a.parameters = { stale: null };
        } else if (typeof a.parameters === 'string') {
          a.parameters = JSON.parse(a.parameters);
        }
        /* istanbul ignore next */
        if (!b.parameters) {
          b.parameters = { stale: null };
        } else if (typeof b.parameters === 'string') {
          b.parameters = JSON.parse(b.parameters);
        }
        const aSort = a.parameters.stale ? 1 : 0;
        const bSort = b.parameters.stale ? 1 : 0;
        return sortDirection === 'asc' ? aSort - bSort : bSort - aSort;
      },
      features: (a, b) => {
        /* istanbul ignore next */
        if (!a.parameters) {
          a.parameters = { sources: [] };
        } else if (typeof a.parameters === 'string') {
          a.parameters = JSON.parse(a.parameters);
        }
        /* istanbul ignore next */
        if (!b.parameters) {
          b.parameters = { sources: [] };
        } else if (typeof b.parameters === 'string') {
          b.parameters = JSON.parse(b.parameters);
        }
        /* istanbul ignore next */
        if (!a.parameters.sources) {
          a.parameters.sources = [];
        }
        /* istanbul ignore next */
        if (!b.parameters.sources) {
          b.parameters.sources = [];
        }
        return sortDirection === 'asc'
          ? a.parameters.sources.length - b.parameters.sources.length
          : b.parameters.sources.length - a.parameters.sources.length;
      },
      platform: lowerCaseSort('platform'),
      app_name: lowerCaseSort('app_name'),
      status: lowerCaseSort('status'),
      filename: lowerCaseSort('filename'),
    };

    return downloads.sort(sortFunctions[sortBy]);
  };

  getIsDownloadItemDisabled = ({ parameters, type }) => {
    const isImtSurveyExport = type === 'UnifiedSurvey';

    const { createdBy = '', interactionData = [] } =
      (this.props.imtSurveys || []).find((survey) => survey.id === parameters.unified_interaction_id) || {};

    return (
      isImtSurveyExport &&
      (this.props.imtSurveysLoading ||
        SurveyMultiAppsModel.getUserPermissions(
          { createdBy, interactionData },
          this.props.currentUserId,
          this.props.allApps,
        ) === ImtPermisson.HAS_ACCESS_TO_SOME_APPS)
    );
  };

  disableDownloadButton = (downloads = [], selected = []) => {
    if (selected.length === 0) {
      return true;
    }
    return selected.some((selected_id) => {
      const selectedDownload = downloads.find(({ id }) => id === selected_id);
      return (
        !selectedDownload || selectedDownload.status !== 'complete' || this.getIsDownloadItemDisabled(selectedDownload)
      );
    });
  };

  renderAppHealthFilters = () => {
    const { tableFilter } = this.state;

    return (
      <>
        <DownloadOption
          id="enjoymentDialog-xls-download"
          name="enjoymentDialog"
          onChange={this.toggleFilter('enjoymentDialog')}
          checked={tableFilter.enjoymentDialog}
        >
          Love Dialog
        </DownloadOption>
        <DownloadOption
          id="interactions-xls-download"
          name="interactions"
          onChange={this.toggleFilter('interactions')}
          checked={tableFilter.interactions}
        >
          Interactions
        </DownloadOption>
        <DownloadOption
          id="starRatings-xls-download"
          name="starRating"
          onChange={this.toggleFilter('starRating')}
          checked={tableFilter.starRating}
        >
          Star Ratings
        </DownloadOption>
      </>
    );
  };

  renderFilter = (className) => {
    const { isFilterOpen, tableFilter } = this.state;
    const allChildrenChecked = values(tableFilter).every(identity);

    let filterOptions;
    switch (className) {
      case 'app-health':
        filterOptions = this.renderAppHealthFilters();
        break;
      default:
        return null;
    }

    return (
      <>
        <button
          className="download-button filter"
          onKeyPress={this.toggleFilterOpen}
          onClick={this.toggleFilterOpen}
          title="Filter Table"
          ref={(ref) => (this.popupRef = ref)}
        />
        {isFilterOpen && (
          <Dialog
            isOpen={isFilterOpen}
            targetEl={this.popupRef}
            onClose={this.toggleFilterOpen}
            placement={Dialog.PopoverPlacement.bottomStart}
          >
            <div className="downloads-filter-picker">
              <h3>Display exports with:</h3>
              <form onSubmit={this.toggleFilterSubmit}>
                <div className="download-options">
                  <DownloadOption
                    id="allData-xls-download"
                    name="allData"
                    onChange={this.toggleFilterAll}
                    checked={allChildrenChecked}
                  >
                    Select All
                  </DownloadOption>
                  {filterOptions}
                </div>
              </form>
            </div>
          </Dialog>
        )}
      </>
    );
  };

  renderFanSignalsParams = (param) => {
    if (param && Array.isArray(param)) {
      const fsList = {
        Fan: 'NF',
        ReclaimedFan: 'SF',
        RepeatFan: 'RF',
        Opportunity: 'NR',
        LostFan: 'SR',
        RepeatOpportunity: 'RR',
      };
      return param.length === 6 ? 'ALL' : param.map((item) => fsList[item]).join(', ');
    }
    // Otherwise the parameter is FS export type
    const fsExportType = {
      CurrentStatus: 'Fan Signal by Customer',
      Summary: 'Fan Signal Buckets by Day',
      SignalsOverTime: 'Signals Over Time',
    };
    return fsExportType[param] || '';
  };

  renderStatus = (status) => {
    let status_icon = null;
    if (status === 'complete') {
      status_icon = <span className="state-complete" />;
    } else if (status === 'running') {
      status_icon = <span className="state-running">In Progress</span>;
    } else if (status === 'failed') {
      status_icon = <span className="state-error">Failed</span>;
      // TODO Reinstate Retry button when logic works
      // status_icon = <button className="state-error button-normalize" onClick={this.props.retryDownload(id)}>Retry</button>;
    }
    return status_icon;
  };

  renderRow = (download, index) => {
    const { className } = this.props;
    const { tableFilter, search } = this.state;
    const {
      app_name,
      id,
      platform,
      filename,
      date_requested,
      filesize,
      status,
      type,
      hasNewBadge,
      apps = [],
    } = download;
    const isImtSurveyExport = type === 'UnifiedSurvey';
    let parameters = typeof download.parameters === 'string' ? JSON.parse(download.parameters) : download.parameters;
    if (['Survey', 'AppRollup', 'Journey'].includes(type)) {
      parameters = parameters.report_params;
    }

    const attributes = {};
    if (this.state.selected.indexOf(id) !== -1) {
      attributes.checked = true;
    }

    if (search && search.length > 0) {
      if (
        !app_name.toLowerCase().includes(search.toLowerCase()) &&
        !filename.toLowerCase().includes(search.toLowerCase()) &&
        !platform.toLowerCase().includes(search.toLowerCase())
      ) {
        return null;
      }
    }

    // Download Type is not a known download we do not want to render it
    if (
      ![
        'Conversations',
        'Survey',
        'AppRollup',
        'AppHealth',
        'Translations',
        'FanSignal',
        'Journey',
        'UnifiedSurvey',
      ].includes(type)
    ) {
      return null;
    }

    // Logic to filter AppHealth features
    /* istanbul ignore else */
    if (type === 'AppHealth' && parameters && parameters.sources && Array.isArray(parameters.sources)) {
      const tableFeatures = Object.keys(tableFilter)
        .filter((k) => tableFilter[k])
        .map(String);
      if (intersection(tableFeatures, parameters.sources).length < 1) {
        return null;
      }
    }

    const getFilenameField = () => {
      if (status === 'complete') {
        const newBadgeElement = hasNewBadge && <span className="new-badge">NEW</span>;
        return (
          <>
            <button
              className="download-button"
              onClick={() => this.props.initiateDownload(this.props.currentUserId, id)}
              disabled={this.getIsDownloadItemDisabled(download)}
            >
              {filename}
            </button>
            {newBadgeElement}
          </>
        );
      }
      return filename;
    };

    const getImtAppsTitle = () => {
      const maxAppsTitleLimit = 2;

      const imtApps = apps.reduce((res, { name }, appNumb) => {
        if (appNumb >= maxAppsTitleLimit) {
          return res;
        }
        return appNumb === 0 ? name : `${res}, ${name}`;
      }, '');

      if (apps.length > maxAppsTitleLimit) {
        return `${imtApps} + ${apps.length - maxAppsTitleLimit} more`;
      }

      return imtApps;
    };

    const appNameField = isImtSurveyExport ? getImtAppsTitle() : app_name;
    const platformField = isImtSurveyExport
      ? Array.from(new Set(apps.map((app) => app.platform))).join(', ')
      : platform;

    return (
      <RowData key={id} index={index} {...attributes} className={className}>
        <ColData className="filename">{getFilenameField()}</ColData>
        <ColData className="status">{this.renderStatus(status, id)}</ColData>
        {type !== 'AppRollup' ? <ColData className="application">{appNameField}</ColData> : null}
        {type !== 'AppRollup' ? <ColData className="platform">{platformField}</ColData> : null}
        {type === 'AppHealth' ? (
          <>
            <ColData className="icon">
              {parameters.sources.includes('enjoymentDialog') ? (
                <span className="includes-enjoymentDialog" title="Love Dialog" />
              ) : null}
            </ColData>
            <ColData className="icon">
              {parameters.sources.includes('interactions') ? (
                <span className="includes-interactions" title="Interactions" />
              ) : null}
            </ColData>
            <ColData className="icon">
              {parameters.sources.includes('starRating') ? (
                <span className="includes-starRating" title="Star Ratings" />
              ) : null}
            </ColData>
          </>
        ) : null}
        {type === 'FanSignal' ? (
          <>
            <ColData className="fan-signal-type">{this.renderFanSignalsParams(parameters.fs_export_type)}</ColData>
            <ColData className="fan-signal-states">{this.renderFanSignalsParams(parameters.requested_states)}</ColData>
          </>
        ) : null}
        {['AppHealth', 'Survey', 'AppRollup', 'FanSignal', 'UnifiedSurvey'].includes(type) ? (
          <ColData className="time-period">
            {parameters.start_date ? (
              <>
                {moment.utc(parameters.start_date).format('ll')} - {moment.utc(parameters.end_date).format('ll')}
              </>
            ) : (
              'All Time'
            )}
          </ColData>
        ) : null}
        {type === 'Journey' ? <ColData className="time-period">{'All Time'}</ColData> : null}
        {type === 'Translations' ? (
          <ColData className="bundle">{parameters.stale ? 'Out Of Date / Missing' : 'Complete'}</ColData>
        ) : null}
        <ColData className="completed">{date_requested ? moment.utc(date_requested).format('ll') : 'N/A'}</ColData>
        <ColData className="filesize">{Number.isInteger(filesize) ? prettyBytes(filesize) : 'N/A'}</ColData>
      </RowData>
    );
  };

  render() {
    const { search, selected, sortBy, sortDirection } = this.state;
    const { className, downloads } = this.props;
    const buttonDisabled = this.disableDownloadButton(downloads, selected);
    const sortedDownloads = this.sortDownloads(sortBy, sortDirection, downloads);

    return (
      <section>
        <div className="sub-nav">
          <div className="left">
            {this.renderFilter(className)}
            <button
              className="download-button download"
              disabled={buttonDisabled}
              onClick={this.props.startDownloads}
              title="Download Selected Items"
            />
            <button
              className="download-button delete"
              disabled={selected.length === 0}
              onClick={this.props.showDeleteDownloads}
              title="Delete Selected Items"
            />
            <span className="note">Note: Files older than 90 days will be automatically deleted</span>
          </div>
          <div className="right">
            <div className="search">
              <input
                type="text"
                name="search"
                className="search-text"
                onChange={this.updateSearch}
                value={search}
                placeholder={
                  className === 'journey'
                    ? ' Search Survey downloads'
                    : `Search ${className
                        .split('-')
                        .map((w) => {
                          return w[0].toUpperCase() + w.slice(1);
                        })
                        .join(' ')} downloads`
                }
              />
              <button className="search-button" />
            </div>
          </div>
        </div>
        <TableInteractive toggleSelectedAction={this.toggleIndividualSelected}>
          <TableHead>
            <RowHeader interactive onChange={this.toggleSelectAll}>
              <ColHeader
                sortDisplay
                sortKey="filename"
                sortStatus={sortBy === 'filename' ? sortDirection : 'none'}
                toggleSort={this.toggleSort}
                className="filename"
              >
                FILE NAME
              </ColHeader>
              <ColHeader
                sortDisplay
                sortKey="status"
                sortStatus={sortBy === 'status' ? sortDirection : 'none'}
                toggleSort={this.toggleSort}
                className="status"
              >
                Status
              </ColHeader>
              {className !== 'app-rollup' ? (
                <ColHeader
                  sortDisplay
                  sortKey="app_name"
                  sortStatus={sortBy === 'app_name' ? sortDirection : 'none'}
                  toggleSort={this.toggleSort}
                  className="app_name"
                >
                  Application
                </ColHeader>
              ) : null}
              {className !== 'app-rollup' ? (
                <ColHeader
                  sortDisplay
                  sortKey="platform"
                  sortStatus={sortBy === 'platform' ? sortDirection : 'none'}
                  toggleSort={this.toggleSort}
                  className="platform"
                >
                  Platform
                </ColHeader>
              ) : null}
              {className === 'app-health' ? (
                <ColHeader
                  sortDisplay
                  sortKey="features"
                  sortStatus={sortBy === 'features' ? sortDirection : 'none'}
                  toggleSort={this.toggleSort}
                  colSpan={3}
                  className="features"
                >
                  Features Included
                </ColHeader>
              ) : null}
              {className === 'translations' ? (
                <ColHeader
                  sortDisplay
                  sortKey="bundle"
                  sortStatus={sortBy === 'bundle' ? sortDirection : 'none'}
                  toggleSort={this.toggleSort}
                  className="bundle"
                >
                  Bundle
                </ColHeader>
              ) : null}
              {className === 'fan-signal' ? (
                <>
                  <ColHeader
                    sortDisplay
                    sortKey="fan-signal-type"
                    sortStatus={sortBy === 'fan-signal-type' ? sortDirection : 'none'}
                    toggleSort={this.toggleSort}
                    className="fan-signal-type"
                  >
                    Type
                  </ColHeader>
                  <ColHeader
                    sortDisplay
                    sortKey="fan-signal-states"
                    sortStatus={sortBy === 'fan-signal-states' ? sortDirection : 'none'}
                    toggleSort={this.toggleSort}
                    className="fan-signal-states"
                  >
                    Fan Signals
                  </ColHeader>
                </>
              ) : null}
              {['app-health', 'survey', 'app-rollup', 'fan-signal', 'journey'].includes(className) ? (
                <ColHeader
                  sortDisplay
                  sortKey="period"
                  sortStatus={sortBy === 'period' ? sortDirection : 'none'}
                  toggleSort={this.toggleSort}
                  className="period"
                >
                  Export Time Period
                </ColHeader>
              ) : null}
              <ColHeader
                sortDisplay
                sortKey="date_requested"
                sortStatus={sortBy === 'date_requested' ? sortDirection : 'none'}
                toggleSort={this.toggleSort}
                className="date_requested"
              >
                Date Created
              </ColHeader>
              <ColHeader
                sortDisplay
                sortKey="filesize"
                sortStatus={sortBy === 'filesize' ? sortDirection : 'none'}
                toggleSort={this.toggleSort}
                className="filesize"
              >
                File Size
              </ColHeader>
            </RowHeader>
          </TableHead>
          <TableBody>{!!sortedDownloads.length && sortedDownloads.map(this.renderRow)}</TableBody>
        </TableInteractive>
      </section>
    );
  }
}

export default DownloadsTable;
