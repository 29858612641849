import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button } from 'src/components/molecules';
import Sticky from 'react-stickynode';

import DownloadIcon from '../assets/navigation-download-icon.jsx.svg';

import '../styles/download_center.scss';

class DownloadCenterNav extends Component {
  constructor(props) {
    super(props);

    this.hideWelcomeModal = this.hideWelcomeModal.bind(this);
  }

  closeFsExportModal = () => this.props.updateUIState('has_not_downloaded_fs_export', false);

  hideWelcomeModal() {
    const { acknowledgeDownloadCenterWelcome } = this.props;
    acknowledgeDownloadCenterWelcome();
  }

  renderWelcomeModal() {
    const { displayDownloadCenterWelcome } = this.props;
    const fader = 'popover-fade-in';

    if (displayDownloadCenterWelcome) {
      return (
        <>
          <div className="download-modal-background" />
          <div className={classnames('download-wrapper-welcome', fader)}>
            <div className="download-popover">
              <p>Your export has started!</p>
              <p>
                You will receive a notification in the Download Center <br />
                when your export is ready to be downloaded.
              </p>
              <Button className="download-popover-ok" onClick={this.hideWelcomeModal}>
                Okay!
              </Button>
              <div className="download-popover-arrow" />
            </div>
          </div>
        </>
      );
    }

    return null;
  }

  renderHasFsExportModal() {
    const fader = 'popover-fade-in';

    return (
      <>
        <div className="download-modal-background" onClick={this.closeFsExportModal} />
        <div className={classnames('download-wrapper-welcome', fader)}>
          <div className={classnames('fs-export', 'download-popover')}>
            <span>
              You have a completed Fan Signals
              <br />
              export waiting for you!
              <br />
              Please download your file(s) in the
              <br />
              Download Center.
              <br />
            </span>
            <Button className="download-popover-ok" onClick={this.closeFsExportModal}>
              Okay!
            </Button>
            <div className="download-popover-arrow" />
          </div>
        </div>
      </>
    );
  }

  renderToast() {
    const { downloadsToastVisible, downloadsToastMessage } = this.props;

    const fader = downloadsToastVisible ? 'popover-fade-in' : 'popover-fade-out';

    return (
      <Sticky className={classnames('download-wrapper', fader)}>
        <div className="download-popover">
          {downloadsToastMessage.msg} <br />
          {downloadsToastMessage.stats ? downloadsToastMessage.stats : null}
          <div className="download-popover-arrow" />
        </div>
      </Sticky>
    );
  }

  render() {
    const { displayDownloadCenterWelcome, hasSeenDownloadCenter, numNewDownloads, hasNotDownloadedFsExport } =
      this.props;

    const isOnSection = !!window.location.pathname.match('downloads') || hasNotDownloadedFsExport;
    const elevateIcon = displayDownloadCenterWelcome && classnames('elevate-icon', 'active');
    const isActiveLink = () => numNewDownloads > 0 && window.location.pathname !== '/downloads';

    return (
      <>
        <NavLink
          className={classnames('button-normalize download-center', elevateIcon, { 'nav-link active': isOnSection })}
          to="/downloads"
          isActive={isActiveLink}
        >
          <DownloadIcon />
        </NavLink>
        {hasNotDownloadedFsExport && this.renderHasFsExportModal()}
        {!hasSeenDownloadCenter && this.renderWelcomeModal()}
        {!hasNotDownloadedFsExport && hasSeenDownloadCenter && this.renderToast()}
      </>
    );
  }
}

DownloadCenterNav.propTypes = {
  acknowledgeDownloadCenterWelcome: PropTypes.func.isRequired,
  displayDownloadCenterWelcome: PropTypes.bool.isRequired,
  downloadsToastMessage: PropTypes.shape({ msg: PropTypes.string, stats: PropTypes.string }).isRequired,
  downloadsToastVisible: PropTypes.bool.isRequired,
  hasSeenDownloadCenter: PropTypes.bool.isRequired,
  numNewDownloads: PropTypes.number.isRequired,
  isUserLoading: PropTypes.bool.isRequired,
  hasNotDownloadedFsExport: PropTypes.bool.isRequired,
  currentUserId: PropTypes.string.isRequired,
  updateUIState: PropTypes.func.isRequired,
};

export default DownloadCenterNav;
