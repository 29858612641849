import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AlchemerWebTitle, AlchemerMobTitle, ApptentiveWebTitle, ApptentiveMobTitle } from 'src/utils/helpers';
import historyProp from '../../proptypes/history-prop';

import Header from '../../components/header/index';
import Masquerade from '../../components/header/masquerade_bar';
import Footer from '../../components/footer';
import { AppDrawerContainer } from '../../components/app_drawer';

import SidebarConnected from './admin_sidebar';

import { getOrgs } from '../../selectors/organizations';

import '../../components/styles/base.scss';
import '../../components/styles/dashboard.scss';
import '../../components/styles/molecules/form_elements.scss';
import '../../components/styles/molecules/dokidoki_link_list.scss';
import '../../components/styles/molecules/dokidoki_sidebar.scss';
import '../../components/styles/molecules/dokidoki_sections.scss';

const subtitle = (pathname) => {
  switch (pathname) {
    case '/profile':
      return 'Profile';
    case '/email-preferences':
      return 'Email Preferences';
    case '/app-memberships':
      return 'Your Projects';
    case '/subcontractors':
      return 'Sub-contractor Disclosure';
    default:
      return 'Company Account Overview';
  }
};

export class AdminLayoutPresentational extends React.Component {
  static displayName = 'AdminLayoutPresentational';
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]),
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    organizations: PropTypes.shape({}),
    match: PropTypes.shape({
      params: PropTypes.shape({
        orgId: PropTypes.string,
      }),
    }),
    history: historyProp,
  };

  static defaultProps = {
    children: null,
    organizations: {},
    match: {
      params: {},
    },
    history: {
      push: () => { },
    },
  };

  getOrgName = (organization) => {
    if (organization.name === ApptentiveMobTitle) {
      return AlchemerMobTitle;
    }
    if (organization.name === ApptentiveWebTitle) {
      return AlchemerWebTitle;
    }
    return organization.name;
  };

  orgSelect = (pathname, organizations) => {
    const currentOrgId = (pathname.split('/')).pop();
    return (
      <select className="switch_account" defaultValue={currentOrgId} onChange={this.navigateToOrg}>
        {Object.keys(organizations).map(orgId => (
          <option key={orgId} value={orgId}>
            {this.getOrgName(organizations[orgId])}
          </option>)
        )}
      </select>
    );
  };

  navigateToOrg = (event) => {
    if (event) {
      event.preventDefault();
      this.props.history.push(`/account/${event.target.value}`);
    }
  };

  render() {
    const { children, match: { params }, location, organizations } = this.props;

    return (
      <div className="admin settings">
        <AppDrawerContainer navigationLocation={'dashboard'} />
        <Masquerade />
        <Header activeTab="" />
        <div className="main-content">
          <div className="section--2col__page-title">
            <div className="page-title is-shown">
              <h1>Account Settings</h1>
            </div>
          </div>
          <div className="section--2col__sub-title">
            <div className="sub-title" key={`org-select-${params.orgId}`}>
              <h2>{subtitle(location.pathname)}</h2>
              {
                /^\/account\/(.*)\/?$/.test(location.pathname) ? (
                  <div className="sub-actions">
                    {this.orgSelect(location.pathname, organizations)}
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className="section--2col__sidebar">
            <SidebarConnected location={location} match={this.props.match} />
          </div>
          <div className="section--2col__content">
            <div className="content" id="content">
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  organizations: getOrgs(state),
});

/* istanbul ignore next */
const AdminLayout = connect(mapStateToProps, {})(AdminLayoutPresentational);
AdminLayout.displayName = 'AdminLayout';

export default AdminLayout;
