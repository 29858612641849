import { Rest, API } from 'src/api';
import { RatingPromptItem } from 'src/reducers/rating-prompt';

import { BaseService } from './base-service';

export class RatingPromptService extends BaseService {
  static fetchRating(appId: string): Promise<RatingPromptItem> {
    return Rest.httpGet(API.RATING_PROMPT.GET(appId), {
      errorTitle: '[API] Error GET ratings prompt',
    }).promise;
  }

  static activate(appId: string, ratingId: RatingPromptItem['id'], active: boolean): Promise<RatingPromptItem> {
    return Rest.httpPut(API.RATING_PROMPT.SAVE(appId), {
      body: { active },
      errorTitle: '[API] Error Activate ratings prompt',
    }).promise;
  }

  static saveRatingDialog(appId: string, rating: RatingPromptItem): Promise<RatingPromptItem> {
    return Rest.httpPut(API.RATING_PROMPT.SAVE(appId), {
      body: rating,
      errorTitle: '[API] Error SAVE ratings prompt',
    }).promise;
  }
}
