import React, { Component } from 'react';
import { HoverTooltip, Icon, IconButton, InputText } from 'src/components/molecules';
import CopyButton from 'src/components/utils/copy/copy_button';
import analytics from 'src/data/analytics';

import './api_key_record.scss';

interface IApiKeyRecordProps {
  description: string,
  apiKeyValue: string,
  isActive: boolean,
  createDate: Date,
  expireDate?: Date,
  renameKeyHandler: (description: string) => Promise<boolean>,
  revokeKeyHandler: () => Promise<void>,
  deleteKeyHandler: () => Promise<void>,
  copyKeyHandler: () => void,
}

interface IApiKeyRecordState {
  isEditing: boolean,
  isLocked: boolean,
  newKeyDescription: string,
  renameKeyError: boolean,
}

export class ApiKeyRecord extends Component<IApiKeyRecordProps, IApiKeyRecordState> {
  static defaultProps: IApiKeyRecordProps = {
    description: '',
    apiKeyValue: '',
    isActive: false,
    createDate: new Date(),
    renameKeyHandler: (_) => Promise.reject('not implemented'),
    revokeKeyHandler: () => Promise.reject('not implemented'),
    deleteKeyHandler: () => Promise.reject('not implemented'),
    copyKeyHandler: () => Promise.reject('not implemented'),
  }

  state: IApiKeyRecordState = {
    isEditing: false,
    isLocked: false,
    newKeyDescription: '',
    renameKeyError: false,
  };

  statusIcon = (): JSX.Element => {
    const tooltip = this.props.isActive ? 'Active' : `Revoked ${this.formatDate(this.props.expireDate)}`
    return <HoverTooltip content={tooltip}><Icon name={this.props.isActive ? 'key' : 'keyoff'} className={this.props.isActive ? 'api-keys__icon-positive' : 'api-keys__icon-light'} /></HoverTooltip>
  }

  formatDate = (createdDate?: Date): string => {
    if (!createdDate) {
      return '';
    }
    const date = new Date(createdDate);
    return date.toLocaleDateString();
  }

  showEditName = () => {
    this.setState({ isEditing: true, newKeyDescription: this.props.description });
  }

  rename = async () => {
    this.setState({ isLocked: true, renameKeyError: false });
    const success = await this.props.renameKeyHandler(this.state.newKeyDescription);
    this.setState({ isLocked: false, renameKeyError: !success });
  }

  revoke = async () => {
    analytics.trackEvent('Metrics API', 'Button Click', 'Revoke Key');
    this.setState({ isLocked: true });
    await this.props.revokeKeyHandler();
    this.setState({ isLocked: false });
  }

  delete = async () => {
    this.setState({ isLocked: true });
    await this.props.deleteKeyHandler();
    this.setState({ isLocked: false });
  }

  dateText = () => {
    if (this.props.isActive) {
      return `Created ${this.formatDate(this.props.createDate)}`;
    }
    return `Revoked ${this.formatDate(this.props.expireDate)}`;
  }

  availableActions = (): JSX.Element => {
    if (this.state.isEditing) {
      return (
        <div className="api-key-actions">
          <IconButton key={`${this.props.apiKeyValue}_action--confirm`} onClick={this.rename} disabled={!this.state.newKeyDescription || this.state.isLocked} iconName="circlecheck" tooltip="Confirm" />
          <IconButton key={`${this.props.apiKeyValue}_action--cancel`} onClick={() => this.setState({ isEditing: false, newKeyDescription: '' })} disabled={this.state.isLocked} iconName="cancel" tooltip="Cancel" />
        </div>
      )
    }
    const actions = [
      <CopyButton key={`${this.props.apiKeyValue}_action--copy`} content={this.props.apiKeyValue} />,
    ];

    if (this.props.isActive) {
      actions.push(
        <IconButton key={`${this.props.apiKeyValue}_action--edit`} onClick={this.showEditName} iconName="editoutline" tooltip="Edit name" disabled={this.state.isLocked} />,
        <IconButton key={`${this.props.apiKeyValue}_action--revoke`} onClick={this.revoke} iconName="keyoff" tooltip="Revoke" disabled={this.state.isLocked} />,
      );
    } else {
      actions.push(
        <IconButton key={`${this.props.apiKeyValue}_action--delete`} onClick={this.delete} iconName="deleteicon" tooltip="Delete" disabled={this.state.isLocked} />,
      );
    }

    return (
      <div className="api-key-actions">
        {...actions}
      </div>
    );
  }

  render() {
    return (
      <div className="api-key">
        <div className="api-key-headline">
          <div className="api-key-headline__icon">{this.statusIcon()}</div>
          <div className={`api-key-headline__name ${this.state.isEditing ? 'api-key-headline__name__editing' : 'api-key-headline__name__display'}`}>
            {this.state.isEditing
              ? <InputText autoFocus value={this.state.newKeyDescription} placeholder="Name" aria-label="Name" optional={false} onChange={(value: string) => this.setState({ newKeyDescription: value })} invalid={this.state.renameKeyError} />
              : <HoverTooltip content={this.props.description}><span className={`api-key__description--text ${this.props.isActive ? '' : 'api-key__description--text__inactive'}`}>{this.props.description}</span></HoverTooltip>}
          </div>
          {this.availableActions()}
        </div>
        <em className={this.props.isActive ? '' : 'date-expired'}>{this.dateText()}</em>
      </div>
    );
  }
}
