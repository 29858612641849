/* eslint-disable import/no-import-module-exports */
/* eslint-disable import/first */
import '../utils/polyfills';
import '../utils/easter-egg';

import React from 'react';
import ReactDom from 'react-dom/client';

import { createStore } from '../store';
import { history } from '../store/history';
import { DashboardRoot } from './components/root';

import { isDevMode } from '../utils/environment';
import { initSentry } from '../utils/sentry';

initSentry();

const store = createStore();

if (isDevMode()) {
  Object.assign(window, { store });
}

const renderApp = () => {
  ReactDom.createRoot(document.getElementById('app')).render(<DashboardRoot store={store} history={history} />);
};

renderApp();

if (module.hot) {
  module.hot.accept('./components/root', () => renderApp());
}
