import React from 'react';

export const Pulse = () => {
  const currentPath = window.location.pathname.split('/');
  const alchemerPath = currentPath[currentPath.length - 1];
  return (
    <iframe
      className="alchemer-iframe"
      title="product-pulse"
      id="alchemer-product-pulse"
      src={`https://www.alchemer.com/${alchemerPath}/`}
    />
  );
};
