export const sidebarRoutes = [
  '/apps/:appId/interactions',
  '/apps/:appId/surveys',
  '/apps/:appId/surveys_2.0',
  '/apps/:appId/groups',
  '/apps/:appId/events',
  '/apps/:appId/groups/new',
  '/apps/:appId/groups/:groupId',
  '/apps/:appId/prompts',
  '/apps/:appId/love-prompt',
  '/apps/:appId/love-dialogs',
  '/apps/:appId/ratings_prompt',
  '/apps/:appId/ratings_prompt/latest',
  '/apps/:appId/message-center',
  '/apps/:appId/interaction-styles',
  '/apps/:appId/initiators',
];

export const interactionsRoutesPath = [
  '/apps/:appId/interactions',
  '/apps/:appId/surveys',
  '/apps/:appId/surveys_2.0',
  '/apps/:appId/prompts',
  '/apps/:appId/events',
  '/apps/:appId/groups',
  '/apps/:appId/interaction-styles',
  '/apps/:appId/love-prompt',
  '/apps/:appId/love-dialogs',
  '/apps/:appId/ratings_prompt',
  '/apps/:appId/ratings_prompt/latest',
  '/apps/:appId/message-center',
  '/apps/:appId/initiators',
];
