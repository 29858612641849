import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SurveyMultiAppsService } from 'src/services';
import { GetState } from 'src/reducers';
import { getCurrentOrgId } from 'src/selectors/current_app';
import { ISurveysMultiAppsApi } from 'src/api/surveys-multi-apps';
import { SURVEYS_PAGINATION_LIMIT } from 'src/services/survey-multi-apps-service';
import { FetchSurveysMultiAppsResp, MultiAppsSurvey } from 'src/reducers/surveys-multi-apps';
import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { SurveyMultiAppsActions } from './surveys-multi-apps/survey-multi-apps.action-types';

// Implementation inspired by:
// https://medium.com/@peatiscoding/typescripts-with-redux-redux-thunk-recipe-fcce4ffca405

const DEFAULT_FETCH_SURVEYS_MULTI_APPS_RESP: FetchSurveysMultiAppsResp = {
  surveys: [],
  page: 1,
  total: 0,
  limit: SURVEYS_PAGINATION_LIMIT,
  active: true,
  has_more: false,
};

export enum FetchSurveysConfigEnum {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

export interface IRequestFetchActionMultiApps {
  type:
    | SurveyMultiAppsActions.REQUEST_FETCH_ACTIVE_SURVEYS_MULTI_APPS
    | SurveyMultiAppsActions.REQUEST_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS;
  isFetching: boolean;
}

export interface IReceiveFetchActionMultiApps {
  type:
    | SurveyMultiAppsActions.RECEIVE_FETCH_ACTIVE_SURVEYS_MULTI_APPS
    | SurveyMultiAppsActions.RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS;
  surveys: MultiAppsSurvey[];
  isFetching: boolean;
  page: number;
  total: number;
  active: boolean;
}

export interface IErroredFetchActionMultiApps {
  type:
    | SurveyMultiAppsActions.ERRORED_FETCH_ACTIVE_SURVEYS_MULTI_APPS
    | SurveyMultiAppsActions.ERRORED_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS;
  isFetching: boolean;
  error: Error;
}

export const requestFetchSurveysMultiApps = (isActive: boolean): IRequestFetchActionMultiApps => ({
  type: isActive
    ? SurveyMultiAppsActions.REQUEST_FETCH_ACTIVE_SURVEYS_MULTI_APPS
    : SurveyMultiAppsActions.REQUEST_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS,
  isFetching: true,
});

export const receiveFetchSurveysMultiApps = (
  data: FetchSurveysMultiAppsResp,
  isActive: boolean,
): IReceiveFetchActionMultiApps => ({
  type: isActive
    ? SurveyMultiAppsActions.RECEIVE_FETCH_ACTIVE_SURVEYS_MULTI_APPS
    : SurveyMultiAppsActions.RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS,
  isFetching: false,
  surveys: data.surveys,
  page: data.page || 1,
  total: data.total || 0,
  active: isActive,
});

export const erroredFetchSurveysMultiApps = (error: Error, isActive: boolean): IErroredFetchActionMultiApps => ({
  type: isActive
    ? SurveyMultiAppsActions.ERRORED_FETCH_ACTIVE_SURVEYS_MULTI_APPS
    : SurveyMultiAppsActions.ERRORED_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS,
  isFetching: false,
  error,
});

export const fetchSurveys =
  (page: number = 1, config: FetchSurveysConfigEnum) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: GetState): Promise<FetchSurveysMultiAppsResp | void> => {
    const orgId = getCurrentOrgId(getState()) as string;

    if (config === FetchSurveysConfigEnum.ALL) {
      let activeItems = DEFAULT_FETCH_SURVEYS_MULTI_APPS_RESP;
      let notActiveItems = DEFAULT_FETCH_SURVEYS_MULTI_APPS_RESP;
      let isActiveError = false;
      let isNotActiveError = false;
      dispatch(requestFetchSurveysMultiApps(true));

      // fetch active surveys with no dispatch
      try {
        activeItems = await SurveyMultiAppsService.fetchSurveys(orgId, page, true);
        activeItems.surveys = (activeItems?.surveys || []).map(SurveyMultiAppsModel.oldSurveyToNew);
      } catch (error) {
        isActiveError = true;
      }

      // fetch not active surveys with no dispatch
      try {
        notActiveItems = await SurveyMultiAppsService.fetchSurveys(orgId, page, false);
        notActiveItems.surveys = (notActiveItems?.surveys || []).map(SurveyMultiAppsModel.oldSurveyToNew);
      } catch (error) {
        isNotActiveError = true;
      }

      // dispatch all fetched surveys or erros after API calls finished
      if (isActiveError) {
        dispatch(erroredFetchSurveysMultiApps(new Error('unable to fetch active surveys'), true));
      } else {
        dispatch(receiveFetchSurveysMultiApps(activeItems as FetchSurveysMultiAppsResp, true));
      }

      if (isNotActiveError) {
        dispatch(erroredFetchSurveysMultiApps(new Error('unable to fetch not active surveys'), false));
      } else {
        dispatch(receiveFetchSurveysMultiApps(notActiveItems as FetchSurveysMultiAppsResp, false));
      }

      return;
    }

    dispatch(requestFetchSurveysMultiApps(config === FetchSurveysConfigEnum.ACTIVE));

    try {
      const data: FetchSurveysMultiAppsResp = await SurveyMultiAppsService.fetchSurveys(
        orgId,
        page,
        config === FetchSurveysConfigEnum.ACTIVE,
      );
      data.surveys = data.surveys.map(SurveyMultiAppsModel.oldSurveyToNew);

      dispatch(receiveFetchSurveysMultiApps(data, config === FetchSurveysConfigEnum.ACTIVE));
      // eslint-disable-next-line
      return Promise.resolve(data) as Promise<FetchSurveysMultiAppsResp | void>;
    } catch (error) {
      dispatch(erroredFetchSurveysMultiApps(error as Error, config === FetchSurveysConfigEnum.ACTIVE));
    }
  };

export const receiveEmailSummaryAppIdsMultiApps = (appIds: string[]) => ({
  type: SurveyMultiAppsActions.RECEIVE_SURVEY_MULTI_APPS_EMAIL_SUMMARY_APP_IDS,
  emailSummaryAppIds: appIds,
});

export const updateEmailSummaryAppIds =
  (userId: string, appIds: string[]) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, api: ISurveysMultiAppsApi): Promise<void> => {
    await api.updateEmailSummaryAppIds(userId, appIds);
    dispatch(receiveEmailSummaryAppIdsMultiApps(appIds));
  };
