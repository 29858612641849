import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserFeature } from 'src/types/core';
import { isDevMode, isNetlifyDev, isNetlifyStaging } from 'src/utils/environment';
import { useFeatureSettings, FeatureFlag, bemPrefix } from 'src/utils';
import { receivedCurrentUserFeatures } from 'src/actions';
import { getFeatures, getCurrentUserId } from 'src/selectors/user';
import { getCurrentAppId } from 'src/selectors/current_app';

import { useOnOutsideClick } from 'src/hooks';
import { Icon, InputCheckbox } from 'src/components/molecules';

import './ui-feature-toggles.scss';

const bem = bemPrefix('ui-feature-toggles');

const FeatureInput: React.FC<{
  className: string;
  featureName: string;
  featureValue: boolean;
  onToggleFeature(featureName: string): () => void;
}> = ({ className, featureName, featureValue, onToggleFeature }) => (
  <div className={className}>
    <InputCheckbox
      className={bem('input-checkbox', { isChecked: featureValue })}
      label={featureName}
      value={featureName}
      checked={featureValue}
      onChange={onToggleFeature(featureName)}
    />
  </div>
);

export const DebugFeaturesToggleMenu: React.FC = () => {
  const dispatch = useDispatch();
  const features = useSelector(getFeatures);
  const appId = useSelector(getCurrentAppId);
  const userId = useSelector(getCurrentUserId);

  const togglerRef = useRef(null);
  const menuRef = useRef(null);

  const [isOpen, showMenu] = useState(false);
  const { flag, hasFeature, setFeature } = useFeatureSettings();

  const onToggleFeature = useCallback(
    (flagName: FeatureFlag) => () => {
      setFeature(flagName, !hasFeature(flagName));
    },
    [],
  );

  const onToggleUserFeature = useCallback(
    (featureName: UserFeature) => () => {
      const newFeatures = features.includes(featureName)
        ? features.filter((name) => name !== featureName)
        : [...features, featureName];

      dispatch(receivedCurrentUserFeatures(newFeatures, appId, userId));
    },
    [features, appId, userId],
  );

  useOnOutsideClick([togglerRef, menuRef], () => showMenu(false));

  // Only show if we are in staging/dev or the current envrionment is development.
  if (![isNetlifyStaging(), isNetlifyDev(), isDevMode()].some(Boolean)) {
    return null;
  }

  return (
    <>
      <div className={bem('toggler')} ref={togglerRef} onClick={() => showMenu(!isOpen)}>
        {isOpen ? <Icon name="remove" /> : <Icon name="gear" />}
      </div>
      {isOpen && (
        <div className={bem('menu')} ref={menuRef}>
          <h3>UI Feature Togglers (Staging-Only)</h3>
          <h4>Feature Flags</h4>
          {Object.keys(flag).map((flagKey: keyof typeof FeatureFlag) => (
            <FeatureInput
              key={flagKey}
              className="feature-flag"
              featureName={flag[flagKey]}
              featureValue={hasFeature(flag[flagKey])}
              onToggleFeature={onToggleFeature}
            />
          ))}
          <hr />
          <h4>User Features</h4>
          {Object.keys(UserFeature)
            .reverse()
            .map((featureKey: keyof typeof UserFeature) => (
              <FeatureInput
                key={featureKey}
                className="user-feature"
                featureName={UserFeature[featureKey]}
                featureValue={features.includes(UserFeature[featureKey])}
                onToggleFeature={onToggleUserFeature}
              />
            ))}
        </div>
      )}
    </>
  );
};

DebugFeaturesToggleMenu.displayName = 'DebugFeaturesToggleMenu';
