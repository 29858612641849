import { Rest, API } from 'src/api';
import { RatingFlowItem, RatingMessageCenter } from 'src/reducers/love-dialog';

import { BaseService } from './base-service';

export class LoveDialogService extends BaseService {
  static fetchFlows(appId: string): Promise<RatingFlowItem[]> {
    return Rest.httpGet(API.LOVE_DIALOG.FLOWS(appId), {
      errorTitle: '[API] Error GET ratings flow',
    }).promise;
  }

  static activate(appId: string, loveDialogId: RatingFlowItem['id'], active: boolean): Promise<RatingFlowItem> {
    return Rest.httpPut(API.LOVE_DIALOG.SAVE(appId, loveDialogId), {
      body: { active },
      errorTitle: '[API] Error Activate love dialog ratings flow',
    }).promise;
  }

  static saveLoveDialog(appId: string, loveDialog: RatingFlowItem): Promise<RatingFlowItem> {
    return Rest.httpPut(API.LOVE_DIALOG.SAVE(appId, loveDialog.id), {
      body: loveDialog,
      errorTitle: '[API] Error SAVE love dialog ratings flow',
    }).promise;
  }

  static saveMessageCenter(appId: string, flowId: string, messageCenter: RatingMessageCenter): Promise<any> {
    return Rest.httpPut(API.LOVE_DIALOG.SAVE(appId, flowId), {
      body: { message_center: messageCenter },
      errorTitle: '[API] Error SAVE message center',
    }).promise;
  }
}
