import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { filter, head, propEq } from 'ramda';

import ApiKeysContainer from 'src/components/api_key_management/api_keys';
import { ApiKeyType } from 'src/types/api_key';
import { AlchemerWebTitle, AlchemerMobTitle, ApptentiveWebTitle, ApptentiveMobTitle } from 'src/utils/helpers';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import historyProp from '../../proptypes/history-prop';

import {
  deleteOrganization,
  fetchOrganization,
  fetchOrganizationMembers,
  fetchOrganizations,
  updateOrganization,
} from '../../actions/organizations';
import { getOrgLoading, getOrgs, getOrgsLoading } from '../../selectors/organizations';
import { getCurrentOrgId } from '../../selectors/current_app';
import { getCurrentUserId } from '../../selectors/user';

import './styles/account.scss';
import '../../components/styles/molecules/tables.scss';
import sdkEngage from '../../utils/sdk_engage';

export class Presentational extends React.Component {
  static displayName = 'AccountPresentational';
  static propTypes = {
    currentOrgId: PropTypes.string,
    currentUserId: PropTypes.string,
    deleteOrganization: PropTypes.func,
    fetchOrganization: PropTypes.func,
    fetchOrganizationMembers: PropTypes.func,
    fetchOrganizations: PropTypes.func,
    loading: PropTypes.bool,
    loadingOrgs: PropTypes.bool,
    organizations: PropTypes.instanceOf(Object),
    match: PropTypes.shape({
      params: PropTypes.shape({
        orgId: PropTypes.string,
      }),
    }),
    history: historyProp,
    updateOrganization: PropTypes.func,
  };

  static defaultProps = {
    currentOrgId: '',
    currentUserId: '',
    deleteOrganization: () => {},
    fetchOrganization: () => {},
    fetchOrganizationMembers: () => {},
    fetchOrganizations: () => {},
    loading: true,
    loadingOrgs: false,
    organizations: {},
    match: {
      params: {
        orgId: '',
      },
    },
    history: {
      replace: () => {},
    },
    updateOrganization: () => {},
  };

  constructor(props) {
    super(props);
    this.billing_contact_id = React.createRef();
    this.organization_name = React.createRef();
  }

  state = {
    errors: [],
  };

  componentDidMount() {
    setPageTitleOnMount('Company Account Overview', { oldTitle: 'Company Account Overview | Alchemer' });

    this.props.fetchOrganizations(this.props.currentUserId);
    if (!this.props.match.params.orgId) {
      this.props.history.replace(`/account/${this.props.currentOrgId}`);
    } else {
      this.props.fetchOrganization(this.props.match.params.orgId);
      this.props.fetchOrganizationMembers(this.props.currentUserId, this.props.match.params.orgId);
    }
    sdkEngage('account_page');
  }

  componentWillReceiveProps({ match = { params: {} }, ...nextProps }) {
    if (match.params.orgId && match.params.orgId !== this.props.match.params.orgId) {
      nextProps.fetchOrganization(match.params.orgId);
      nextProps.fetchOrganizationMembers(nextProps.currentUserId, match.params.orgId);
    }
  }

  getOrg = () => this.props.organizations[this.props.match.params.orgId] || {};

  getBillingContact = () => {
    const org = this.getOrg();
    return head(filter(propEq('user_id', org.billing_contact_id), org.members || []));
  };

  deleteOrganization = (orgId) => (_ev) => {
    const organization = this.getOrg();
    const confirmation = window.prompt(
      'To proceed with deleting your organization and all associated data, enter the exact name of your organization in the space provided. THIS CANNOT BE UNDONE.',
    ); // eslint-disable-line no-alert
    if (confirmation && confirmation === organization.name && orgId === organization.id) {
      this.props.deleteOrganization(orgId, confirmation);
    }
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ errors: [] });

    if (!this.organization_name.current || !this.organization_name.current.value) {
      this.setState({
        errors: ['Organization name is required.', ...this.state.errors],
      });
      return;
    }

    this.props.updateOrganization(this.props.match.params.orgId, {
      name: this.organization_name.current.value,
      billing_contact_id: this.billing_contact_id.current.value,
    });
  };

  isBillingContact = () => {
    const { currentUserId } = this.props;
    const billingContact = this.getBillingContact();

    // NOTE: Read Only Mode for non-billing contacts.
    let isBillingContact = false;
    if (billingContact.user_id === currentUserId) {
      isBillingContact = true;
    }
    return isBillingContact;
  };

  renderBillingContactSelect = (isBillingContact, billingContact, organization) =>
    isBillingContact ? (
      <select
        id="billing_contact"
        name="billing_contact"
        defaultValue={billingContact.user_id}
        ref={this.billing_contact_id}
      >
        {organization.members.map((member) => (
          <option key={member.user_id} value={member.user_id}>
            {member.name}
          </option>
        ))}
      </select>
    ) : (
      <a className="billing-contact fs-hide" href={`mailto:${billingContact.email}`}>
        {billingContact.name}
      </a>
    );

  renderTrialExpiration = (billingContact, organization) =>
    organization.trial_end_date ? (
      <div className="group edit">
        <div className="group-link">
          Trial Expiration:{' '}
          <span className="trial_end_date">{moment(organization.trial_end_date * 1000).format('LL')}</span>
        </div>
        <p className="trial_days_left">
          {Math.round((organization.trial_end_date * 1000 - Date.now()) / (24 * 60 * 60 * 1000))} days left
        </p>
      </div>
    ) : (
      <div className="group edit">
        <a className="fs-hide group-link" href={`mailto:${billingContact.email}`}>
          Contact your company&apos;s billing contact
        </a>
        <p>to change billing and account settings</p>
      </div>
    );

  renderInactiveAccount = () => (
    <div className="group cancelled">
      <p>
        <strong className="group-link">This account has been deactivated</strong>
        <br />
        It will continue to work until the end of your billing cycle, at which point all projects, accounts and API keys
        will be shut down.
        <br />
        <br />
        If this is in error, please <a href="mailto:sales@alchemer.com">contact us.</a>
      </p>
    </div>
  );

  renderActiveAccount = (billingContact, organization) => (
    <div className="active-account">
      <div className="group">
        <a className="group-link" href="mailto:sales@alchemer.com">
          Contact Alchemer
        </a>
        <p>
          for billing issues, inquiries, or if you need to
          <br />
          update your company&apos;s billing contact
        </p>
      </div>
      {this.renderTrialExpiration(billingContact, organization)}
    </div>
  );

  renderApiKeys = (organizationId, userId) => (
    <div className="api-key-container">
      <ApiKeysContainer keyType={ApiKeyType.org} ownerId={organizationId} userId={userId} />
    </div>
  );

  renderDeleteOrg = (organization) => (
    <div className="delete_organization">
      <div className="row--2col">
        <div className="col">
          <h2>Delete Organization</h2>
          <p>
            Use this to delete your organization and all associated data, including all projects, conversations,
            surveys, etc. This is non-recoverable.
          </p>
        </div>
      </div>
      <div className="row">
        <button
          className="primary delete"
          disabled={this.props.loading || this.props.loadingOrgs}
          onClick={this.deleteOrganization(organization.id)}
        >
          Delete
        </button>
      </div>
    </div>
  );

  render() {
    const organization = this.getOrg();
    const billingContact = this.getBillingContact();

    // NOTE: Due to the bug with missing billing contact, we need to show loading first.
    if (this.props.loading || this.props.loadingOrgs || !organization || !billingContact) {
      return (
        <div className="account index" key="loading">
          <div className="loading">
            <h2>Loading...</h2>
          </div>
        </div>
      );
    }

    const collaboratorLimit = organization.plan ? organization.plan.collaborator_limit : 0;
    const displayCollaboratorLimit = collaboratorLimit ? collaboratorLimit.toLocaleString() : 'Unlimited';

    const organizationCollabCount = organization.collab_count ? organization.collab_count : 0;
    const extraCollaborators = organizationCollabCount - collaboratorLimit;
    const needExtraCollaborators =
      organizationCollabCount > collaboratorLimit && displayCollaboratorLimit !== 'Unlimited';
    const openSeats = collaboratorLimit ? collaboratorLimit - organizationCollabCount : 0;

    // Read Only Mode
    const isBillingContact = this.isBillingContact();

    const getOrgName = () => {
      if (organization.name === ApptentiveMobTitle) {
        return AlchemerMobTitle;
      }
      if (organization.name === ApptentiveWebTitle) {
        return AlchemerWebTitle;
      }
      return organization.name;
    };

    return (
      <div className="account index" key={organization.id}>
        {this.state.errors.length > 0 ? (
          <div className="form-error">
            {this.state.errors.map((err) => (
              <p key={err}>{err}</p>
            ))}
          </div>
        ) : null}
        <form
          acceptCharset="UTF-8"
          className="update_organization"
          id="update_organization"
          onSubmit={this.handleSubmit}
        >
          <div className="row--2col">
            <div className="col">
              <label htmlFor="name">Company Name</label>
              <p>For billing purposes only. Will not be shown to your customers.</p>
              <input
                className="name"
                name="name"
                required
                minLength={6}
                readOnly={!isBillingContact}
                type="text"
                defaultValue={getOrgName()}
                ref={this.organization_name}
              />
            </div>
            <div className="col">
              <label htmlFor="billing_contact">Billing Contact</label>
              <p>Your company&apos;s primary contact for billing and account-level settings:</p>
              {this.renderBillingContactSelect(isBillingContact, billingContact, organization)}
            </div>
          </div>
          {isBillingContact && (
            <div className="row">
              <button className="primary" disabled={this.props.loading || this.props.loadingOrgs}>
                Save Changes
              </button>
            </div>
          )}
        </form>
        <div className="account-flex-container">
          <div className="even-flex-item interactions">
            <h2>Plan</h2>
            <div className="inner-box">
              <div className="group">
                <div className="group-link">Team Members: {organizationCollabCount}</div>
                <p className="members">
                  {openSeats} open seats / {displayCollaboratorLimit} available total seats
                </p>
              </div>
              {needExtraCollaborators ? (
                <div className="group extra-collab">
                  <p>
                    <strong>Extra Collaborators: {extraCollaborators}</strong>
                  </p>
                </div>
              ) : null}
              <div className="group edit">
                <a className="group-link" href="mailto:sales@alchemer.com">
                  Contact your Alchemer Account Manager
                </a>
                <p>for plan upgrades, downgrades, or inquiries</p>
              </div>
            </div>
          </div>
          <div className="even-flex-item billing">
            <h2>Billing</h2>
            <div className="inner-box">
              {['cancelled', 'unpaid'].includes(organization.account_status)
                ? this.renderInactiveAccount()
                : this.renderActiveAccount(billingContact, organization)}
            </div>
          </div>
        </div>
        {isBillingContact && this.renderApiKeys(this.props.currentOrgId, this.props.currentUserId)}
        {isBillingContact ? this.renderDeleteOrg(organization) : null}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  currentOrgId: getCurrentOrgId(state),
  currentUserId: getCurrentUserId(state),
  organizations: getOrgs(state),
  loading: getOrgLoading(state),
  loadingOrgs: getOrgsLoading(state),
});

/* istanbul ignore next */
const mapDispatchToProps = {
  deleteOrganization,
  fetchOrganization,
  fetchOrganizationMembers,
  fetchOrganizations,
  updateOrganization,
};

/* istanbul ignore next */
const Account = connect(mapStateToProps, mapDispatchToProps)(Presentational);
Account.displayName = 'Account';

export default Account;
