import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import md5 from 'md5';

import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import { fetchAppMemberships } from '../../actions/app_memberships';
import { removeAppMember } from '../../actions/app_members';
import { getAppMemberships, getAppMembershipsErrored, getAppMembershipsLoading } from '../../selectors';
import { getCurrentAppId } from '../../selectors/current_app';
import { getCurrentUserId } from '../../selectors/user';
import sdkEngage from '../../utils/sdk_engage';

import './styles/app_memberships.scss';
import '../../components/styles/molecules/tables.scss';

export class Presentational extends React.Component {
  static displayName = 'AppMembershipsPresentational';
  static propTypes = {
    appMemberships: PropTypes.instanceOf(Array),
    currentApp: PropTypes.string,
    currentUserId: PropTypes.string,
    fetchError: PropTypes.bool,
    loading: PropTypes.bool,
    fetchAppMemberships: PropTypes.func,
    removeAppMember: PropTypes.func,
  };

  static defaultProps = {
    appMemberships: [],
    currentApp: '',
    currentUserId: '',
    loading: true,
    fetchError: false,
    fetchAppMemberships: () => {},
    removeAppMember: () => {},
  };

  componentDidMount() {
    setPageTitleOnMount('Your Projects', { oldTitle: 'Your Projects | Alchemer' });
    this.props.fetchAppMemberships(this.props.currentUserId);
    sdkEngage('app_membership_page');
  }

  removeAppMember = (appId, appMemberId) => (_event) => {
    this.props.removeAppMember(appId, appMemberId);
  };

  render() {
    return (
      <div className="app-memberships index" key={md5(JSON.stringify(this.props.appMemberships))}>
        {this.props.loading ? (
          <div className="loading">
            <h2>Loading...</h2>
          </div>
        ) : null}
        {this.props.fetchError ? (
          <div className="fetch-error">
            <p>There was an error fetching your Projects.</p>
          </div>
        ) : null}
        <table className="app-list">
          <thead>
            <tr>
              <th>Icon</th>
              <th data-sort="string">Project</th>
              <th data-sort="string">Platform</th>
              <th data-sort="string">Organization</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.appMemberships.map((app) => (
              <tr key={app.id}>
                <td className="icon">
                  <img
                    className={`app-icon app-icon--${app.platform.toLowerCase()}`}
                    src={app.icon_url}
                    alt={app.title}
                    title={app.title}
                  />
                </td>
                <td>{app.title}</td>
                <td>{app.platform}</td>
                <td>{app.organization_name}</td>
                <td>
                  {app.id === this.props.currentApp ? (
                    'Current Project'
                  ) : (
                    <button className="button-normalize" onClick={this.removeAppMember(app.id, app.app_member_id)}>
                      Remove Project
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  currentApp: getCurrentAppId(state),
  currentUserId: getCurrentUserId(state),
  appMemberships: getAppMemberships(state),
  loading: getAppMembershipsLoading(state),
  fetchError: getAppMembershipsErrored(state),
});

/* istanbul ignore next */
const mapDispatchToProps = {
  fetchAppMemberships,
  removeAppMember,
};

/* istanbul ignore next */
const AppMemberships = connect(mapStateToProps, mapDispatchToProps)(Presentational);
AppMemberships.displayName = 'AppMemberships';

export default AppMemberships;
