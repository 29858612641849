import React from 'react';

import './sub-title.scss';

interface SubtitleProps {
  className?: string;
  title: React.ReactNode;
  children?: React.ReactNode;
}

export const Subtitle: React.FC<SubtitleProps> = ({ className = '', title, children }) => (
  <div className={`sub-title ${className}`}>
    <h2>{title}</h2>
    {children}
  </div>
);
