import { any, isEmpty, omit, pick, sortBy, values } from 'ramda';
import { DropdownOption } from 'src/components/molecules';
import { getTextFromHtml, getIsStringContainHtml } from 'src/components/molecules/util-handlers';
import {
  PromptMultiAppsItem,
  ImageLayout,
  PromptPayload,
} from 'src/reducers/prompts-multi-apps/prompts-multi-apps.types';
import {
  API_VERSION_WITH_INITIATOR_PROMPTS,
  API_VERSION_WITH_NO_RICH_TEXT_PROMPTS,
  API_VERSION_WITH_RICH_TEXT,
  AppInteractionData,
  InteractionType,
  Platform,
  PromptAction,
  PromptActionType,
} from 'src/types/core';
import { humanizedEventNameList } from 'src/utils/events';
import { InteractionSection } from 'src/interactions/newinteractions/creation/shared-types';
import { MultiAppsSurvey } from '../surveys-multi-apps';
import { ImtModel } from '../imt/imt.model';

export const WEB_IMAGE_SCALE = 1;
export const MOBILE_IMAGE_SCALE = 3;

const defaultImage: PromptMultiAppsItem['image'] = {
  file: '',
  title: '',
  layout: ImageLayout.FULL_WIDTH,
  altText: '',
  scale: MOBILE_IMAGE_SCALE,
};

export const defaultModel: PromptMultiAppsItem = {
  id: '',
  organizationId: '',
  canLaunch: false,
  createdBy: '',
  type: InteractionType.TextModal,
  actions: [
    {
      label: 'Dismiss',
      interactionType: PromptActionType.Dismiss,
      order: 0,
    },
  ],
  interactionData: [
    {
      interactionId: 'int-id',
      appId: '',
      platform: Platform.iOS,
      active: false,
      actions: [
        {
          label: 'Dismiss',
          interactionType: PromptActionType.Dismiss,
          order: 0,
        },
      ],
      codePoints: [],
      criteria: {},
    },
  ],
  name: '',
  title: '',
  body: '',
  image: undefined,
  maxHeight: undefined,
};

export const isAction = {
  Close: (action: PromptAction) => action.interactionType === PromptActionType.Cancel,
  Dismiss: (action: PromptAction) => action.interactionType === PromptActionType.Dismiss,
  Survey: (action: PromptAction) => action.interactionType === PromptActionType.Survey,
  AlchemerSurvey: (action: PromptAction) => action.interactionType === PromptActionType.AlchemerSurvey,
  Link: (action: PromptAction) => action.interactionType === PromptActionType.Link,
  MessageCenter: (action: PromptAction) => action.interactionType === PromptActionType.MessageCenter,
  NilClass: (action: PromptAction) => action.interactionType === PromptActionType.NilClass,
  Initiator: (action: PromptAction) => action.interactionType === PromptActionType.Initiator,
};

export class PromptModel extends ImtModel {
  static Defaults = defaultModel;
  static DefaultImage = defaultImage;
  static sortActionsByOrder = sortActionsByOrder;
  static toLegacy = toLegacy;

  static oldPromptToNew(item: never) {
    return this.convertPayloadKeys(item) as PromptMultiAppsItem;
  }

  static getPromptActionsByAppId(model: PromptMultiAppsItem, appId: string): PromptAction[] {
    return sortActionsByOrder(model.interactionData.find((interaction) => interaction.appId === appId)?.actions || []);
  }

  static getIsContainHtmlFields(model: PromptMultiAppsItem): boolean {
    return getIsStringContainHtml(model.title) || getIsStringContainHtml(model.body);
  }

  static getParsedHtmlFieldsBeforeSave(model: PromptMultiAppsItem): PromptMultiAppsItem {
    return {
      ...model,
      title: getIsStringContainHtml(model.title) ? model.title : (getTextFromHtml(model.title) as string),
      body: getIsStringContainHtml(model.body) ? model.body : (getTextFromHtml(model.body) as string),
    };
  }

  static getInitialSection(
    model: PromptMultiAppsItem,
    reportingFilterAppOptions: DropdownOption[],
    { hash }: Location,
  ): InteractionSection {
    if (hash) {
      const hashSection =
        values(InteractionSection).find((section) => !!String(hash).match(section)) || InteractionSection.Content;

      if (hashSection) return hashSection;
    }

    const isNoteJustCreated = localStorage.getItem('isNoteJustCreated');
    if (isNoteJustCreated) {
      localStorage.removeItem('isNoteJustCreated');
      return InteractionSection.Targeting;
    }

    if (!model.id) {
      return InteractionSection.Apps;
    }

    return model.id && this.hasAnswers(model, reportingFilterAppOptions)
      ? InteractionSection.Reporting
      : InteractionSection.Content;
  }

  static getIsContainInitiator(model: PromptMultiAppsItem): boolean {
    return model.actions.some((action) => isAction.Initiator(action) && this.isActiveAction(action));
  }

  static getApiVersion(model: PromptMultiAppsItem): string {
    if (this.getIsContainInitiator(model)) {
      return API_VERSION_WITH_INITIATOR_PROMPTS;
    }
    return this.getIsContainHtmlFields(model) ? API_VERSION_WITH_RICH_TEXT : API_VERSION_WITH_NO_RICH_TEXT_PROMPTS;
  }

  // README: https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/index.js#L97
  static getShownEvents(interactionData: AppInteractionData[]) {
    const eventsSet = new Set<string>();
    interactionData.forEach(({ codePoints }) => ((codePoints as string[]) || []).forEach((cp) => eventsSet.add(cp)));
    const filteredEvents = Array.from(eventsSet);
    return filteredEvents.length > 0 ? humanizedEventNameList(Array.from(eventsSet) as string[]) : '--';
  }

  static getActionsCount = (interactionData: AppInteractionData[]) =>
    interactionData.reduce((acc, interaction) => acc + (interaction.viewCount ?? 0), 0);

  static isValid(model: PromptMultiAppsItem): boolean {
    const requiredProps = pick(['name'], model);
    const hasContent = !!(model.title || model.body || model.image);
    const hasActions = model.actions.length > 0;

    return !any(isEmpty)(values(requiredProps)) && hasContent && hasActions;
  }

  static setData(model: PromptMultiAppsItem, data: Partial<PromptMultiAppsItem>): PromptMultiAppsItem {
    return { ...model, ...data };
  }

  static setImageData(model: PromptMultiAppsItem, data: Partial<PromptMultiAppsItem['image']>): PromptMultiAppsItem {
    return {
      ...model,
      image: model.image
        ? ({ ...model.image, ...data } as PromptMultiAppsItem['image'])
        : ({ ...data } as PromptMultiAppsItem['image']),
    };
  }

  static getActions({ actions }: Pick<PromptMultiAppsItem, 'actions'>): PromptAction[] {
    return sortActionsByOrder(actions.filter((item) => !item._destroy && !isAction.Close(item)));
  }

  // https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/tab_reporting.js#L79
  // https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/tab_reporting.js#L57
  static getReportedActions(model: PromptMultiAppsItem, reportingFilterAppOptions: DropdownOption[]): PromptAction[] {
    const isAllAppsSelected = reportingFilterAppOptions[0].value === 'all';
    const selectedAppIdsMap = new Map(reportingFilterAppOptions.map((option) => [option.value, true]));
    const selectedActionsMap = new Map();
    let actions: PromptAction[] = [];

    (model.interactionData || []).forEach((appData) => {
      if (!selectedAppIdsMap.has(appData.appId) && !isAllAppsSelected) {
        return;
      }

      appData.actions?.forEach((action) => {
        let updatedAction: PromptAction & { platform?: Platform };
        if (selectedActionsMap.has(action.id)) {
          const existingAction = selectedActionsMap.get(action.id);
          updatedAction = {
            ...existingAction,
            execCount: existingAction.execCount + action.execCount,
          };
          if (isAction.Close(action)) {
            updatedAction.platform = appData.platform;
          }
          actions = [...actions.filter((item) => item.id !== action.id), updatedAction];
          return;
        }

        updatedAction = { ...action };
        if (isAction.Close(action)) {
          updatedAction.platform = appData.platform;
        }
        selectedActionsMap.set(action.id, updatedAction);
        actions = [...actions, updatedAction];
      });
    });

    const sortByCount = (actions: PromptAction[]) => sortBy((item) => -(item.execCount || 0), actions);
    return sortByCount(actions.filter((item) => (item.execCount || 0) > 0));
  }

  static hasImageFile(image: PromptMultiAppsItem['image']): boolean {
    return Boolean(image?.file);
  }

  static isImageAlreadyUploaded(image: PromptMultiAppsItem['image']): boolean {
    return this.hasImageFile(image) && Boolean(typeof image?.file === 'string');
  }

  static hasAnswers(model: PromptMultiAppsItem, reportingFilterAppOptions: DropdownOption[]): boolean {
    return !isEmpty(this.getReportedActions(model, reportingFilterAppOptions));
  }

  static isActiveAction(action: PromptAction): boolean {
    return action._destroy ? false : !isAction.Close(action);
  }

  static getActionsSurveyIds(model: PromptMultiAppsItem): string[] {
    return model.actions.filter((action) => isAction.Survey(action)).map((action) => action.interactionId) as string[];
  }

  static hasInvalidActions(model: PromptMultiAppsItem, surveys: { id?: string }[]): boolean {
    return model.actions.some(
      (action) =>
        action.interactionType === PromptActionType.Survey &&
        !surveys.some(({ id }: { id?: string }) => id === action.interactionId),
    );
  }

  static convertPromptToPayload(prompt: PromptMultiAppsItem) {
    const filteredInteractionData = prompt.interactionData
      .filter((interaction) => !interaction.isExistingInDB || !interaction.isRemoved)
      // eslint-disable-next-line
      .map(({ isExistingInDB, isRemoved, ...rest }) => rest);

    const interactionData = filteredInteractionData.map((interaction) => {
      const actions = interaction.actions?.map((action): PromptAction => {
        if (isAction.AlchemerSurvey(action)) {
          return {
            ...action,
            interactionType: PromptActionType.Link,
          };
        }

        return action;
      });
      return {
        ...interaction,
        actions,
      };
    });

    return this.toLegacy({
      ...prompt,
      interactionData,
    });
  }

  static convertPayloadToPrompt(prompt: PromptPayload): PromptMultiAppsItem {
    const updatedInteractionData = prompt.interaction_data.map((data) => {
      const actions = data.actions?.map((action) =>
        action.append_variables
          ? {
              ...action,
              interaction_type: PromptActionType.AlchemerSurvey,
            }
          : action,
      );

      return {
        ...data,
        actions,
        codePoints: data.code_points || [],
        isExistingInDB: true,
        isRemoved: false,
      };
    });

    return this.oldPromptToNew({
      ...prompt,
      interaction_data: updatedInteractionData,
      actions: prompt.actions || [],
    } as never);
  }

  /**
   * Copy the (new-style) toplevel actions from the (old-style) actions on the first interaction if
   * necessary.
   */
  static ensureToplevelActions(prompt: PromptMultiAppsItem, surveys: MultiAppsSurvey[]): PromptMultiAppsItem {
    // Build a mapping between the per-app (concrete) survey IDs and the ID of the unified
    // interaction that contains them. We need this to build the unified actions later on.
    const concreteToUnifiedSurveyIdMap = Object.fromEntries(
      surveys.flatMap(({ id, interactionData }) =>
        interactionData
          .map(({ interactionId }): [string, string] | null => (interactionId ? [interactionId, id] : null))
          .filter((x): x is [string, string] => !!x),
      ),
    );

    if (prompt.actions.length !== 0) {
      // If the toplevel actions already exist, we should still do our best to make sure the survey
      // actions' interaction ID is filled in.
      return {
        ...prompt,
        actions: prompt.actions.map((action) => {
          if (action.interactionType !== PromptActionType.Survey || action.interactionId) {
            return action;
          }

          const matchingAction = prompt.interactionData[0].actions?.find(
            ({ id, interactionType }) => action.id === id && action.interactionType === interactionType,
          );

          if (!matchingAction) return action;

          return matchingAction.interactionId
            ? { ...action, interactionId: concreteToUnifiedSurveyIdMap[matchingAction.interactionId] }
            : action;
        }),
      };
    }

    return {
      ...prompt,
      actions: (prompt.interactionData[0]?.actions || [])
        .map((actionWithId): PromptAction | null => {
          const action = omit(['id'], actionWithId);

          if (isAction.MessageCenter(action)) {
            // We will fill in the correct ID for the message center later.
            return omit(['interactionId'], action);
          }

          if (isAction.Survey(action)) {
            return action.interactionId
              ? { ...action, interactionId: concreteToUnifiedSurveyIdMap[action.interactionId] }
              : action;
          }

          if (isAction.Close(action)) {
            return null;
          }

          return action;
        })
        .filter((x): x is PromptAction => !!x),
    };
  }

  static getNewPromptInteractionWithActions({
    interaction,
    actions,
    surveys,
    messageCenterIdsByApps,
  }: {
    interaction: AppInteractionData;
    actions: PromptAction[];
    surveys: MultiAppsSurvey[];
    messageCenterIdsByApps: Record<string, string>;
  }): AppInteractionData {
    const unifiedToConcreteSurveyMap = new Map(
      surveys
        .map(({ id, interactionData }) => [
          id,
          interactionData.find(({ appId }) => appId === interaction.appId)?.interactionId,
        ])
        .filter((x): x is [string, string] => !!x[1]),
    );

    return {
      ...interaction,
      actions: (actions || [])
        .map((action): PromptAction | null => {
          if (isAction.Survey(action)) {
            const concreteSurveyId = action.interactionId && unifiedToConcreteSurveyMap.get(action.interactionId);
            if (!concreteSurveyId) return null;

            return {
              ...action,
              interactionId: concreteSurveyId,
            };
          }

          if (isAction.MessageCenter(action)) {
            return { ...action, interactionId: messageCenterIdsByApps[interaction.appId] };
          }

          return action;
        })
        .filter((x): x is PromptAction => !!x),
    };
  }

  static getActionForApp({
    id,
    action,
    appId,
    platform,
    surveys,
    messageCenterIdsByApps,
  }: {
    id?: string;
    action: PromptAction;
    appId: string;
    platform: Platform;
    surveys: MultiAppsSurvey[];
    messageCenterIdsByApps: Record<string, string>;
  }): PromptAction | null {
    if (isAction.Survey(action)) {
      const concreteSurveyId =
        action.interactionId &&
        surveys
          .find(({ id }) => id === action.interactionId)
          ?.interactionData.find(({ appId: interactionAppId }) => appId === interactionAppId)?.interactionId;
      if (!concreteSurveyId) return null;

      return {
        ...action,
        id,
        interactionId: concreteSurveyId,
      };
    }

    if (isAction.AlchemerSurvey(action) || isAction.Link(action)) {
      if (platform !== Platform.Web && action.target === 'self') {
        return { ...action, id, target: 'new' };
      }
    }

    if (isAction.MessageCenter(action)) {
      if (platform === Platform.Web) return null;

      return { ...action, id, interactionId: messageCenterIdsByApps[appId] };
    }

    return { ...action, id };
  }

  static addAction({
    prompt,
    newAction,
    surveys,
    messageCenterIdsByApps,
  }: {
    prompt: PromptMultiAppsItem;
    newAction: PromptAction;
    surveys: MultiAppsSurvey[];
    messageCenterIdsByApps: Record<string, string>;
  }): PromptMultiAppsItem {
    const nextOrder =
      Math.max(
        ...prompt.actions
          .concat(prompt.interactionData.flatMap(({ actions }) => actions || []))
          .reduce<number[]>((acc, { order = -1 }) => acc.concat(order), []),
      ) + 1;

    return {
      ...prompt,
      actions: [...prompt.actions, { ...newAction, order: nextOrder }],
      interactionData: prompt.interactionData.map((int) => {
        const { appId, platform, actions } = int;

        const actionForApp = PromptModel.getActionForApp({
          action: newAction,
          appId,
          platform,
          surveys,
          messageCenterIdsByApps,
        });

        if (!actionForApp) return int;

        return {
          ...int,
          actions: [...(actions || []), { ...actionForApp, order: nextOrder }],
        };
      }),
    };
  }

  static removeAction({
    prompt,
    removedAction,
  }: {
    prompt: PromptMultiAppsItem;
    removedAction: PromptAction;
  }): PromptMultiAppsItem {
    if (removedAction.order == null) return prompt;

    const toplevelIdx = prompt.actions.findIndex(({ order }) => order === removedAction.order);

    if (toplevelIdx === -1) return prompt;

    return {
      ...prompt,
      actions: prompt.actions.slice(0, toplevelIdx).concat(prompt.actions.slice(toplevelIdx + 1)),
      interactionData: prompt.interactionData.map((int) => {
        const { actions = [], isExistingInDB } = int;

        const index = actions.findIndex(({ order, _destroy }) => order === removedAction.order && !_destroy);

        if (index === -1) return int;

        const replacementAction =
          isExistingInDB && actions[index].id
            ? [{ ...omit(['order'], actions[index]), _destroy: 1 } as PromptAction]
            : [];

        return {
          ...int,
          actions: actions.slice(0, index).concat(replacementAction, actions.slice(index + 1)),
        };
      }),
    };
  }

  static changeAction({
    prompt,
    changedAction,
    surveys,
    messageCenterIdsByApps,
  }: {
    prompt: PromptMultiAppsItem;
    changedAction: PromptAction;
    surveys: MultiAppsSurvey[];
    messageCenterIdsByApps: Record<string, string>;
  }): PromptMultiAppsItem {
    const changedOrder = changedAction.order;
    if (changedOrder == null) return prompt;

    const toplevelIdx = prompt.actions.findIndex(({ order }) => order === changedAction.order);

    if (toplevelIdx === -1) return prompt;

    return {
      ...prompt,
      actions: prompt.actions.slice(0, toplevelIdx).concat([changedAction], prompt.actions.slice(toplevelIdx + 1)),
      interactionData: prompt.interactionData.map((int) => {
        const { appId, platform, actions = [], isExistingInDB } = int;

        const index = actions.findIndex(({ order }) => order === changedAction.order);

        const actionForApp = PromptModel.getActionForApp({
          id: actions[index]?.id,
          action: changedAction,
          appId,
          platform,
          surveys,
          messageCenterIdsByApps,
        });

        if (index === -1) {
          if (!actionForApp) return int;

          const insertIndex = actions.findIndex(({ order = -1 }) => order >= changedOrder);
          const spliceIndex = insertIndex === -1 ? actions.length : insertIndex;

          return {
            ...int,
            actions: actions.slice(0, spliceIndex).concat([actionForApp], actions.slice(spliceIndex)),
          };
        }

        let replacementAction: PromptAction[] = [];

        if (actionForApp) {
          replacementAction = [actionForApp];
        } else if (isExistingInDB && actions[index].id) {
          replacementAction = [{ ...actions[index], _destroy: 1 } as PromptAction];
        }

        return {
          ...int,
          actions: actions.slice(0, index).concat(replacementAction, actions.slice(index + 1)),
        };
      }),
    };
  }

  static reorderAction({
    prompt,
    fromIndex,
    toIndex,
  }: {
    prompt: PromptMultiAppsItem;
    fromIndex: number;
    toIndex: number;
  }): PromptMultiAppsItem {
    const newActions = Array.from(prompt.actions);
    const [action] = newActions.splice(fromIndex, 1);
    if (!action) return prompt;

    const newOrder: number[] = [];

    newActions.splice(toIndex, 0, action);
    newActions.forEach((action, i) => {
      if (action.order != null) newOrder.push(action.order);

      if (!isAction.Close(action)) {
        action.order = i;
      }
    });

    return {
      ...prompt,
      actions: newActions,
      interactionData: prompt.interactionData.map((int) => {
        const { actions = [] } = int;
        const actionsByOrder = new Map(
          actions.filter(({ order }) => order != null).map((action) => [action.order as number, action]),
        );

        return {
          ...int,
          actions: newOrder
            .map((oldOrder, i): PromptAction | undefined => {
              const action = actionsByOrder.get(oldOrder);
              return action && { ...action, order: i };
            })
            .filter((x): x is PromptAction => !!x),
        };
      }),
    };
  }
}

function sortActionsByOrder(actions: PromptAction[]) {
  return sortBy((a) => (a.order !== undefined ? a.order : Number.MAX_VALUE), actions);
}

function omitEmptyKeys(obj: Record<string, any>) {
  const res = { ...obj };
  Object.keys(res).forEach((key) => {
    if (res[key] === undefined || res[key] === null) {
      delete res[key];
    }
  });
  return res;
}

function toLegacy(model: PromptMultiAppsItem) {
  const getActions = (action?: PromptAction) =>
    !action
      ? []
      : omitEmptyKeys({
          id: action.id,
          label: action.label,
          interaction_type: action.interactionType,
          order: action.order || 0,
          exec_count: action.execCount,
          interaction_id: action.interactionId,
          append_variables: action.appendVariables,
          url: action.url,
          target: action.target,
          _destroy: action._destroy,
        });

  const optionalFields = {
    updated_at: model.updatedAt,
    created_at: model.createdAt,
    display_type: model.displayType,
    start_time: model.startTime,
    end_time: model.endTime,
    first_active_at: model.firstActiveAt,
    image: model.image && {
      file: model.image.file,
      title: model.image.title,
      layout: model.image.layout,
      alt_text: model.image.altText,
      scale: model.image.scale,
    },
    id: model.id || '',
  };
  return {
    ...omit(
      [
        'canLaunch',
        'displayType',
        'firstActiveAt',
        'updatedAt',
        'createdAt',
        'maxHeight',
        'startTime',
        'endTime',
        'createdBy',
        'interactionData',
        'organizationId',
        'actions',
      ],
      model,
    ),
    name: model.name,
    title: model.title,
    body: model.body,
    can_launch: model.canLaunch,
    max_height: model.maxHeight,
    actions: model.actions.map(getActions),
    interaction_data: model.interactionData.map((data) =>
      omitEmptyKeys({
        interaction_id: data.interactionId,
        app_id: data.appId,
        platform: data.platform,
        active: data.active,
        code_points: data.codePoints,
        criteria: data.criteria || {},
        triggered_by: data.triggeredBy,
        actions: data.actions?.map(getActions),
        archived_actions: data.archivedActions?.map(getActions),
      }),
    ),
    organization_id: model.organizationId,
    created_by: model.createdBy,
    type: model.type,
    ...omitEmptyKeys(optionalFields),
  };
}
