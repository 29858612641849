import React, { Children } from 'react';
import PropTypes from 'prop-types';

function renderChild(child, props) {
  const { has_next, has_prev, interactive, items = [], selected = [], togglePage, toggleSelected } = props;
  const selectedCount = selected.length;
  const isIndeterminate = selectedCount && items.length && selectedCount !== items.length;

  return React.cloneElement(child, {
    has_next,
    has_prev,
    interactive,
    checked: isIndeterminate ? 'indeterminate' : !!selectedCount,
    togglePage,
    toggleSelected,
  });
}

const TableHead = (props) => {
  const { children } = props;

  return (
    <thead>
      {Children.map(children, child => renderChild(child, props))}
    </thead>
  );
};

/* eslint-disable react/no-unused-prop-types */
TableHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  has_next: PropTypes.bool,
  has_prev: PropTypes.bool,
  interactive: PropTypes.bool,
  items: PropTypes.array,
  selected: PropTypes.array,
  togglePage: PropTypes.func,
  toggleSelected: PropTypes.func,
};
/* eslint-enable react/no-unused-prop-types */

TableHead.defaultProps = {
  has_next: false,
  has_prev: false,
  interactive: false,
  items: [],
  selected: [],
  togglePage: () => {},
  toggleSelected: () => {},
};

export default TableHead;
