import React from 'react';
import PropTypes from 'prop-types';

const ColHeader = ({ children, className, colSpan, sortDisplay, sortKey, sortStatus, toggleSort }) => {
  const attributes = {};
  if (sortDisplay) {
    const status = sortStatus === 'desc' ? 'asc' : 'desc';
    attributes.onClick = () => toggleSort(sortKey, status);
  }

  return (
    <th className={`col col--header ${className}`} colSpan={colSpan}>
      {!children ? null : (
        <button className="button-normalize icon icon__button" {...attributes}>
          {children}
          {sortDisplay && <i className={`icon icon--sort icon--sort-${sortStatus}`} />}
        </button>
      )}
    </th>
  );
};

ColHeader.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  colSpan: PropTypes.number,
  sortDisplay: PropTypes.bool,
  sortKey: PropTypes.string,
  sortStatus: PropTypes.oneOf(['asc', 'desc', 'none']),
  toggleSort: PropTypes.func,
};

ColHeader.defaultProps = {
  className: '',
  colSpan: 1,
  sortDisplay: false,
  sortStatus: 'none',
  toggleSort: () => {},
};

export default ColHeader;
