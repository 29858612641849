import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { filter, head, propEq } from 'ramda';

import { getCurrentOrgId } from '../../selectors/current_app';
import { getOrgs } from '../../selectors/organizations';
import { getCurrentUserId } from '../../selectors/user';

import './styles/sidebar.scss';

class SidebarPresentational extends React.Component {
  static displayName = 'SidebarPresentational';
  static propTypes = {
    currentOrgId: PropTypes.string,
    currentUserId: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    organizations: PropTypes.instanceOf(Object),
    match: PropTypes.shape({
      params: PropTypes.shape({
        orgId: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    currentOrgId: '',
    currentUserId: '',
    location: {
      pathname: '',
    },
    organizations: {},
    match: {
      params: {
        orgId: '',
      },
    },
  };

  getOrgId = () => (this.props.match.params && this.props.match.params.orgId ? this.props.match.params.orgId : this.props.currentOrgId);

  getOrg = () => this.props.organizations[this.getOrgId()] || {};

  getBillingContact = () => {
    const org = this.getOrg();
    return head(filter(propEq('user_id', org.billing_contact_id), org.members || [])) || {};
  };

  isBillingContact = () => {
    const billingContact = this.getBillingContact();

    // NOTE: Read Only Mode for non-billing contacts.
    let isBillingContact = false;
    if (billingContact.user_id === this.props.currentUserId) {
      isBillingContact = true;
    }
    return isBillingContact;
  };

  render() {
    const orgId = this.getOrgId();
    const isBillingContact = this.isBillingContact();
    const { currentUserId, location } = this.props;
    return (
      <Sticky top="header.navbar">
        <div className="sidebar__nav-list" key={`${currentUserId}-${orgId}`}>
          <div className="link-list">
            <ul>
              <li className={`profile ${location.pathname === '/profile' ? 'is-active' : ''}`}>
                <Link to="/profile">Your Profile</Link>
              </li>
              <li className={`app-memberships ${location.pathname === '/app-memberships' ? 'is-active' : ''}`}>
                <Link to="/app-memberships">Your Projects</Link>
              </li>
              <li className={`email-preferences ${location.pathname === '/email-preferences' ? 'is-active' : ''}`}>
                <Link to="/email-preferences">Email Preferences</Link>
              </li>
              <li className={`overview ${/^\/account\/([a-f\d]{24})$/.test(location.pathname) ? 'is-active' : ''}`}>
                <Link to={`/account/${orgId}`}>Company Account Overview</Link>
              </li>
              {
                isBillingContact ? (
                  <li className={`subcontractors ${location.pathname === '/account/subcontractors' ? 'is-active' : ''}`}>
                    <Link to="/account/subcontractors">Sub-contractor Disclosure</Link>
                  </li>
                ) : null
              }
            </ul>
          </div>
        </div>
      </Sticky>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  currentOrgId: getCurrentOrgId(state),
  currentUserId: getCurrentUserId(state),
  organizations: getOrgs(state),
});

/* istanbul ignore next */
const Sidebar = connect(mapStateToProps, {})(SidebarPresentational);
Sidebar.displayName = 'Sidebar';

export default Sidebar;
