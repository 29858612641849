import { mergeDeepRight } from 'ramda';

const debugReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'DEBUG_STATE': {
      return mergeDeepRight(state, action.payload);
    }
    case 'DEBUG_FEATURE': {
      if (Array.isArray(action.payload) && Array.isArray(state?.user?.features)) {
        return {
          ...state,
          user: {
            ...state.user,
            features: [
              ...state.user.features,
              ...action.payload,
            ],
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default debugReducer;
