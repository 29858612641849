import React from 'react';
import PropTypes from 'prop-types';

import ColAction from './col_action';

const RowHeader = ({ children, interactive, checked, disabled = false, onChange }) => {
  const attributes = { checked: checked === true ? checked : false };

  return (
    <tr className="row row--header">
      {interactive && (
        <ColAction>
          {!disabled && <input
            type="checkbox"
            {...attributes}
            onChange={onChange}
            ref={input => {
              if (input) input.indeterminate = checked === 'indeterminate';
            }}
          />}
        </ColAction>
      )}
      {children}
    </tr>
  );
};

RowHeader.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  interactive: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RowHeader;
