import { Rest, API } from 'src/api';
import { getNoteWithUploadedImage } from 'src/utils/s3_upload';
import { PromptMultiAppsItem, PromptModel, PromptPayload, DeletePromptResponse } from 'src/reducers/prompts-multi-apps';
import { AppInteractionData, MultiAppCloningResult, PromptActionType } from 'src/types/core';
import { BaseService } from './base-service';

export class PromptMultiAppsService extends BaseService {
  static async save(orgId: string, prompt: PromptMultiAppsItem): Promise<PromptPayload> {
    let promptToUpload: PromptMultiAppsItem = { ...prompt };
    const apiVersion = PromptModel.getApiVersion(prompt);
    const hasFileToUpload = PromptModel.hasImageFile(prompt.image) && !PromptModel.isImageAlreadyUploaded(prompt.image);
    if (hasFileToUpload) {
      promptToUpload = (await getNoteWithUploadedImage(orgId, promptToUpload)) as PromptMultiAppsItem;
    }

    const body: any = prepareNestedAttributes(PromptModel.getParsedHtmlFieldsBeforeSave(promptToUpload));

    if (body.id) {
      if (!body.image) {
        body.is_image_removed = true;
      }

      // https://github.com/apptentive/pupum/blob/61b5a1b27c7ee3ad1ebf09bcdfe9bda2360cdda5/legacy/assets/models/note.js#L107
      return Rest.httpPut(API.PROMPTS_MULTI_APPS.UPDATE(orgId, body.id as string, apiVersion), {
        body,
        errorTitle: '[API] Error PUT UPDATE prompt item',
      }).promise;
    }
    return Rest.httpPost(API.PROMPTS_MULTI_APPS.CREATE(orgId, apiVersion), {
      body,
      errorTitle: '[API] Error POST CREATE prompt item',
    }).promise;
  }

  static activate(orgId: string, promptId: string, active: boolean, apiVersion: string): Promise<PromptPayload> {
    return Rest.httpPut(API.PROMPTS_MULTI_APPS.UPDATE(orgId, promptId, apiVersion), {
      body: { active },
      errorTitle: '[API] Error PUT activate prompt item',
    }).promise;
  }

  static fetchPrompts(orgId: string): Promise<PromptPayload[]> {
    return Rest.httpGet(API.PROMPTS_MULTI_APPS.ALL(orgId), {
      errorTitle: '[API] Error GET notes list',
    }).promise;
  }

  static fetchPrompt(orgId: string, promptId: string): Promise<PromptPayload> {
    return Rest.httpGet(API.PROMPTS_MULTI_APPS.GET(orgId, promptId), {
      errorTitle: '[API] Error GET prompt item',
    }).promise;
  }

  static deletePrompt(orgId: string, promptId: string): Promise<DeletePromptResponse> {
    return Rest.httpDelete(API.PROMPTS_MULTI_APPS.DELETE(orgId, promptId), {
      errorTitle: '[API] Error DELETE prompt item',
    }).promise;
  }

  static clonePromptToApp(
    orgId: string,
    promptId: string,
    options: { cloneTargeting: boolean },
  ): Promise<PromptMultiAppsItem> {
    return Rest.httpPost(API.PROMPTS_MULTI_APPS.CLONE(orgId, promptId), {
      body: { options },
      errorTitle: '[API] Error POST clone prompt to appId',
    }).promise;
  }

  static clonePromptToApps({
    orgId,
    appIds,
    promptId,
    options: { cloneTargeting, cloneOneToOne },
  }: {
    orgId: string;
    appIds: string[];
    promptId: string;
    options: { cloneTargeting: boolean; cloneOneToOne: boolean };
  }): Promise<MultiAppCloningResult[]> {
    return Rest.httpPost(API.PROMPTS_MULTI_APPS.CLONE(orgId, promptId), {
      body: { clone_targeting: cloneTargeting, clone_one_to_one: cloneOneToOne, app_ids: appIds },
      errorTitle: '[API] Error POST clone prompt to appIds',
    }).promise;
  }
}

function prepareNestedAttributes(prompt: PromptMultiAppsItem) {
  return PromptModel.convertPromptToPayload({
    ...prompt,
    interactionData: prompt.interactionData.map(
      (interaction) =>
        ({
          ...interaction,
          actions: interaction.actions?.filter(({ interactionType }) => interactionType !== PromptActionType.Cancel),
        }) as AppInteractionData,
    ),
  });
}
