import React, { useMemo, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { keys, prop, sortBy, isEmpty } from 'ramda';

import { Organization } from 'src/reducers/organizations';
import { Button, ButtonProps, usePopoverProps } from 'src/components/molecules';
import { AppDrawerApps } from './app_drawer_apps';
import { AppLimitPopover } from './app-limit-popover';

import './app_drawer.scss';
import { AppMemberRole } from "../../reducers/apps.types";

export interface AppDrawerDisplayProps {
  appDrawerOpen: boolean;
  currentApp: string;
  userId: string;
  currentAppRole: string;
  organizations: Record<string, Organization>;
  remainAppsCount: number | null;
  fetchOrganizationApps(userId: string, orgId: string): void;
  handleClickOutside(e: React.MouseEvent): void;
}

let fetchedByAppId = '';
let orgAppsFetched: string[] = [];
const setOrgAppsFetched = (appIDs: string[]) => (orgAppsFetched = [...appIDs]);

export const AppDrawerDisplay: React.FC<AppDrawerDisplayProps> = React.memo(
  ({
    currentApp,
    remainAppsCount,
    appDrawerOpen,
    organizations,
    userId,
    currentAppRole,
    handleClickOutside,
    fetchOrganizationApps,
  }) => {
    const history = useHistory();
    const { isOpenPopover, targetRef, showPopover, closePopover } = usePopoverProps();

    const sortedOrgs = useMemo(() => {
      return sortBy(
        prop('name'),
        keys(organizations).map((orgId) => ({
          id: orgId,
          name: organizations[orgId].name,
          apps: organizations[orgId].apps || [],
        }))
      );
    }, [organizations]);

    const isReachedAppLimit = useMemo(() => (remainAppsCount !== null ? remainAppsCount <= 0 : false), [
      remainAppsCount,
    ]);

    const onAddNewApp = useCallback(() => {
      if (isReachedAppLimit) {
        showPopover();
      } else {
        history.push('/apps/new');
      }
    }, [history, isReachedAppLimit]);

    useEffect(() => {
      if (fetchedByAppId !== currentApp) {
        setOrgAppsFetched([]);
        fetchedByAppId = currentApp;
        return;
      }

      const orgIdsToFetchApps = sortedOrgs.filter((org) => !orgAppsFetched.includes(org.id)).map((org) => org.id);

      if (!isEmpty(orgIdsToFetchApps)) {
        setOrgAppsFetched([...orgAppsFetched, ...orgIdsToFetchApps]);

        orgIdsToFetchApps.forEach((orgId) => {
          fetchOrganizationApps(userId, orgId);
        });
      }
    }, [sortedOrgs, orgAppsFetched, currentApp, userId, fetchOrganizationApps]);

    if (!appDrawerOpen) {
      return null;
    }

    const btnProps: Partial<ButtonProps> = isReachedAppLimit
      ? {}
      : {
        color: 'primary',
        reverted: true,
      };

    const isReporter = currentAppRole === AppMemberRole.Reporter;

    return (
      <div className="app-drawer open">
        <div className="drawer" onMouseLeave={handleClickOutside}>
          <div className="orgs-list">
            <header>
              <Link to="/apps" className="rollup-link">
                All Projects
              </Link>
            </header>
            {sortedOrgs.map((org) => (
              <section key={`org-${org.id}-${org.apps.length}-${currentApp}`} className="organization">
                <h1>{org.name}</h1>
                <AppDrawerApps apps={org.apps} currentApp={currentApp} />
              </section>
            ))}
          </div>
          {!isReporter && (
            <div className="app-select-add-app">
              <Button label="+ Add Project" forwardRef={targetRef} onClick={onAddNewApp} {...btnProps} />
            </div>
          )}
          {isOpenPopover && <AppLimitPopover targetEl={targetRef.current} onClose={closePopover} isLimitReached />}
        </div>
      </div>
    );
  }
);
