export interface ApiKey {
    key_id: string;
    full_key_name: string;
    target_id: string;
    description: string;
    created_at: string;
    expired_at: string | null;
    key_type: ApiKeyType;
}

export enum ApiKeyType {
    user = 'user',
    org = 'org',
}
