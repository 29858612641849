import React, { Component } from 'react';
import { connect } from 'react-redux';
import { __, append, without, zipObj } from 'ramda';
import md5 from 'md5';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import { State } from '../../reducers';

import { fetchEmailPreferences, updateUserEmailPreferences } from '../../actions/email_preferences';
import { getEmailPreferences, getEmailPreferencesErrors, getEmailPreferencesLoading } from '../../selectors';
import { getCurrentUserId, getFeatures } from '../../selectors/user';
import EmailPreferencesRow from './email_preferences_row';

import './styles/email_preferences.scss';
import '../../components/styles/molecules/tables.scss';
import { EmailFrequency, Features } from '../../types/core';
import engageSDK from '../../utils/sdk_engage';

interface UserOptions {
  new_message_notifications?: boolean;
  unresponded_messages_enabled?: boolean | string;
  email_preferences_reviews?: EmailFrequency;
  email_preferences_metrics_alerts?: EmailFrequency;
}

interface EmailPreference {
  app_member_id: string;
  email_preferences_reviews: EmailFrequency;
  email_preferences_metrics_alerts: EmailFrequency;
  icon_url: string;
  id: string;
  new_message_notifications: boolean;
  platform: string;
  title: string;
}

export interface EmailPreferencesProps {
  currentUserId: string;
  emailPreferences: EmailPreference[];
  fetchEmailPreferences: (currentUserId: string) => Promise<void>;
  fetchError: boolean;
  loading: boolean;
  features: Features[];
  updateUserEmailPreferences: (currentUserId: string, output: any) => Promise<void>;
}

interface IState {
  selected: string[];
}

export class Presentational extends Component<EmailPreferencesProps, IState> {
  static displayName = 'EmailPreferencesPresentational';

  static defaultProps = {
    currentUserId: '',
    emailPreferences: [],
    fetchEmailPreferences: () => {},
    fetchError: false,
    loading: true,
    features: [],
    updateUserEmailPreferences: () => {},
  };

  state: IState = {
    selected: [],
  };

  componentDidMount() {
    setPageTitleOnMount('Email Preferences', { oldTitle: 'Email Preferences | Alchemer' });
    this.props.fetchEmailPreferences(this.props.currentUserId);
    engageSDK('email_preferences_page');
  }

  setSelected = (appId: string) => () => {
    const current = this.state.selected;
    const toggleValue = current.includes(appId) ? without([appId], current) : append(appId, current);
    this.setState({
      selected: toggleValue,
    });
  };

  selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      this.setState({ selected: this.props.emailPreferences.map((r) => r.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleBulkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    if (!this.state.selected.length) {
      return;
    }

    const data: UserOptions = {};

    // Logic switch based on selected value
    switch (event.target.value) {
      case 'all_unsubscribe':
        data.new_message_notifications = false;
        data.unresponded_messages_enabled = false;
        data.email_preferences_reviews = EmailFrequency.Unsubscribed;
        break;
      case 'nmn_subscribe':
        data.new_message_notifications = true;
        break;
      case 'nmn_unsubscribe':
        data.new_message_notifications = false;
        break;
      case `um_${EmailFrequency.Daily}`:
        data.unresponded_messages_enabled = EmailFrequency.Daily;
        break;
      case `um_${EmailFrequency.Weekly}`:
        data.unresponded_messages_enabled = EmailFrequency.Weekly;
        break;
      case 'um_subscribe':
        data.unresponded_messages_enabled = true;
        break;
      case 'um_unsubscribe':
        data.unresponded_messages_enabled = false;
        break;
      case `reviews_${EmailFrequency.Daily}`:
        data.email_preferences_reviews = EmailFrequency.Daily;
        break;
      case `reviews_${EmailFrequency.Weekly}`:
        data.email_preferences_reviews = EmailFrequency.Weekly;
        break;
      case `reviews_${EmailFrequency.Unsubscribed}`:
        data.email_preferences_reviews = EmailFrequency.Unsubscribed;
        break;
      case `metrics_alerts_${EmailFrequency.Daily}`:
        data.email_preferences_metrics_alerts = EmailFrequency.Daily;
        break;
      case `metrics_alerts_${EmailFrequency.Weekly}`:
        data.email_preferences_metrics_alerts = EmailFrequency.Weekly;
        break;
      case `metrics_alerts_${EmailFrequency.Unsubscribed}`:
        data.email_preferences_metrics_alerts = EmailFrequency.Unsubscribed;
        break;
      default:
        console.error('Missing type', event.target.value);
        return;
    }

    const filler = new Array(this.state.selected.length);
    const output = zipObj(this.state.selected, filler.fill(data));

    this.props.updateUserEmailPreferences(this.props.currentUserId, output);

    this.setState({ selected: [] });
  };

  renderEmailFrequencyOptGroup(label: string, prefix: string) {
    return (
      <optgroup label={label}>
        <option value={prefix + EmailFrequency.Daily}>Daily</option>
        <option value={prefix + EmailFrequency.Weekly}>Weekly</option>
        <option value={prefix + EmailFrequency.Unsubscribed}>Unsubscribe</option>
      </optgroup>
    );
  }

  render() {
    return (
      <div className="email-preferences index" key={md5(JSON.stringify(this.props.emailPreferences))}>
        {this.state.selected.length > 0 ? (
          <select className="email-subscriptions" defaultValue="" onChange={this.handleBulkChange}>
            <option disabled selected value="">
              Change to...
            </option>
            {this.renderEmailFrequencyOptGroup('App Store Reviews', 'reviews_')}
            {this.renderEmailFrequencyOptGroup('Alerts', 'metrics_alerts_')}
            <optgroup label="New Messages">
              <option value="nmn_subscribe">Subscribe</option>
              <option value="nmn_unsubscribe">Unsubscribe</option>
            </optgroup>
            <optgroup label="Unresponded Messages">
              <option value="um_subscribe">Subscribe</option>
              <option value="um_unsubscribe">Unsubscribe</option>
            </optgroup>
            <optgroup label="All">
              <option value="all_unsubscribe">Unsubscribe from All</option>
            </optgroup>
          </select>
        ) : null}
        {this.props.loading ? (
          <div className="loading">
            <h2>Loading...</h2>
          </div>
        ) : null}
        {this.props.fetchError ? (
          <div className="fetch-error">
            <p>There was an error fetching Email Preferences.</p>
          </div>
        ) : null}
        <table className="app-list">
          <thead>
            <tr>
              <th className="center-align">
                <input className="select-all" type="checkbox" onChange={this.selectAll} />
              </th>
              <th>Icon</th>
              <th data-sort="string">Project</th>
              <th className="center-align">App Store Reviews</th>
              <th className="center-align">Alerts</th>
              <th className="center-align">New Messages</th>
              <th className="center-align">Unresponded Messages</th>
            </tr>
          </thead>
          <tbody>
            {this.props.emailPreferences.map((row) => (
              <EmailPreferencesRow
                currentUserId={this.props.currentUserId}
                key={md5(JSON.stringify(row))}
                updateUserEmailPreferences={this.props.updateUserEmailPreferences}
                {...row}
                selected={this.state.selected.includes(row.id)}
                setSelected={this.setSelected(row.id)}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: State) => ({
  currentUserId: getCurrentUserId(state),
  emailPreferences: getEmailPreferences(state),
  fetchError: getEmailPreferencesErrors(state),
  loading: getEmailPreferencesLoading(state),
  features: getFeatures(state),
});

/* istanbul ignore next */
const mapDispatchToProps = {
  fetchEmailPreferences,
  updateUserEmailPreferences,
};

/* istanbul ignore next */
const EmailPreferences = connect(mapStateToProps, mapDispatchToProps)(Presentational as React.ComponentClass);
EmailPreferences.displayName = 'EmailPreferences';

export default EmailPreferences;
