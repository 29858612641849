import { Rest, API } from 'src/api';
import { InitiatorItem } from 'src/reducers/initiators';
import { BaseService } from './base-service';

export class InitiatorsService extends BaseService {
  static async save(appId: string, initiator: InitiatorItem): Promise<InitiatorItem> {
    if (initiator.id) {
      return Rest.httpPut(API.INITIATORS.UPDATE(appId, initiator.id as string), {
        body: initiator,
        errorTitle: '[API] Error PUT UPDATE initiator item',
      }).promise;
    }

    throw new Error('Internal Error: attempt to create/update initiator without ID');
  }

  static activate(appId: string, initiatorId: string, active: boolean): Promise<InitiatorItem> {
    return Rest.httpPut(API.INITIATORS.UPDATE(appId, initiatorId), {
      body: { active },
      errorTitle: '[API] Error PUT activate initiator item',
    }).promise;
  }

  static fetchInitiators(appId: string): Promise<InitiatorItem[]> {
    return Rest.httpGet(API.INITIATORS.ALL(appId), {
      errorTitle: '[API] Error GET initiators list',
    }).promise;
  }

  static fetchInitiator(appId: string, initiatorId: string): Promise<InitiatorItem> {
    return Rest.httpGet(API.INITIATORS.GET(appId, initiatorId), {
      errorTitle: '[API] Error GET initiator item',
    }).promise;
  }

  static deleteInitiator(appId: string, initiatorId: string): Promise<any> {
    return Rest.httpDelete(API.INITIATORS.DELETE(appId, initiatorId), {
      errorTitle: '[API] Error DELETE initiator item',
    }).promise;
  }
}
