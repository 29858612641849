/* eslint-disable arrow-body-style */

import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SurveyMultiAppsService } from 'src/services';
import { GetState, DispatchFunc } from 'src/reducers';
import { isType } from 'src/reducers/surveys-multi-apps/surveys-multi-apps.utils';
import { getCurrentOrgId } from 'src/selectors/current_app';
import {
  getSurveyById,
  getSurveysMultiAppsIsLoading,
  getSurveysMultiAppsIsBootstrapped,
} from 'src/selectors/surveys-multi-apps.selectors';
import { LoveDialogActions } from 'src/actions/love-dialog/love-dialog.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import {
  FetchSurveysMultiAppsResp,
  InteractionDataResponseStat,
  MultiAppsSurvey,
  SurveyResponseStat,
  SurveyTextAnswers,
} from 'src/reducers/surveys-multi-apps';
import { AppInteractionData } from 'src/types/core';
import { Time } from 'src/utils';
import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { fetchSurveys, FetchSurveysConfigEnum, updateEmailSummaryAppIds } from '../surveys-multi-apps';
import { SurveyMultiAppsActions } from './survey-multi-apps.action-types';

export interface SurveyMultiAppsActionPayload {
  ids?: string[];
  id?: string;
  surveyId?: string;
  textAnswers?: Record<string, SurveyTextAnswers>;
  stats?: InteractionDataResponseStat[];
  surveysActive?: MultiAppsSurvey[];
  surveysNotActive?: MultiAppsSurvey[];
  interactionData?: AppInteractionData[];
  survey?: MultiAppsSurvey;
  apps?: string[];
  active?: boolean;
  questionId?: string;
}

export type SurveyReducerActions = Action<
  | SurveyMultiAppsActions
  | LoveDialogActions.SAVE_LOVE_DIALOG_SUCCESS
  | typeof UPDATE_CURRENT_APP_PENDING
  | typeof UPDATE_CURRENT_APP_SUCCESS
> & {
  payload?: SurveyMultiAppsActionPayload;
  surveys?: MultiAppsSurvey[];
  surveyId?: string;
  emailSummaryAppIds?: string[];
  active?: boolean;
  isFetching?: boolean;
  error?: Error;
  page?: number;
  total?: number;
};

type SurveysDispatch = DispatchFunc<SurveyReducerActions>;

export const surveys = {
  fetchSurveys,
  updateEmailSummaryAppIds,

  deleteSurvey: (surveyId: string, apiVersion: string) => {
    return (dispatch: SurveysDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      dispatch({
        type: SurveyMultiAppsActions.REQUEST_DELETE_SURVEY_MULTI_APPS,
        surveyId,
      });

      return SurveyMultiAppsService.delete(orgId, surveyId, apiVersion)
        .then(() =>
          dispatch({
            type: SurveyMultiAppsActions.RECEIVE_DELETE_SURVEY_MULTI_APPS,
            surveyId,
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.ERRORED_DELETE_SURVEY_MULTI_APPS,
            surveyId,
            error,
          }),
        );
    };
  },

  bootstrapSurveys: () => {
    return (dispatch: SurveysDispatch, getState: GetState) => {
      const state = getState();
      const isLoading = getSurveysMultiAppsIsLoading(state);
      const isBootstrapped = getSurveysMultiAppsIsBootstrapped(state);

      dispatch({ type: SurveyMultiAppsActions.BOOTSTRAP_SURVEYS_MULTI_APPS, payload: {} });

      const wrapWithPagination = (
        fetchSurveysHandler: (
          page: number | undefined,
          config: FetchSurveysConfigEnum,
        ) => (
          dispatch: ThunkDispatch<{}, {}, AnyAction>,
          getState: GetState,
        ) => Promise<FetchSurveysMultiAppsResp | void>,
        page: number,
      ): Promise<FetchSurveysMultiAppsResp | unknown | void> => {
        const handlePagination = (
          json: FetchSurveysMultiAppsResp,
        ): Promise<FetchSurveysMultiAppsResp | unknown | void> => {
          return dispatch(fetchSurveysHandler(json.page + 1, FetchSurveysConfigEnum.ACTIVE))
            .then((res: any = { has_more: false }) => {
              if (!res.has_more) {
                return new Promise((resolve) => resolve({ ...res, surveys: [...json.surveys, ...res.surveys] }));
              }

              return handlePagination({ ...json, page: json.page + 1, surveys: [...json.surveys, ...res.surveys] });
            })
            .catch((error) => new Error(`Failed to fetch bootstrapSurveys because of ${error}`));
        };

        return dispatch(fetchSurveysHandler(page, FetchSurveysConfigEnum.ACTIVE))
          .then((json: any = { has_more: false }) => {
            if (!json.has_more) {
              return new Promise((resolve) => resolve(json));
            }
            return handlePagination(json);
          })
          .catch((error) => new Promise((_, rej) => rej(error)));
      };

      if (!isLoading && !isBootstrapped) {
        return wrapWithPagination(surveys.fetchSurveys, 1);
      }
      return Promise.resolve();
    };
  },

  fetchSurveysByIds: (surveyIds: string[]) => {
    return (dispatch: Function, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());
      dispatch({ type: SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_PENDING, payload: { ids: surveyIds } });

      const promises: Promise<MultiAppsSurvey>[] = [];
      surveyIds.forEach((id) => {
        const promise = new Promise((res) => res(SurveyMultiAppsService.fetchSurvey(orgId, id)));
        promises.push(promise as Promise<MultiAppsSurvey>);
      });

      return Promise.all(promises)
        .then((resp) =>
          dispatch({
            type: SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_SUCCESS,
            payload: resp.map((item) =>
              SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave(SurveyMultiAppsModel.oldSurveyToNew(item)),
            ),
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_ERROR,
            payload: { ids: surveyIds },
            error,
          }),
        );
    };
  },

  fetchSurvey: (surveyId: string) => {
    return (dispatch: Function, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());
      dispatch({ type: SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_PENDING, payload: { id: surveyId } });

      return SurveyMultiAppsService.fetchSurvey(orgId, surveyId)
        .then((survey) =>
          dispatch({
            type: SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_SUCCESS,
            payload: SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave(SurveyMultiAppsModel.oldSurveyToNew(survey)),
          }),
        )
        .catch((error) =>
          dispatch({ type: SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_ERROR, payload: { id: surveyId }, error }),
        );
    };
  },

  fetchStats: (surveyId: string, apps: string[], start: number, end: number) => {
    return (dispatch: SurveysDispatch, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(getState());
      const survey = getSurveyById(state, surveyId);
      dispatch({ type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_PENDING, payload: { surveyId } });

      const questions = survey?.questionSetAttributes.map((item) => item.questions[0]);
      const inialStates = questions?.map((q) => ({
        id: q.id,
        answers: (q.answerChoices || []).map((ans) => ({
          choice: ans.value,
          count: 0,
        })),
      }));

      const startDate = Time.format(start, Time.FORMAT.YYYY_MM_DD);
      const endDate = Time.format(end, Time.FORMAT.YYYY_MM_DD);

      return SurveyMultiAppsService.getSurveyStats(orgId, surveyId, apps, startDate, endDate)
        .then((intResponseStats) => {
          const updatedStats = intResponseStats.map((item) => {
            const newStats = item.stats.map((ans) => {
              if (ans.answer_count === 0 && isType.Multichoice(ans)) {
                const resAns = inialStates?.find((elem) => elem.id === ans.id);
                return { ...ans, answers: resAns?.answers } as SurveyResponseStat;
              }
              return ans;
            });
            return { ...item, stats: newStats };
          });

          return dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_SUCCESS,
            payload: { surveyId, stats: updatedStats, apps },
          });
        })
        .catch((error) =>
          dispatch({ type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_ERROR, payload: { surveyId }, error }),
        );
    };
  },

  fetchTextAnswers: (
    surveyId: string,
    apps: string[],
    start: number,
    end: number,
    pagination?: { limit: number; page: number },
  ) => {
    return (dispatch: SurveysDispatch, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(state);
      const survey = getSurveyById(state, surveyId);
      const questions = survey
        ? survey.questionSetAttributes
            .map((item) => item.questions[0])
            .filter((item) => isType.Singleline(item) || isType.Multichoice(item) || isType.Multiselect(item)) // TODO: Check another types if needed!
            .filter(
              (item) =>
                (item.answerChoices && SurveyMultiAppsModel.isQuestionWithOtherChoice(item)) || isType.Singleline(item),
            )
            .map((item) => item.id as string)
        : [];

      dispatch({ type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_PENDING, payload: { surveyId } });

      const startDate = Time.format(start, Time.FORMAT.YYYY_MM_DD);
      const endDate = Time.format(end, Time.FORMAT.YYYY_MM_DD);

      return SurveyMultiAppsService.getTextAnswers(orgId, surveyId, questions, apps, startDate, endDate, pagination)
        .then((textAnswers) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_SUCCESS,
            payload: { surveyId, textAnswers, apps },
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_ERROR,
            payload: { surveyId },
            error,
          }),
        );
    };
  },

  fetchQuestionTextAnswers: (
    surveyId: string,
    questionId: string,
    apps: string[],
    start: number,
    end: number,
    pagination?: { limit: number; page: number },
  ) => {
    return (dispatch: Function, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(state);

      dispatch({
        type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_PENDING,
        payload: { surveyId, questionId },
      });

      const startDate = Time.format(start, Time.FORMAT.YYYY_MM_DD);
      const endDate = Time.format(end, Time.FORMAT.YYYY_MM_DD);

      return SurveyMultiAppsService.getTextAnswers(orgId, surveyId, [questionId], apps, startDate, endDate, pagination)
        .then((textAnswers) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_SUCCESS,
            payload: { surveyId, textAnswers, questionId, apps },
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_ERROR,
            payload: { surveyId, questionId },
            error,
          }),
        );
    };
  },

  save: (item: MultiAppsSurvey, apiVersion: string) => {
    return (dispatch: Function, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      if (!item.id) {
        const questionSetAttributes = SurveyMultiAppsModel.enrichQSetsWithDefaultSkipLogic(item.questionSetAttributes);
        const json = SurveyMultiAppsModel.toLegacy(
          SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave({ ...item, questionSetAttributes }),
        );
        dispatch({ type: SurveyMultiAppsActions.CREATE_SURVEY_MULTI_APPS_PENDING, payload: item });
        return SurveyMultiAppsService.create(orgId, json as unknown as MultiAppsSurvey, apiVersion)
          .then((survey) =>
            dispatch({
              type: SurveyMultiAppsActions.CREATE_SURVEY_MULTI_APPS_SUCCESS,
              payload: SurveyMultiAppsModel.oldSurveyToNew(survey),
            }),
          )
          .catch((error) =>
            dispatch({ type: SurveyMultiAppsActions.CREATE_SURVEY_MULTI_APPS_ERROR, payload: item, error }),
          );
      }

      const questionSetAttributes = SurveyMultiAppsModel.updQSetsWithDefaultSkipLogic(item.questionSetAttributes);
      const json = SurveyMultiAppsModel.toLegacy(
        SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave({ ...item, questionSetAttributes }),
      );
      dispatch({ type: SurveyMultiAppsActions.UPDATE_SURVEY_MULTI_APPS_PENDING, payload: { surveyId: item.id } });
      return SurveyMultiAppsService.update(orgId, json as unknown as MultiAppsSurvey, apiVersion)
        .then((survey) =>
          dispatch({
            type: SurveyMultiAppsActions.UPDATE_SURVEY_MULTI_APPS_SUCCESS,
            payload: SurveyMultiAppsModel.oldSurveyToNew(survey),
          }),
        )
        .catch((error) =>
          dispatch({ type: SurveyMultiAppsActions.UPDATE_SURVEY_MULTI_APPS_ERROR, payload: item, error }),
        );
    };
  },

  updateSurveyActive: (surveyId: string, interactionData: AppInteractionData[]) => {
    return (dispatch: Function, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(state);
      const item = getSurveyById(state, surveyId) as MultiAppsSurvey;

      if (!item) {
        return Promise.reject(new Error('Survey not found'));
      }

      const apiVersion = SurveyMultiAppsModel.getApiVersion(item);
      const isSurveyCurrenltyActive = item.interactionData.some((int) => int.active);
      const isActivateAll = interactionData.every((int) => int.active);
      const isDeactivateAll = interactionData.every((int) => !int.active);
      const newIntDataMap = new Map(interactionData.map((int) => [int.appId, int.active]));
      const interactionDataToUpdate: AppInteractionData[] = [];

      Array.from(new Set(item.interactionData)).forEach((int) => {
        if (!newIntDataMap.has(int.appId)) {
          return;
        }

        const newActiveValue = newIntDataMap.get(int.appId) as boolean;
        const isChanged = int.active !== newActiveValue;
        const isAlreadyInUpdateList = interactionDataToUpdate.some((existingInt) => existingInt.appId === int.appId);

        if (isChanged && !isAlreadyInUpdateList) {
          interactionDataToUpdate.push({ ...int, active: newActiveValue });
        }
      });

      let promiseAction: Promise<{}>;

      if (interactionDataToUpdate.length > 1 && (isActivateAll || isDeactivateAll)) {
        promiseAction = isActivateAll
          ? SurveyMultiAppsService.activateAll(orgId, surveyId)
          : SurveyMultiAppsService.deactivateAll(orgId, surveyId);
      } else {
        const promises = interactionDataToUpdate.map(({ appId, active, interactionId = '' }) =>
          active
            ? SurveyMultiAppsService.activate(appId, interactionId, apiVersion)
            : SurveyMultiAppsService.deactivate(appId, interactionId, apiVersion),
        );

        promiseAction = Promise.all(promises);
      }

      dispatch({
        type: SurveyMultiAppsActions.REQUEST_UPDATE_SURVEY_MULTI_APPS_ACTIVE,
        payload: { surveyId, interactionData: interactionDataToUpdate },
      });

      return promiseAction
        .then(() =>
          dispatch({
            type: SurveyMultiAppsActions.RECEIVE_UPDATE_SURVEY_MULTI_APPS_ACTIVE,
            payload: { surveyId, interactionData, active: isSurveyCurrenltyActive },
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.ERRORED_UPDATE_SURVEY_MULTI_APPS_ACTIVE,
            payload: { interactionData },
            error,
          }),
        );
    };
  },

  cloneSurveyToApp: (surveyId: string, appId: string, options: { cloneTargeting: boolean }) => {
    return (dispatch: Function) => {
      dispatch({ type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_APP_PENDING, payload: { appId, surveyId } });
      return SurveyMultiAppsService.cloneSurveyToApp(appId, surveyId, options)
        .then((data) =>
          dispatch({
            type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_APP_SUCCESS,
            payload: { appId, surveyId, survey: data.survey },
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_APP_ERROR,
            payload: { appId, surveyId },
            error,
          }),
        );
    };
  },

  cloneSurveyToApps: (
    surveyId: string,
    appIds: string[],
    options: { cloneTargeting: boolean; cloneOneToOne: boolean },
  ) => {
    return async (dispatch: Function, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      dispatch({
        type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_MULTI_APPS_PENDING,
        payload: { appIds, surveyId, cloneOneToOne: options.cloneOneToOne },
      });

      try {
        const clonedInteractions = await SurveyMultiAppsService.cloneSurveyToApps({ orgId, appIds, surveyId, options });
        dispatch({
          type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_MULTI_APPS_SUCCESS,
          payload: { appIds, surveyId, clonedInteractions, cloneOneToOne: options.cloneOneToOne },
        });
      } catch (error) {
        dispatch({
          type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_MULTI_APPS_ERROR,
          payload: { appIds, surveyId },
          error: error as Error,
        });
      }
    };
  },
};
