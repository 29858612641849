import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';

class TableInteractive extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    selectLimit: PropTypes.number,
    tableAggregate: PropTypes.shape({
      ends_with: PropTypes.string,
      has_more: PropTypes.bool,
      indicies: PropTypes.arrayOf(PropTypes.string),
      items: PropTypes.array,
      selected: PropTypes.array,
      total: PropTypes.number,
    }),
    togglePagesAction: PropTypes.func,
    toggleSelectedAction: PropTypes.func,
  };

  static defaultProps = {
    selectLimit: 10,
    tableAggregate: {
      has_more: false,
      indicies: [],
      selected: [],
    },
    togglePagesAction: () => {},
    toggleSelectedAction: () => {},
  };

  state = {
    selected: false,
  };

  togglePage = (forward = true) => {
    const { togglePagesAction } = this.props;
    const { ends_with, has_more, indicies = [] } = this.props.tableAggregate;

    if (forward && !has_more) {
      console.warn('inconsistent state detected when trying to access next table page when there is none');
      return;
    }

    forward
      ? togglePagesAction(ends_with, [...indicies, ends_with])
      : togglePagesAction(indicies[indicies.length - 2], indicies.slice(0, -1));
  };

  /**
   * Toggles the selectAll checkbox value between its 3 states:
   * - checked(Boolean)
   * - 'indeterminate', which is a checkbox that is partially checked
   *
   * @param {number} indeterminate - if set, it determines how to call toggleSelectedAction and sets the state of the checkbox to 'indeterminate'
   * @param {boolean} checked - defaults to false, states whether the action checked|unchecked the row
   */
  toggleSelected = (indeterminate, checked = false) => {
    const { selectLimit, toggleSelectedAction } = this.props;
    const { items = [], selected = [] } = this.props.tableAggregate;

    if (typeof indeterminate === 'number') {
      if (checked && selectLimit === selected.length) return;

      toggleSelectedAction(indeterminate, checked);
      this.setState({ selected: 'indeterminate' });
    } else if (this.state.selected) {
      toggleSelectedAction([]);
      this.setState({ selected: false });
    } else {
      const newSelected = [];
      const len = items.length < selectLimit ? items.length : selectLimit;
      for (let i = 0; i < len; i++) {
        newSelected.push(items[i].label);
      }
      toggleSelectedAction(newSelected);
      this.setState({ selected: true });
    }
  };

  render() {
    const { children } = this.props;
    const { indicies = [], has_more, items, selected } = this.props.tableAggregate;

    return (
      <table className="table table--interactive">
        { Children.map(children, child => React.cloneElement(child, {
          has_next: has_more,
          has_prev: !!indicies.length,
          interactive: true,
          items,
          selected,
          togglePage: this.togglePage,
          toggleSelected: this.toggleSelected,
        })) }
      </table>
    );
  }
}

export default TableInteractive;
