import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentRealAppId } from '../../selectors/current_app';
import { updateCurrentApp } from '../../actions/session';
import Empty from '../empty';
import debugLog from '../../utils/debug_log';

/**
 * Connects to the router and redux state to ensure that both agree on the
 * current app. If they are found to be different it blocks rendering its
 * content and instead renders the Empty component (loading page), it then
 * triggers an updateCurrentApp action. Once they reach agreement the content is
 * loaded.
 *
 * @class EnsureApp
 * @extends {React.Component}
 */
class EnsureApp extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.objectOf(PropTypes.string),
    }),
    currentAppId: PropTypes.string,
    children: PropTypes.node,
    // eslint-disable-next-line react/no-unused-prop-types
    updateCurrentApp: PropTypes.func,
  };

  static defaultProps = {
    match: {
      params: {},
    },
  };

  componentDidMount() {
    this.ensureApp(this.props);
  }

  componentDidUpdate(prevProps) {
    // Only ensure when the route appId changes, subsequent renders may still
    // be in a state where content can't be rendered but we can't keep triggering
    // the check or we end up in a loop.
    if (prevProps.match.params.appId !== this.props.match.params.appId) {
      this.ensureApp(this.props);
    }
  }

  ensureApp = (props) => {
    const { currentAppId, match: { params: { appId: routeAppId } } } = props;
    if (routeAppId === 'current') {
      const redirectTo = props.location.pathname.replace(/^\/apps\/current/, `/apps/${currentAppId}`);
      props.history.replace(redirectTo);
    } else if (routeAppId !== 'new' && routeAppId !== currentAppId) {
      // The validation that the route appId is not 'new' is a safeguard, in
      // most cases this component should only be used with routes that exclude
      // the magic "new" id.
      props.updateCurrentApp(routeAppId);
    } else {
      debugLog('current app matches route');
    }
  };

  render() {
    const { currentAppId, match: { params: { appId: routeAppId } } } = this.props;
    if (currentAppId === routeAppId) {
      return this.props.children;
    }
    return (
      <div className="ensure-app">
        <Empty />
      </div>
    );
  }
}

const getPropsFromState = (state) => ({ currentAppId: getCurrentRealAppId(state) });
const getPropsFromDispatch = { updateCurrentApp };

export default connect(getPropsFromState, getPropsFromDispatch)(EnsureApp);
