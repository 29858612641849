import React, { Component } from 'react';
import cookie from 'react-cookie';

// styles
import '../styles/masquerade_bar.scss';

export class MasqueradeBar extends Component {
  state = {
    masquerade: cookie.load('m'),
  };

  render() {
    let masqueradeBar = null;
    if (this.state.masquerade) {
      masqueradeBar = (
        <div className="masquerade fs-hide">
          Masquerading as <strong>{this.state.masquerade}</strong>
          <a href="/logout">Log Out</a>
        </div>
      );
    }

    return masqueradeBar;
  }
}

export default MasqueradeBar;
