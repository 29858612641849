import React from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from '../../components/header/index';
import Masquerade from '../../components/header/masquerade_bar';
import Footer from '../../components/footer';
import { AppDrawerContainer } from '../../components/app_drawer';

import '../../components/styles/base.scss';
import '../../components/styles/dashboard.scss';

const DashboardLayout = ({ children, mode }) => (
  <div className="dashboard">
    <AppDrawerContainer />
    <Masquerade />
    <HeaderContainer mode={mode} />
    {children}
    <Footer />
  </div>
);

/* istanbul ignore next */
DashboardLayout.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
};

/* istanbul ignore next */
DashboardLayout.defaultProps = {
  children: {},
  mode: '',
};

export default DashboardLayout;
