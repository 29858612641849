import React from 'react';
import { Link } from 'react-router-dom';
import className from 'classnames';
import { prop, sortBy } from 'ramda';
import { OrganizationApp } from 'src/reducers/organizations';
import { Platform } from 'src/types/core';

interface AppDrawerAppsProps {
  apps: OrganizationApp[];
  currentApp: string;
}

// NOTE: `navigationLocation` is used to ensure app changes retain the current section,
// it needs to be enabled and tested and would be used in <Link to={`/apps/${app.id}/${navigationLocation}`}>
export class AppDrawerApps extends React.PureComponent<AppDrawerAppsProps> {
  render() {
    const { apps, currentApp } = this.props;
    const sortedApps = sortBy(prop('title'), apps);
    return (
      <ul className="app-list" key={`${apps.length}-${currentApp}`}>
        {sortedApps.map((app) => (
          <li
            key={`app-${app.id}-${currentApp}`}
            className={className({
              active: app.id === currentApp,
              'app-info': true,
            })}
          >
            <div key={app.id}>
              <Link to={`/apps/${app.id}/dashboard`}>
                <img
                  src={app.iconUrl}
                  alt={`app ${app.title} icon`}
                  className={className({
                    'app-icon-ios': app.platform === Platform.iOS,
                    'app-icon-android': app.platform === Platform.Android,
                    'app-icon': true,
                  })}
                />
                <div className="app-name">{app.title}</div>
                <div className="app-platform">{app.platform}</div>
              </Link>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}
