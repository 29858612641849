import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, SimplePopover } from '../molecules';

export const ProfileMenu = React.forwardRef(({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }, ref) => {
  const { logout } = useAuth0();

  return (
    <SimplePopover
      className={'profile-menu-container'}
      isOpen={isOpen}
      targetEl={ref as any}
      placement={SimplePopover.PopoverPlacement.topStart}
      onClose={onClose}
      withArrow
    >
      <div className="profile-menu">
        <div className="profile-menu-settings">
          <ul>
            <li>
              <Link to="/profile" className="profile">
                Your Profile
              </Link>
            </li>
            <li>
              <Link to="/app-memberships" className="app-memberships">
                Your Projects
              </Link>
            </li>
            <li>
              <Link to="/email-preferences" className="email-preferences">
                Email Preferences
              </Link>
            </li>
            <li>
              <Link to="/account" className="account">
                Account
              </Link>
            </li>
          </ul>
        </div>
        <div className="profile-menu-logout">
          <Button
            onClick={() =>
              logout({
                logoutParams: { returnTo: process.env.LOGIN_SPA_URL as string },
              })
            }
          >
            Log Out
          </Button>
        </div>
      </div>
    </SimplePopover>
  );
});
