import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';
import { isEmpty } from 'ramda';

import { SnackBar } from 'src/components/molecules';
import { resetNewDownloadsCount, updateUIState } from 'src/actions/session';
import { eventMetrics, Events } from 'src/actions/event-metrics';
import { getAppsList, getCurrentAppMembershipRole } from 'src/selectors/current_app';
import { AppMemberRole } from 'src/reducers/apps.types';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import { State } from 'src/reducers';
import { getDownloads as getSurveyDownloads } from 'src/reducers/downloadsV2/downloadsV2';
import { getDownloadsList, getDownloadsV2Loading } from 'src/selectors/downloads-v2';
import { Download, DownloadService } from 'src/services/download-service';
import { FeatureFlag, featureSettings } from 'src/utils';
import { getAllSurveysMultiApps, getSurveysMultiAppsIsLoading } from 'src/selectors/surveys-multi-apps.selectors';
import { surveys } from 'src/actions/surveys-multi-apps/survey-multi-apps.actions';
import { MultiAppsSurvey } from 'src/reducers/surveys-multi-apps';
import { IApplication } from 'src/types/core';
import DownloadsTable from './downloads_table';

import { deleteDownload, fetchDownloads, retryDownload, updateDownload } from '../../actions/downloads';
import { getDownloads, getDownloadsErrors, getDownloadsLoading } from '../../selectors';
import { getCurrentUserId } from '../../selectors/user';
import { exportDownload } from '../../api/users';

import sdkEngage from '../../utils/sdk_engage';

import './styles/downloads.scss';

const noop = () => {};

interface DownloadCenterProps {
  currentUserId: string;
  downloads: any[];
  fetchError: boolean;
  loading: boolean;
  role: string;
  imtSurveyDownloads: Download[];
  imtSurveyDownloadsLoading: boolean;
  imtSurveys: MultiAppsSurvey[];
  imtSurveysLoading: boolean;
  allApps: IApplication[];
  deleteDownload: (userId: string, downloadId: string) => void;
  fetchDownloads: (userId: string) => void;
  resetNewDownloadsCount: () => void;
  retryDownload: (userId: string, downloadId: { id: string }) => void;
  updateDownload: (userId: string, download: any) => void;
  sendEvent: (event: Events, properties?: any) => void;
  updateUIState: (key: string, value: any) => void;
  getSurveyDownloads: (userId: string) => void;
  fetchImtSurveysByIds: (surveyIds: string[]) => void;
}

interface DownloadCenterState {
  isModalDeleteDownloadsOpen: boolean;
  isModalDeleteDownloadsSuccessOpen: boolean;
  isModalStartDownloadsOpen: boolean;
  selected: string[];
  downloadedDownloads: string[];
}

export class DownloadCenter extends React.Component<DownloadCenterProps, DownloadCenterState> {
  popupRef = null;

  static displayName = 'DownloadsPresentational';

  static defaultProps: Partial<DownloadCenterProps> = {
    currentUserId: '',
    downloads: [],
    fetchError: false,
    loading: true,
    imtSurveyDownloads: [],
    imtSurveyDownloadsLoading: false,
    imtSurveys: [],
    imtSurveysLoading: false,
    allApps: [],
    deleteDownload: noop,
    fetchDownloads: noop,
    resetNewDownloadsCount: noop,
    retryDownload: noop,
    updateDownload: noop,
    sendEvent: noop,
    updateUIState: noop,
    getSurveyDownloads: noop,
    fetchImtSurveysByIds: noop,
  };

  state: DownloadCenterState = {
    isModalDeleteDownloadsOpen: false,
    isModalDeleteDownloadsSuccessOpen: false,
    isModalStartDownloadsOpen: false,
    selected: [],
    downloadedDownloads: [],
  };

  isImtFeatureEnabled = featureSettings.get(FeatureFlag.INTERACTIONS_MULTITARGET);

  componentDidMount() {
    setPageTitleOnMount('Downloads', { oldTitle: 'Downloads | Alchemer' });
    sdkEngage('downloads_page');
    this.props.fetchDownloads(this.props.currentUserId);
    this.props.getSurveyDownloads(this.props.currentUserId);
    this.props.resetNewDownloadsCount();
  }

  componentDidUpdate(prevProps: DownloadCenterProps) {
    const isNewImtSurveyDownloadsFetched = prevProps.imtSurveyDownloads.length !== this.props.imtSurveyDownloads.length;
    if (isNewImtSurveyDownloadsFetched) {
      const imtIdsFromDownloads = this.props.imtSurveyDownloads.map(
        (d) => d.parameters?.unified_interaction_id,
      ) as string[];
      const imtIdsToFetch = Array.from(new Set(imtIdsFromDownloads));
      this.props.fetchImtSurveysByIds(imtIdsToFetch);
    }
  }

  componentWillUnmount() {
    this.props.resetNewDownloadsCount();
  }

  fetchDownloads = () => {
    this.props.fetchDownloads(this.props.currentUserId);
    this.props.getSurveyDownloads(this.props.currentUserId);
  };

  deleteDownloads = () => {
    const { selected } = this.state;
    if (!Array.isArray(selected) || isEmpty(selected)) {
      return;
    }

    selected.forEach((download_id) => {
      this.props.deleteDownload(this.props.currentUserId, download_id);
    });
    this.toggleDeleteDownloads();
    this.showDeleteDownloadsSuccess();
  };

  updateDownload = () => {
    const { selected } = this.state;
    if (Array.isArray(selected) && selected.length > 0) {
      selected.forEach((download) => {
        this.props.updateDownload(this.props.currentUserId, download);
      });
    }
  };

  initiateDownload = async (currentUserId: string, downloadId: string) => {
    try {
      const reqDownload = this.props.downloads.find((d) => d.id === downloadId);
      if (reqDownload && reqDownload.type === 'FanSignal') {
        this.props.updateUIState('has_not_downloaded_fs_export', false);
        this.props.sendEvent(Events.EXPORT_SIGNALS_DC_DOWNLOAD);
      }

      const json =
        this.isImtFeatureEnabled && this.props.imtSurveyDownloads.some((d) => d.id === downloadId)
          ? await DownloadService.getDownloadUrl(currentUserId, downloadId)
          : await exportDownload(currentUserId, downloadId);
      sdkEngage('download_center_exported_file');
      this.setState({ downloadedDownloads: [...this.state.downloadedDownloads, downloadId] });
      window.open(json.url);
    } catch (error) {
      console.error(`Error fetching Download URL for id: ${downloadId}`);
    }
  };

  startDownloads = async () => {
    const { selected } = this.state;
    const { currentUserId } = this.props;
    if (Array.isArray(selected) && selected.length > 0) {
      await Promise.all(selected.map((download_id) => this.initiateDownload(currentUserId, download_id)));
      this.showStartDownloads();
    }
  };

  retryDownload = (id: string) => {
    return (_event: any) => {
      this.props.retryDownload(this.props.currentUserId, { id });
    };
  };

  showDeleteDownloads = () => {
    this.setState({ isModalDeleteDownloadsOpen: true });

    let hasFsExportToRemove = false;
    const downloadIdEntityMap = new Map();
    this.props.downloads.forEach((d) => downloadIdEntityMap.set(d.id, d));

    this.state.selected.forEach((download_id) => {
      const selectedDownload = downloadIdEntityMap.get(download_id);
      if (selectedDownload && selectedDownload.type === 'FanSignal') {
        hasFsExportToRemove = true;
      }
    });

    if (hasFsExportToRemove) {
      this.props.updateUIState('has_not_downloaded_fs_export', false);
    }
  };

  toggleDeleteDownloads = () => {
    this.setState({ isModalDeleteDownloadsOpen: !this.state.isModalDeleteDownloadsOpen });
  };

  showDeleteDownloadsSuccess = () => {
    this.setState({ isModalDeleteDownloadsOpen: false });
    // Wait before showing success for visual effect
    setTimeout(() => {
      this.setState({ isModalDeleteDownloadsSuccessOpen: true });
    }, 500);
    setTimeout(() => {
      this.setState({ isModalDeleteDownloadsSuccessOpen: false });
    }, 4500);
  };

  showStartDownloads = () => {
    this.setState({ isModalStartDownloadsOpen: true });
    setTimeout(() => {
      this.setState({ isModalStartDownloadsOpen: false });
    }, 4000);
  };

  setSelectedItems = (selected: string[]) => {
    if (Array.isArray(selected)) {
      this.setState({ selected });
    }
  };

  showMessageCenter() {
    /* istanbul ignore next */
    if (typeof ApptentiveSDK !== 'undefined') {
      try {
        ApptentiveSDK.showMessageCenter();
      } catch (_) {
        console.error('No ApptentiveSDK to call.');
      }
    }
  }

  renderDeleteDownloadsModal = () => (
    <SnackBar
      showButtons
      declineText="No, cancel"
      acceptText="Yes, delete"
      onAccept={this.deleteDownloads}
      onDecline={this.toggleDeleteDownloads}
    >
      <div className="delete-files-modal">
        Warning: File deletion is permanent!
        <br />
        Are you sure you want to delete selected files?
      </div>
    </SnackBar>
  );

  renderDeleteDownloadsSuccessModal = () => (
    <SnackBar>
      <div className="delete-files-success-modal">File(s) deleted.</div>
    </SnackBar>
  );

  renderEmptyState() {
    return (
      <div className="empty-state">
        {this.props.fetchError && <p className="fetch-error">There was an error fetching downloads.</p>}
        <p>
          Need assistance? Check out our{' '}
          <a href="https://help.alchemer.com/help/download-center" rel="external">
            Download Center Help Documentation
          </a>{' '}
          for more information, or{' '}
          <button className="download-button-link" onClick={this.showMessageCenter} onKeyPress={this.showMessageCenter}>
            message our Customer Success team
          </button>
          .
        </p>
      </div>
    );
  }

  renderStarDownloadsModal = () => (
    <SnackBar>
      <div className="download-files-success-modal">
        Please disable your pop-up blocker for bulk downloads.
        <br />
        You will receive a browser notification when download(s) are complete.
      </div>
    </SnackBar>
  );

  renderLoading() {
    const { loading } = this.props;
    return loading ? (
      <div className="loading">
        <h2>Loading...</h2>
      </div>
    ) : null;
  }

  renderTable(type: string, klassName: string) {
    const { currentUserId, downloads, imtSurveyDownloads } = this.props;
    const isImtSurveys = type === 'UnifiedSurvey' && this.isImtFeatureEnabled;
    const filteredDocuments = (isImtSurveys ? imtSurveyDownloads : downloads)
      .filter((download) => download.type === type)
      .map((obj) => {
        const isEligible =
          !this.state.downloadedDownloads.includes(obj.id) && !obj.date_downloaded && obj.status === 'complete';
        const hasNewBadge = isEligible && moment.utc(obj.date_completed).add(72, 'hours').isAfter(moment().utc());
        return { ...obj, hasNewBadge };
      })
      .sort(
        (a: { date_requested: number }, b: { date_requested: number }) =>
          (new Date(b.date_requested) as never) - (new Date(a.date_requested) as never),
      );
    return filteredDocuments.length > 0 ? (
      <DownloadsTable
        className={klassName}
        currentUserId={currentUserId}
        deleteDownload={this.deleteDownloads}
        downloads={filteredDocuments}
        fetchDownloads={this.fetchDownloads}
        retryDownload={this.retryDownload}
        setSelectedItems={this.setSelectedItems}
        showDeleteDownloads={this.showDeleteDownloads}
        showDeleteDownloadsSuccess={this.showDeleteDownloadsSuccess}
        showStartDownloads={this.showStartDownloads}
        startDownloads={this.startDownloads}
        initiateDownload={this.initiateDownload}
        toggleDeleteDownload={this.toggleDeleteDownloads}
        updateDownload={this.updateDownload}
        imtSurveys={this.props.imtSurveys}
        imtSurveysLoading={this.props.imtSurveysLoading}
        allApps={this.props.allApps}
      />
    ) : (
      this.renderEmptyState()
    );
  }
  render() {
    const { isModalDeleteDownloadsOpen, isModalDeleteDownloadsSuccessOpen, isModalStartDownloadsOpen } = this.state;

    const isReporter = this.props.role === AppMemberRole.Reporter;

    return (
      <>
        <CSSTransition
          classNames="delete-modal-backdrop"
          unmountOnExit
          timeout={{ exit: 400 }}
          in={isModalDeleteDownloadsOpen}
        >
          {() => <div className="delete-modal-backdrop" />}
        </CSSTransition>
        <div className="downloads index">
          {isModalDeleteDownloadsOpen ? this.renderDeleteDownloadsModal() : null}
          {isModalDeleteDownloadsSuccessOpen ? this.renderDeleteDownloadsSuccessModal() : null}
          {isModalStartDownloadsOpen ? this.renderStarDownloadsModal() : null}
          <div className="sub-title">
            <h2>Downloads</h2>
          </div>
          {this.renderLoading()}
          <h2>Summary Exports</h2>
          {this.renderTable('AppHealth', 'app-health')}
          <br />
          <>
            <h2>Fan Signals Exports</h2>
            {this.renderTable('FanSignal', 'fan-signal')}
            <br />
          </>
          {!isReporter && (
            <>
              <h2>Conversations Exports</h2>
              {this.renderTable('Conversations', 'conversations')}
              <br />
            </>
          )}
          <h2>Survey Exports</h2>
          {this.renderTable(this.isImtFeatureEnabled ? 'UnifiedSurvey' : 'Survey', 'survey')}
          <br />
          <h2>Translations Exports</h2>
          {this.renderTable('Translations', 'translations')}
          <br />
          <h2>Rollup Exports</h2>
          {this.renderTable('AppRollup', 'app-rollup')}
          <br />
        </div>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: State) => ({
  currentUserId: getCurrentUserId(state),
  downloads: getDownloads(state),
  imtSurveyDownloads: getDownloadsList(state),
  imtSurveyDownloadsLoading: getDownloadsV2Loading(state),
  fetchError: getDownloadsErrors(state),
  loading: getDownloadsLoading(state),
  role: getCurrentAppMembershipRole(state),
  imtSurveys: getAllSurveysMultiApps(state),
  imtSurveysLoading: getSurveysMultiAppsIsLoading(state),
  allApps: getAppsList(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  deleteDownload: (userId: string, data: never) => dispatch(deleteDownload(userId, data)),
  fetchDownloads: (userId: string) => dispatch(fetchDownloads(userId)),
  retryDownload: (userId: string, data: never) => dispatch(retryDownload(userId, data)),
  updateDownload: (userId: string, data: never) => dispatch(updateDownload(userId, data)),
  resetNewDownloadsCount: () => dispatch(resetNewDownloadsCount()),
  sendEvent: (event: Events) => dispatch(eventMetrics.sendEvent(event)),
  updateUIState: (key: string, value: never) => dispatch(updateUIState(key, value)),
  getSurveyDownloads: (userId: string) => dispatch(getSurveyDownloads({ userId })),
  fetchImtSurveysByIds: (surveyIds: string[]) => dispatch(surveys.fetchSurveysByIds(surveyIds)),
});

/* istanbul ignore next */
export const DownloadCenterContainer = connect(mapStateToProps, mapDispatchToProps)(DownloadCenter);
DownloadCenterContainer.displayName = 'DownloadCenterContainer';
