import React from 'react';
import { SimplePopover, SimplePopoverProps } from 'src/components/molecules';
import { CustomerCareButton } from 'src/components/customer-care-button';

import './app-limit-popover.scss';

interface AppLimitPopoverProps extends Pick<SimplePopoverProps, 'targetEl' | 'onClose'> {
  isLimitReached?: boolean;
}

export const AppLimitPopover: React.FC<AppLimitPopoverProps> = ({ isLimitReached = false, targetEl, onClose }) => {
  return (
    <SimplePopover
      isOpen
      className="app-limit-popover"
      targetEl={targetEl}
      onClose={onClose}
      withArrow={false}
      placement={SimplePopover.PopoverPlacement.topStart}
    >
      <h3>Project Limit</h3>
      {isLimitReached ? (
        <>
          You have reached the project limit on your account. Please contact your Account Manager or reach out to our{' '}
          <CustomerCareButton /> for any questions.
        </>
      ) : (
        <>
          To increase this limit, please contact your Account Manager or reach out to our <CustomerCareButton /> for any
          questions.
        </>
      )}
    </SimplePopover>
  );
};

AppLimitPopover.displayName = 'AppLimitPopover';
