/* eslint-disable import/order */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { personAvatar } from '../../utils/person_helpers';

import { updateUser, changePassword } from '../../actions';

import { getUser, getCurrentUserName, getCurrentUserEmail } from '../../selectors/user';
import { getProfile, getChangePassword } from '../../selectors/dashboard';
import { getCurrentAppMembershipRole } from '../../selectors/current_app';
import sdkEngage from '../../utils/sdk_engage';

import ApiKeysContainer from 'src/components/api_key_management/api_keys';
import { ApiKeyType } from 'src/types/api_key';
import { AppMemberRole } from 'src/reducers/apps.types';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import { featureSettings } from 'src/utils';

import './styles/profile.scss';

export class Presentational extends Component {
  static displayName = 'ProfilePresentational';
  static propTypes = {
    changePassword: PropTypes.func,
    changePasswordLoading: PropTypes.bool,
    changePasswordSuccess: PropTypes.bool,
    companyTelephone: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    professionalRole: PropTypes.string,
    userId: PropTypes.string,
    timeZone: PropTypes.string,
    updateUser: PropTypes.func,
    updateUserErrors: PropTypes.arrayOf(PropTypes.string),
    updateUserLoading: PropTypes.bool,
    role: PropTypes.string,
  };

  static defaultProps = {
    changePassword: () => {},
    changePasswordLoading: false,
    changePasswordSuccess: false,
    companyTelephone: '',
    email: '',
    name: '',
    professionalRole: '',
    userId: '',
    timeZone: '',
    updateUser: () => {},
    updateUserErrors: [],
    updateUserLoading: false,
  };

  state = {
    errors: [],
  };

  componentDidMount() {
    setPageTitleOnMount('Profile', { oldTitle: 'Profile | Alchemer' });
    sdkEngage('profile_page');
  }

  changePassword = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.props.changePassword();
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ errors: [] });

    if (!this.form.elements.name.value) {
      this.setState({ errors: ['Name is required.', ...this.state.errors] });
    }
    if (!this.form.elements.email.value) {
      this.setState({ errors: ['Email is required.', ...this.state.errors] });
    }
    if (!this.form.elements.companyTelephone.value) {
      this.setState({ errors: ['Phone Number is required.', ...this.state.errors] });
    }
    if (!this.form.elements.professionalRole.value) {
      this.setState({ errors: ['Professional Role is required.', ...this.state.errors] });
    }
    if (!this.form.elements.timeZone.value) {
      this.setState({ errors: ['Time Zone is required.', ...this.state.errors] });
    }

    if (this.state.errors.length) {
      return;
    }

    this.props.updateUser({
      name: this.form.elements.name.value.trim(),
      email: this.form.elements.email.value.trim(),
      time_zone: this.form.elements.timeZone.value,
      company_telephone: this.form.elements.companyTelephone.value.trim(),
      professional_role: this.form.elements.professionalRole.value,
    });
  };

  render() {
    const hasIdentityFeature = featureSettings.get(featureSettings.Flag.IDENTITY);
    const { name, email, timeZone, companyTelephone, professionalRole, role } = this.props;
    const { updateUserLoading, updateUserErrors, changePasswordLoading, changePasswordSuccess } = this.props;
    const showApiKeys = role !== AppMemberRole.Reporter;

    return (
      <div className="profile index">
        <form
          acceptCharset="UTF-8"
          className="update-profile"
          name="update-profile"
          onSubmit={this.handleSubmit}
          ref={(form) => (this.form = form)}
        >
          <div className="row profile-static">
            <img alt="" className="profile fs-hide" src={personAvatar({ email })} />
            <p className="name fs-hide">{name}</p>
            <p className="email fs-hide">{email}</p>
          </div>
          <div className="row--narrow">
            <div className="row--2col">
              <div className="col">
                <label htmlFor="name">
                  Name
                  <input className="name fs-hide" name="name" required type="text" defaultValue={name} />
                </label>
              </div>
              <div className="col">
                <label htmlFor="email">
                  Email - also used for your{' '}
                  <a
                    href="https://gravatar.com/emails/"
                    className="link-text"
                    rel="external noopener noreferrer"
                    target="_blank"
                  >
                    Gravatar
                  </a>
                  <input
                    className="email fs-hide"
                    name="email"
                    required
                    type="email"
                    defaultValue={email}
                    disabled={hasIdentityFeature}
                  />
                </label>
              </div>
            </div>
            <div className="row--2col">
              <div className="col">
                <label htmlFor="companyTelephone">
                  Your Work Phone
                  <input
                    className="company-telephone fs-hide"
                    name="companyTelephone"
                    required
                    type="tel"
                    defaultValue={companyTelephone}
                  />
                </label>
              </div>
              <div className="col">
                <label htmlFor="timeZone">
                  Time Zone
                  <select className="timeZone" id="timeZone" name="timeZone" defaultValue={timeZone}>
                    <option disabled value="">
                      Select a time zone...
                    </option>
                    <option value="Hawaii">(GMT-10:00) Hawaii</option>
                    <option value="Alaska">(GMT-09:00) Alaska</option>
                    <option value="Pacific Time (US &amp; Canada)">(GMT-08:00) Pacific Time (US & Canada)</option>
                    <option value="Arizona">(GMT-07:00) Arizona</option>
                    <option value="Mountain Time (US &amp; Canada)">(GMT-07:00) Mountain Time (US & Canada)</option>
                    <option value="Central Time (US &amp; Canada)">(GMT-06:00) Central Time (US & Canada)</option>
                    <option value="Eastern Time (US &amp; Canada)">(GMT-05:00) Eastern Time (US & Canada)</option>
                    <option value="Indiana (East)">(GMT-05:00) Indiana (East)</option>
                    <option disabled value="">
                      -------------
                    </option>
                    <option value="American Samoa">(GMT-11:00) American Samoa</option>
                    <option value="International Date Line West">(GMT-11:00) International Date Line West</option>
                    <option value="Midway Island">(GMT-11:00) Midway Island</option>
                    <option value="PST8PDT">(GMT-08:00) PST8PDT</option>
                    <option value="Tijuana">(GMT-08:00) Tijuana</option>
                    <option value="Chihuahua">(GMT-07:00) Chihuahua</option>
                    <option value="Mazatlan">(GMT-07:00) Mazatlan</option>
                    <option value="Central America">(GMT-06:00) Central America</option>
                    <option value="Guadalajara">(GMT-06:00) Guadalajara</option>
                    <option value="Mexico City">(GMT-06:00) Mexico City</option>
                    <option value="Monterrey">(GMT-06:00) Monterrey</option>
                    <option value="Saskatchewan">(GMT-06:00) Saskatchewan</option>
                    <option value="Bogota">(GMT-05:00) Bogota</option>
                    <option value="Lima">(GMT-05:00) Lima</option>
                    <option value="Quito">(GMT-05:00) Quito</option>
                    <option value="Caracas">(GMT-04:30) Caracas</option>
                    <option value="Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada)</option>
                    <option value="Georgetown">(GMT-04:00) Georgetown</option>
                    <option value="La Paz">(GMT-04:00) La Paz</option>
                    <option value="Santiago">(GMT-04:00) Santiago</option>
                    <option value="Newfoundland">(GMT-03:30) Newfoundland</option>
                    <option value="Brasilia">(GMT-03:00) Brasilia</option>
                    <option value="Buenos Aires">(GMT-03:00) Buenos Aires</option>
                    <option value="Greenland">(GMT-03:00) Greenland</option>
                    <option value="Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
                    <option value="Azores">(GMT-01:00) Azores</option>
                    <option value="Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
                    <option value="Casablanca">(GMT+00:00) Casablanca</option>
                    <option value="Dublin">(GMT+00:00) Dublin</option>
                    <option value="Edinburgh">(GMT+00:00) Edinburgh</option>
                    <option value="Lisbon">(GMT+00:00) Lisbon</option>
                    <option value="London">(GMT+00:00) London</option>
                    <option value="Monrovia">(GMT+00:00) Monrovia</option>
                    <option value="UTC">(GMT+00:00) UTC</option>
                    <option value="Amsterdam">(GMT+01:00) Amsterdam</option>
                    <option value="Belgrade">(GMT+01:00) Belgrade</option>
                    <option value="Berlin">(GMT+01:00) Berlin</option>
                    <option value="Bern">(GMT+01:00) Bern</option>
                    <option value="Bratislava">(GMT+01:00) Bratislava</option>
                    <option value="Brussels">(GMT+01:00) Brussels</option>
                    <option value="Budapest">(GMT+01:00) Budapest</option>
                    <option value="Copenhagen">(GMT+01:00) Copenhagen</option>
                    <option value="Ljubljana">(GMT+01:00) Ljubljana</option>
                    <option value="Madrid">(GMT+01:00) Madrid</option>
                    <option value="Paris">(GMT+01:00) Paris</option>
                    <option value="Prague">(GMT+01:00) Prague</option>
                    <option value="Rome">(GMT+01:00) Rome</option>
                    <option value="Sarajevo">(GMT+01:00) Sarajevo</option>
                    <option value="Skopje">(GMT+01:00) Skopje</option>
                    <option value="Stockholm">(GMT+01:00) Stockholm</option>
                    <option value="Vienna">(GMT+01:00) Vienna</option>
                    <option value="Warsaw">(GMT+01:00) Warsaw</option>
                    <option value="West Central Africa">(GMT+01:00) West Central Africa</option>
                    <option value="Zagreb">(GMT+01:00) Zagreb</option>
                    <option value="Athens">(GMT+02:00) Athens</option>
                    <option value="Bucharest">(GMT+02:00) Bucharest</option>
                    <option value="Cairo">(GMT+02:00) Cairo</option>
                    <option value="Harare">(GMT+02:00) Harare</option>
                    <option value="Helsinki">(GMT+02:00) Helsinki</option>
                    <option value="Istanbul">(GMT+02:00) Istanbul</option>
                    <option value="Jerusalem">(GMT+02:00) Jerusalem</option>
                    <option value="Kyiv">(GMT+02:00) Kyiv</option>
                    <option value="Pretoria">(GMT+02:00) Pretoria</option>
                    <option value="Riga">(GMT+02:00) Riga</option>
                    <option value="Sofia">(GMT+02:00) Sofia</option>
                    <option value="Tallinn">(GMT+02:00) Tallinn</option>
                    <option value="Vilnius">(GMT+02:00) Vilnius</option>
                    <option value="Baghdad">(GMT+03:00) Baghdad</option>
                    <option value="Kuwait">(GMT+03:00) Kuwait</option>
                    <option value="Minsk">(GMT+03:00) Minsk</option>
                    <option value="Moscow">(GMT+03:00) Moscow</option>
                    <option value="Nairobi">(GMT+03:00) Nairobi</option>
                    <option value="Riyadh">(GMT+03:00) Riyadh</option>
                    <option value="St. Petersburg">(GMT+03:00) St. Petersburg</option>
                    <option value="Volgograd">(GMT+03:00) Volgograd</option>
                    <option value="Tehran">(GMT+03:30) Tehran</option>
                    <option value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
                    <option value="Baku">(GMT+04:00) Baku</option>
                    <option value="Muscat">(GMT+04:00) Muscat</option>
                    <option value="Tbilisi">(GMT+04:00) Tbilisi</option>
                    <option value="Yerevan">(GMT+04:00) Yerevan</option>
                    <option value="Kabul">(GMT+04:30) Kabul</option>
                    <option value="Ekaterinburg">(GMT+05:00) Ekaterinburg</option>
                    <option value="Islamabad">(GMT+05:00) Islamabad</option>
                    <option value="Karachi">(GMT+05:00) Karachi</option>
                    <option value="Tashkent">(GMT+05:00) Tashkent</option>
                    <option value="Chennai">(GMT+05:30) Chennai</option>
                    <option value="Kolkata">(GMT+05:30) Kolkata</option>
                    <option value="Mumbai">(GMT+05:30) Mumbai</option>
                    <option value="New Delhi">(GMT+05:30) New Delhi</option>
                    <option value="Sri Jayawardenepura">(GMT+05:30) Sri Jayawardenepura</option>
                    <option value="Kathmandu">(GMT+05:45) Kathmandu</option>
                    <option value="Almaty">(GMT+06:00) Almaty</option>
                    <option value="Astana">(GMT+06:00) Astana</option>
                    <option value="Dhaka">(GMT+06:00) Dhaka</option>
                    <option value="Novosibirsk">(GMT+06:00) Novosibirsk</option>
                    <option value="Urumqi">(GMT+06:00) Urumqi</option>
                    <option value="Rangoon">(GMT+06:30) Rangoon</option>
                    <option value="Bangkok">(GMT+07:00) Bangkok</option>
                    <option value="Hanoi">(GMT+07:00) Hanoi</option>
                    <option value="Jakarta">(GMT+07:00) Jakarta</option>
                    <option value="Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                    <option value="Beijing">(GMT+08:00) Beijing</option>
                    <option value="Chongqing">(GMT+08:00) Chongqing</option>
                    <option value="Hong Kong">(GMT+08:00) Hong Kong</option>
                    <option value="Irkutsk">(GMT+08:00) Irkutsk</option>
                    <option value="Kuala Lumpur">(GMT+08:00) Kuala Lumpur</option>
                    <option value="Perth">(GMT+08:00) Perth</option>
                    <option value="Singapore">(GMT+08:00) Singapore</option>
                    <option value="Taipei">(GMT+08:00) Taipei</option>
                    <option value="Ulaan Bataar">(GMT+08:00) Ulaan Bataar</option>
                    <option value="Osaka">(GMT+09:00) Osaka</option>
                    <option value="Sapporo">(GMT+09:00) Sapporo</option>
                    <option value="Seoul">(GMT+09:00) Seoul</option>
                    <option value="Tokyo">(GMT+09:00) Tokyo</option>
                    <option value="Yakutsk">(GMT+09:00) Yakutsk</option>
                    <option value="Adelaide">(GMT+09:30) Adelaide</option>
                    <option value="Darwin">(GMT+09:30) Darwin</option>
                    <option value="Brisbane">(GMT+10:00) Brisbane</option>
                    <option value="Canberra">(GMT+10:00) Canberra</option>
                    <option value="Guam">(GMT+10:00) Guam</option>
                    <option value="Hobart">(GMT+10:00) Hobart</option>
                    <option value="Magadan">(GMT+10:00) Magadan</option>
                    <option value="Melbourne">(GMT+10:00) Melbourne</option>
                    <option value="Port Moresby">(GMT+10:00) Port Moresby</option>
                    <option value="Solomon Is.">(GMT+10:00) Solomon Is.</option>
                    <option value="Sydney">(GMT+10:00) Sydney</option>
                    <option value="Vladivostok">(GMT+10:00) Vladivostok</option>
                    <option value="New Caledonia">(GMT+11:00) New Caledonia</option>
                    <option value="Auckland">(GMT+12:00) Auckland</option>
                    <option value="Fiji">(GMT+12:00) Fiji</option>
                    <option value="Kamchatka">(GMT+12:00) Kamchatka</option>
                    <option value="Marshall Is.">(GMT+12:00) Marshall Is.</option>
                    <option value="Wellington">(GMT+12:00) Wellington</option>
                    <option value="Nuku'alofa">(GMT+13:00) Nuku&apos;alofa</option>
                    <option value="Samoa">(GMT+13:00) Samoa</option>
                    <option value="Tokelau Is.">(GMT+13:00) Tokelau Is.</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <div className="row--narrow">
            <div className="row--2col">
              <div className="col">
                <label htmlFor="professionalRole">
                  Professional Role
                  <select
                    className="professionalRole"
                    name="professionalRole"
                    id="professionalRole"
                    defaultValue={professionalRole}
                  >
                    <optgroup label="Role">
                      <option disabled value="">
                        Select Your Role...
                      </option>
                      <option value="product_management">Product Management</option>
                      <option value="development">Development</option>
                      <option value="marketing">Marketing</option>
                      <option value="customer_success">Customer Success</option>
                      <option value="administrative">Administrative</option>
                    </optgroup>
                  </select>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {this.state.errors.length ? (
                  <div className="error-text">
                    {this.state.errors.map((error) => (
                      <p key={error}>{error}</p>
                    ))}
                  </div>
                ) : null}
                {updateUserErrors.length ? (
                  <div className="error-text">
                    {updateUserErrors.map((error) => (
                      <p key={error}>{error}</p>
                    ))}
                  </div>
                ) : null}
                <button className="primary" disabled={updateUserLoading}>
                  Save Settings
                </button>
              </div>
            </div>
          </div>
        </form>
        {showApiKeys && (
          <div className="api-key-container">
            <ApiKeysContainer keyType={ApiKeyType.user} ownerId={this.props.userId} />
          </div>
        )}
        <h2 className="separator">Password</h2>
        <button
          className="primary change-password"
          type="button"
          onClick={this.changePassword}
          disabled={changePasswordLoading}
        >
          Change Password
        </button>
        {changePasswordSuccess ? (
          <div className="success">
            An email has been sent to you with instructions on resetting your password with our Authentication provider.
          </div>
        ) : null}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  name: getCurrentUserName(state),
  email: getCurrentUserEmail(state),
  companyTelephone: getUser(state).companyTelephone,
  professionalRole: getUser(state).professionalRole,
  timeZone: getUser(state).timeZone,
  userId: getUser(state).id,
  updateUserLoading: getProfile(state).updateUserLoading,
  updateUserErrors: getProfile(state).updateUserErrors,
  changePasswordLoading: getChangePassword(state).changePasswordLoading,
  changePasswordSuccess: getChangePassword(state).changePasswordSuccess,
  role: getCurrentAppMembershipRole(state),
});

/* istanbul ignore next */
const mapDispatchToProps = {
  updateUser,
  changePassword,
};

/* istanbul ignore next */
const Profile = connect(mapStateToProps, mapDispatchToProps)(Presentational);
Profile.displayName = 'Profile';

export default Profile;
