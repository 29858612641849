import { createSelector } from 'reselect';

export const getNotifications = state => state.notifications || [];
export const getNotificationsFetched = state => state.notificationsFetched || false;

export const unreadNotification = notification => !(notification.read_at instanceof Date)
  || Number.isNaN(notification.read_at.valueOf());
export const unreadNotifications = notifications => notifications.filter(unreadNotification);

const isTranslationNotif = notification => notification && notification.title && (
  notification.title === 'Import Failed' || notification.title === 'Import Complete' || notification.title === 'Translations Import'
);

export const getTranslations = createSelector(
  getNotifications,
  (notifications) => notifications.filter(isTranslationNotif) || []
);
