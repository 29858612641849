import React, { useState, useCallback, SetStateAction } from 'react';

export function useStateObject<T extends {} = Record<string, any>>(initialState: T) {
  const [state, setData] = useState<T>(initialState);
  const setState = useCallback((value: Partial<T>) => setData({ ...state, ...value } as SetStateAction<T>), [
    Object.values(state),
  ]);
  const resetState = useCallback(() => setData(initialState as SetStateAction<T>), []);

  return {
    state,
    setState,
    resetState,
  };
}
