import { Rest, API } from 'src/api';
import { IBaseWebhook, IWebhook, ITestWebhook } from 'src/reducers/apps.types';
import { BaseService } from './base-service';

export class FsWebhookService extends BaseService {
  static fetchWebhooks(appId: string) {
    return Rest.httpGet(API.WEB_HOOK.FS_GET(appId), {
      errorTitle: '[API] Error Fetching Webhook Integration',
    }).promise;
  }

  static createWebhook(webhook: IBaseWebhook) {
    return Rest.httpPost(API.WEB_HOOK.FS_CREATE, {
      body: webhook,
      errorTitle: '[API] Error Creating Webhook Integration',
    }).promise;
  }

  static updateWebhook(webhook: IWebhook) {
    return Rest.httpPost(API.WEB_HOOK.FS_UPDATE(webhook.id), {
      body: webhook,
      errorTitle: '[API] Error Updating Webhook Integration',
    }).promise;
  }

  static deleteWebhook(id: string) {
    return Rest.httpDelete(API.WEB_HOOK.FS_DELETE(id), {
      errorTitle: '[API] Error Deleting Webhook Integration',
    }).promise;
  }

  static testWebhook(payload: ITestWebhook) {
    return Rest.httpPost(API.WEB_HOOK.FS_TEST, {
      body: payload,
      errorTitle: '[API] Error Testing Webhook Integration',
    }).promise;
  }
}
