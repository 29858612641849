import React from 'react';
import { Store } from 'redux';
import { History } from 'history';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import { CheckSessionContainer } from 'src/components/utils/check-session';
import ValidateAccount from '../../components/utils/validate_account';
import { NotificationToasts } from '../../components/notification-toasts';
import { RoutesContainer } from './routes-container';
import { featureSettings, FeatureFlag } from '../../utils';

interface DashboardRootProps {
  store: Store;
  history: History;
}

export const DashboardRoot: React.FC<DashboardRootProps> = ({ store, history }) => (
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.AUTH0_CUSTOM_DOMAIN as string}
      clientId={process.env.AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://${process.env.AUTH0_DOMAIN}/api/v2/`,
      }}
    >
      <CheckSessionContainer>
        <ValidateAccount>
          <Router history={history}>
            <Route component={RoutesContainer} />
          </Router>
        </ValidateAccount>
      </CheckSessionContainer>
      {featureSettings.get(FeatureFlag.TOAST_NOTIFICATIONS) && <NotificationToasts />}
    </Auth0Provider>
  </Provider>
);

export default DashboardRoot;
