import React from 'react';
import PropTypes from 'prop-types';

const FilterGroup = ({ children }) => (
  <section className="filter__section--interactive">{children}</section>
);

FilterGroup.propTypes = {
  children: PropTypes.node,
};

export default FilterGroup;
