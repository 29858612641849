import React from 'react';
import PropTypes from 'prop-types';

const ColAction = ({ children, colSpan }) => {
  const attributes = {};
  if (colSpan > 1) attributes.colSpan = colSpan;

  return (
    <td className="col col--action" {...attributes}>
      {children}
    </td>
  );
};

ColAction.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  colSpan: PropTypes.number,
};

export default ColAction;
