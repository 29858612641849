/* eslint-disable react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { notify } from 'src/components/notification-toasts';
import { ApiKey } from 'src/types/api_key';
import { Button, IconButton, InputText, Loading } from 'src/components/molecules';
import './api_key_display.scss';
import analytics from 'src/data/analytics';
import { ApiKeyRecord } from './api_key_record';

interface ApiKeyDisplayProps {
  isLoading: boolean;
  isError: boolean;
  apiKeys: ApiKey[];
  refreshKeysHandler: VoidFunction;
  createKeyHandler: (description: string) => Promise<ApiKey>;
  renameKeyHandler: (keyId: string, description: string) => Promise<void>;
  revokeKeyHandler: (keyId: string) => Promise<void>;
  deleteKeyHandler: (keyId: string) => Promise<void>;
}

interface ApiKeyDisplayState {
  showCreateKey: boolean;
  newKeyDescription: string;
  isCreatingKey: boolean;
  newKeyValidationMessage: string;
}

export class ApiKeyDisplay extends Component<ApiKeyDisplayProps, ApiKeyDisplayState> {
  static defaultProps: ApiKeyDisplayProps = {
    isLoading: false,
    isError: false,
    apiKeys: [],
    refreshKeysHandler: () => {},
    createKeyHandler: (_: string) => Promise.reject('not implemented'),
    renameKeyHandler: (_: string, __: string) => Promise.reject('not implemented'),
    revokeKeyHandler: (_: string) => Promise.reject('not implemented'),
    deleteKeyHandler: (_: string) => Promise.reject('not implemented'),
  };

  state: ApiKeyDisplayState = {
    showCreateKey: false,
    newKeyDescription: '',
    isCreatingKey: false,
    newKeyValidationMessage: '',
  };

  validateKeyName = (name: string): string | null => {
    if (!name) {
      return 'You must specify a non-whitespace name.';
    }
    if (this.props.apiKeys.findIndex((k) => k.description.toLowerCase() === name.toLowerCase()) >= 0) {
      return 'A key with this name already exists.';
    }
    return null;
  }

  createKey = async () => {
    analytics.trackEvent('Metrics API', 'Button Click', 'Create Key');
    this.setState({ newKeyValidationMessage: '' });
    if (this.state.isCreatingKey) {
      return;
    }
    const trimmed = this.state.newKeyDescription.trim();
    const validationMessage = this.validateKeyName(trimmed);
    if (validationMessage) {
      this.setState({ newKeyValidationMessage: validationMessage });
      return;
    }
    this.setState({ isCreatingKey: true });
    try {
      await this.props.createKeyHandler(trimmed);
      this.setState({ showCreateKey: false, newKeyDescription: '' });
      notify.success('API key created');
      this.props.refreshKeysHandler();
    } catch (ex: any) {
      notify.error('Error creating API Key, please try again.');
    } finally {
      this.setState({ isCreatingKey: false });
    }
  }

  revokeKey = async (keyId: string) => {
    try {
      await this.props.revokeKeyHandler(keyId);
      notify.success('API key revoked');
      this.props.refreshKeysHandler();
    } catch (ex: any) {
      notify.error('Error revoking API Key, please try again.');
    }
  }

  deleteKey = async (keyId: string) => {
    try {
      await this.props.deleteKeyHandler(keyId);
      notify.success('API key permanently deleted');
      this.props.refreshKeysHandler();
    } catch (ex: any) {
      notify.error('Error deleting API Key, please try again.');
    }
  }

  renameKey = async (keyId: string, description: string): Promise<boolean> => {
    const trimmed = description.trim();
    const validationMessage = this.validateKeyName(trimmed);
    if (validationMessage) {
      notify.error(validationMessage);
      return false;
    }
    try {
      await this.props.renameKeyHandler(keyId, trimmed);
      notify.success('API key renamed');
      this.props.refreshKeysHandler();
      return true;
    } catch (ex: any) {
      notify.error('Error renaming API Key, please try again.');
      return false;
    }
  }

  renderNewKeyRow = () => {
    return <div className="new-api-key">
      <InputText placeholder="Name" aria-label="Name" optional={false} onChange={(value: string) => this.setState({ newKeyDescription: value, newKeyValidationMessage: '' })} errorText={this.state.newKeyValidationMessage} invalid={!!this.state.newKeyValidationMessage} />

      <IconButton onClick={this.createKey} disabled={!this.state.newKeyDescription || this.state.isCreatingKey} iconName="circlecheck" tooltip="Confirm" />
      <IconButton onClick={() => this.setState({ showCreateKey: false, newKeyDescription: '' })} iconName="cancel" tooltip="Cancel" disabled={this.state.isCreatingKey} />
    </div>;
  }

  mapStateToContent = () => {
    if (this.props.isLoading) {
      return <Loading />;
    }
    if (this.props.isError) {
      return <div className="api-keys__error-container">
        <span>Error loading API Keys. Please try again later.</span>
        <Button className="small" label="Refresh" onClick={this.props.refreshKeysHandler} />
      </div>;
    }
    if (this.props.apiKeys.length === 0) {
      return <div className="api-keys__empty-container">
        <span>You have not created any API Keys. Click <Button normalize label="Create" onClick={() => this.setState({ showCreateKey: true })} /> to get started.</span>
      </div>;
    }
    return <div>
      <div id="api_key_grid" className="api-keys-flex">
        {...this.props.apiKeys.map((k) => <ApiKeyRecord
          key={`api-key-${k.full_key_name}`}
          description={k.description}
          apiKeyValue={k.full_key_name}
          createDate={new Date(k.created_at)}
          isActive={!k.expired_at}
          expireDate={k.expired_at ? new Date(k.expired_at) : undefined}
          renameKeyHandler={(description: string) => this.renameKey(k.full_key_name, description)}
          revokeKeyHandler={() => this.revokeKey(k.full_key_name)}
          deleteKeyHandler={() => this.deleteKey(k.full_key_name)}
        />)}
      </div>
    </div>;
  }

  render() {
    return (
      <div>
        <div className="api-key-header">
          <div className="api-key-header__text">
            <h2>API Keys</h2>
            <span>If you have questions see <a href="https://help.alchemer.com/help/alchemer-mobile-api" target="_blank" rel="noreferrer">Learn More</a> or view <a href="https://app.swaggerhub.com/apis-docs/Alchemer/AlchemerMobile/2.0.0" target="_blank" rel="noreferrer">Developer Docs</a> for more details!</span>
          </div>
          {!this.state.showCreateKey && <Button className="primary" label="Add New" onClick={() => this.setState({ showCreateKey: true })} />}
          {this.state.showCreateKey && this.renderNewKeyRow()}
        </div>
        {this.mapStateToContent()}
      </div>
    );
  }
}
