import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import Rollup from 'src/rollup/rollup';
import EnsureApp from 'src/components/utils/ensure_app';
import { ReloadComponent } from 'src/components/utils/reload_component';
import { SuspenseComponent } from 'src/components/utils/suspense-component';
import { interactionsRoutesPath } from 'src/interactions/newinteractions/interactions-paths';
import { DebugFeaturesToggleMenu } from 'src/dashboard/components/ui-feature-toggles-menu';

import DashboardLayout from './layout';
import AdminLayout from './admin_layout';
import TrialExpired from './trial_expired';
import { AppNewContainer } from './app-new';
import Account from './account';
import Subcontractor from './subcontractors';
import Profile from './profile';
import { DownloadCenterContainer } from './download-center';
import EmailPreferences from './email_preferences';
import AppMemberships from './app_memberships';
import { Pulse } from './pulse';

const AppHealthContainer = React.lazy(() => import(/* webpackChunkName: "appHealth" */ './app_health'));
const FanSignalsComponent = React.lazy(() => import(/* webpackChunkName: "fanSignals" */ './fan_signals_panel'));
const InsightsRoutes = React.lazy(() => import(/* webpackChunkName: "insights" */ './insights-routes'));
const SettingsRoutesContainer = React.lazy(
  () => import(/* webpackChunkName: "settings" */ '../../settings/settings_section'),
);

const ConversationRoutes = React.lazy(
  () => import(/* webpackChunkName: "conversations" */ '../../conversations/components/conversations-routes'),
);

const NewInteractions = React.lazy(
  () => import(/* webpackChunkName: "newinteractions" */ '../../interactions/newinteractions/new-interactions'),
);

export const RoutesContainer = () => (
  <div className="dashboard-routers-container" id="dashboard-root">
    <DebugFeaturesToggleMenu />
    <Switch>
      <Route
        path={['/account', '/account/*', '/profile', '/email-preferences', '/app-memberships']}
        component={(props: RouteComponentProps) => (
          <AdminLayout {...props}>
            <Switch>
              <Route path="/account" component={Account} exact />
              <Route path="/account/subcontractors" component={Subcontractor} exact />
              <Route path="/account/:orgId" component={Account} exact />
              <Route path="/profile" component={Profile} exact />
              <Route path="/email-preferences" component={EmailPreferences} exact />
              <Route path="/app-memberships" component={AppMemberships} exact />
            </Switch>
          </AdminLayout>
        )}
      />
      <Route
        path="/apps/new"
        exact
        component={(props: RouteComponentProps<{}>) => (
          <DashboardLayout {...props}>
            <AppNewContainer />
          </DashboardLayout>
        )}
      />
      <Route
        path="/downloads"
        exact
        component={(props: RouteComponentProps<{}>) => (
          <DashboardLayout {...props}>
            <DownloadCenterContainer />
          </DashboardLayout>
        )}
      />
      <Route
        path="/apps/:appId"
        component={(props: RouteComponentProps<{ appId: string }>) => (
          <EnsureApp {...props}>
            <DashboardLayout {...props}>
              <SuspenseComponent>
                <Switch>
                  <Redirect path="/apps/:appId/notes" to="/apps/:appId/prompts" exact />
                  <Redirect path="/apps/:appId/notes/new" to="/apps/:appId/prompts/new" exact />
                  <Redirect path="/apps/:appId/notes/:noteId" to="/apps/:appId/prompts/:noteId" exact />
                  <Route path="/apps/:appId/in-product-survey" component={Pulse} exact />
                  <Route path="/apps/:appId/in-product-workflow" component={Pulse} exact />
                  <Route path="/apps/:appId/in-product-pulse" component={Pulse} exact />
                  <Route path="/apps/:appId/dashboard" component={AppHealthContainer} exact />
                  <Route path="/apps/:appId/fan_signals" component={FanSignalsComponent} exact />
                  <Route path="/apps/:appId/insights" component={InsightsRoutes} />
                  <Route path="/apps/:appId/settings" component={SettingsRoutesContainer} />
                  <Route path="/apps/:appId/quick_responses" component={SettingsRoutesContainer} />
                  <Route path={interactionsRoutesPath} component={NewInteractions} />
                  <Route
                    path={['/apps/:appId/conversations', '/apps/:appId/group_messages']}
                    component={ConversationRoutes}
                  />
                  <Route path="/apps/:appId/*" component={ReloadComponent} />
                </Switch>
              </SuspenseComponent>
            </DashboardLayout>
          </EnsureApp>
        )}
      />
      <Route path="/trial-expired" component={TrialExpired} />
      <Route
        path="/apps"
        component={(props: RouteComponentProps<{}>) => (
          <DashboardLayout mode={'rollup'} {...props}>
            <Rollup />
          </DashboardLayout>
        )}
      />
      <Route path="/*" component={ReloadComponent} />
    </Switch>
  </div>
);
