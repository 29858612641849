/* eslint-disable import/no-import-module-exports */
import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';

import { featureSettings } from 'src/utils';
import { logger } from './loggerMiddleware';
import { storeActionsMiddleware, StoreActions } from './store-actions';
import { rootReducer } from '../reducers';
import * as AtriumAPI from '../api';
import { rootEpic } from '../actions/root_epic';

const composeMiddlewares = (middlewares) => {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: { options: true } })
    : compose;
  return composeEnhancer(applyMiddleware(...middlewares));
};

export default () => {
  const epicMiddleware = createEpicMiddleware({ dependencies: { api: AtriumAPI } });
  const store = createStore(
    rootReducer,
    composeMiddlewares([
      logger.middleware,
      thunkMiddleware.withExtraArgument({ api: AtriumAPI }),
      epicMiddleware,
      storeActionsMiddleware,
    ]),
  );

  epicMiddleware.run(rootEpic);
  logger.enrichRunLogs(store);

  featureSettings.subscribeActions(StoreActions.getInstance());

  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer);
    });
    module.hot.accept('../utils/jwt');
    module.hot.accept('../api');
  }

  return store;
};
