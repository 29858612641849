import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SavedLocation from 'src/utils/saved_location';
import { DispatchFunc, State } from 'src/reducers';
import { getAppLoading, getCurrentApp, getCurrentAppId, getCurrentOrgId } from 'src/selectors/current_app';
import {
  getCurrentOrg,
  getOrgLastUpdated,
  getOrgLoading,
  getOrganizations,
  getOrgsLoading,
} from 'src/selectors/organizations';
import { getCurrentUserId } from 'src/selectors/user';
import { Organization, OrganizationsState } from 'src/reducers/organizations';
import { IApplication } from 'src/types/core';
import { connect } from 'react-redux';
import { fetchApp } from 'src/actions/apps';
import { fetchOrganization, fetchOrganizations } from 'src/actions/organizations';
import { fetchCurrentUserFeatures } from 'src/actions';
import jwt from 'src/utils/jwt';
import { fetchCurrentUser } from 'src/actions/session';
import Empty from '../empty';
import { IdleDetector } from './idle_detector';

export interface IProps {
  currentApp: IApplication;
  currentOrg: Organization;
  currentAppId: string;
  currentOrgId: string;
  currentUserId: string;
  organizations: OrganizationsState;
  lastUpdatedOrg: number;
  loadingApp: boolean;
  loadingOrg: boolean;
  loadingOrgs: boolean;
  fetchApp: (appId: string) => void;
  fetchOrganization: (orgId: string) => void;
  fetchOrganizations: (userId: string) => void;
  fetchCurrentUserFeatures: (userId: string) => void;
  fetchCurrentUser: () => Promise<any>;
}

const GET_TOKEN_TIMEOUT_MS = 10000; // 10 seconds

const CheckSession: FC<IProps & { children: React.ReactNode }> = ({
  children,
  currentOrgId,
  currentApp,
  currentAppId,
  currentUserId,
  organizations,
  lastUpdatedOrg,
  loadingApp,
  loadingOrg,
  loadingOrgs,
  fetchApp,
  fetchOrganization,
  fetchOrganizations,
  fetchCurrentUserFeatures,
  fetchCurrentUser,
}) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout, loginWithRedirect } = useAuth0();

  const redirect = (location: string) => {
    window.history.pushState({}, document.title, location);
  };

  const redirectToNewLocation = (currentApp: string) => {
    // Check to see if we were already trying to go somewhere, or figure out where to go.
    let savedLocation = SavedLocation.check();
    if (savedLocation && currentApp) {
      const hasHardCodedAppId = currentApp && savedLocation.includes('/current/');
      savedLocation = hasHardCodedAppId
        ? savedLocation.replace('/current/', `/${currentApp}/`)
        : `/apps/${currentApp}/dashboard`;
      redirect(savedLocation);
    } else if (currentApp) {
      redirect(`/apps/${currentApp}/dashboard`);
    } else {
      // Redirect to the login page if the user has no currentApp
      console.error('User has no currentApp set:', currentApp);
      window.location.assign(process.env.LOGIN_SPA_URL as string);
    }
  };

  const initUser = (token: string) => {
    const existingJwt = jwt.getApiToken();
    if (!existingJwt || existingJwt !== token) {
      jwt.setApiToken(token);
    }

    fetchCurrentUser()
      .then(({ payload }) => {
        redirectToNewLocation(payload.currentApp);
      })
      .catch((error) => {
        console.error('Error Fetching Current User', error);
        logout({
          logoutParams: { returnTo: process.env.LOGIN_SPA_URL as string },
        });
      });
  };

  const checkIsAuthenticated = async () => {
    if (isAuthenticated && currentUserId) {
      return;
    }

    const getTokenPromise = getAccessTokenSilently({
      authorizationParams: {
        audience: `https://${process.env.AUTH0_DOMAIN}/api/v2/`,
      },
    });

    const timeOutPromise = new Promise((resolve) => setTimeout(resolve, GET_TOKEN_TIMEOUT_MS));

    try {
      const token = (await Promise.race([getTokenPromise, timeOutPromise])) as string | undefined;
      if (!token) {
        throw new Error('Timeout getting token');
      }

      if (!currentUserId) {
        await initUser(token);
      }

      loginWithRedirect({
        onRedirect: () => {
          if (!isAuthenticated && !token) {
            window.location.href = process.env.LOGIN_SPA_URL as string;
          }
        },
      } as never);
    } catch (error) {
      if (isAuthenticated) {
        logout({
          logoutParams: { returnTo: process.env.LOGIN_SPA_URL as string },
        });
        return;
      }

      window.location.href = process.env.LOGIN_SPA_URL as string;
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    checkIsAuthenticated();
  }, [isLoading]);

  useEffect(() => {
    fetch();
  }, [currentAppId, isAuthenticated, currentUserId]);

  const fetch = () => {
    if (!isAuthenticated || !currentUserId) {
      return;
    }

    // Check if all the initial loading of the user's orgs has been triggered yet.
    const needToFetchOrgs = !loadingOrgs && (!organizations || !Object.keys(organizations.orgs).length);
    if (needToFetchOrgs) {
      fetchOrganizations(currentUserId);
    }

    // Fetch the app if it hasn't been fetched yet.
    if (currentAppId && currentAppId !== 'new' && currentAppId !== 'current' && !loadingApp) {
      // Fetching app `currentAppId` that is actually an app.
      fetchApp(currentAppId);

      // fetch feature flags for new app
      fetchCurrentUserFeatures(currentUserId);
    }

    // If the current orgId doesn't exist or it was last modified more than an hour ago, re-fetch it.
    if (!needToFetchOrgs && currentOrgId && !loadingOrg) {
      const CACHE_TIMEOUT = 3600000; // 1 hour
      if (!organizations.orgs[currentOrgId] || (lastUpdatedOrg !== 0 && Date.now() - lastUpdatedOrg > CACHE_TIMEOUT)) {
        fetchOrganization(currentOrgId);
      }
    }
  };

  if (isAuthenticated) {
    // Don't render the children until both the currentApp and the organization corresponding
    // to the currentApp has loaded. We want to make sure that the page doesn't start to
    // render once the app has loaded, but the org has not yet finished loading.
    // Yes, this is messy, but all of the checks below are really required in order to ensure that
    // we don't prematurely render the children.
    const bothOrgsAndAppLoaded =
      !loadingApp && currentApp && currentApp.id === currentAppId && !loadingOrgs && !loadingOrg;
    if (bothOrgsAndAppLoaded) {
      return <IdleDetector>{children}</IdleDetector>;
    }
  }

  if (!isAuthenticated && !SavedLocation.check()) {
    SavedLocation.set(window.location.pathname);
  }

  return <Empty />;
};

type DispatchProps =
  | 'fetchApp'
  | 'fetchOrganization'
  | 'fetchOrganizations'
  | 'fetchCurrentUserFeatures'
  | 'fetchCurrentUser';

const mapStateToProps = (state: State) => ({
  currentApp: getCurrentApp(state),
  currentOrg: getCurrentOrg(state),
  currentAppId: getCurrentAppId(state),
  currentOrgId: getCurrentOrgId(state),
  currentUserId: getCurrentUserId(state),
  organizations: getOrganizations(state),
  lastUpdatedOrg: getOrgLastUpdated(state),
  loadingApp: getAppLoading(state),
  loadingOrg: getOrgLoading(state),
  loadingOrgs: getOrgsLoading(state),
});

const mapDispatchToProps = (dispatch: DispatchFunc) => ({
  fetchApp: (appId: string) => {
    dispatch(fetchApp(appId));
  },
  fetchOrganization: (orgId: string) => {
    dispatch(fetchOrganization(orgId));
  },
  fetchOrganizations: (userId: string) => {
    dispatch(fetchOrganizations(userId));
  },
  fetchCurrentUserFeatures: (userId: string) => {
    dispatch(fetchCurrentUserFeatures(userId));
  },
  fetchCurrentUser: () => dispatch(fetchCurrentUser()) as Promise<any>,
});

export const CheckSessionContainer = connect<Omit<IProps, DispatchProps>, Pick<IProps, DispatchProps>>(
  mapStateToProps,
  mapDispatchToProps,
)(CheckSession);
