import classNames from 'classnames';
import React from 'react';
import { IconButton } from 'src/components/molecules';
import { bemPrefix } from 'src/utils';
import { copyToClipboard } from './copy_to_clipboard';

const bem = bemPrefix('copy-button');

/** IconButton based component that will write some text content to the clipboard when clicked.
 * @param content String text to add to the clipboard, only omit if specifying custom copy function.
 * @param onError Void function that will execute if there is an error writing text to the clipboard.
 * @param onClick Void function that will completely override the existing copy behavior.
 */
const CopyButton: React.FC<{ content?: string, onError?: () => void, onClick?: () => void, className?: string, children?: React.ReactNode }> = ({ content, onError, onClick, className, children }) => {
  const clickHandler = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (!content) {
      console.error('No linked content to copy.');
      return;
    }
    copyToClipboard(content, onError);
  }

  if (!children) {
    // No specified child, so default to the IconButton
    return <IconButton className={classNames(bem('standard'), className)} onClick={clickHandler} iconName="copy" tooltip="Copy to clipboard" />
  }

  // Enforce use of only one child element. This wouldn't make much sense otherwise.
  const element = React.Children.only(children) as React.ReactElement<any>;
  return React.cloneElement(element, { onClick: clickHandler, className: classNames(element.props.className, bem('custom'), className) });
}

export default CopyButton;
