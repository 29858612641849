import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'src/components/molecules';
import { connect } from 'react-redux';
import { prop, sortBy } from 'ramda';

import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import historyProp from '../../proptypes/history-prop';

import { fetchOrganizations, fetchOrganizationApps } from '../../actions/organizations';

import { getCurrentBillingContactEmail, getCurrentBillingContactName, getOrgs } from '../../selectors/organizations';
import { getCurrentOrgId } from '../../selectors/current_app';
import { getCurrentUserId } from '../../selectors/user';

import Footer from '../../components/footer';

import './styles/trial-expired.scss';
import '../../components/styles/onboarding.scss';

export class TrialExpiredPresentational extends React.Component {
  state = { orgAppsFetched: [] };

  sortedOrgs = [];

  static displayName = 'TrialExpired';
  static propTypes = {
    billingContactEmail: PropTypes.string,
    billingContactName: PropTypes.string,
    currentOrgId: PropTypes.string,
    fetchOrganizationApps: PropTypes.func, // eslint-disable-line
    fetchOrganizations: PropTypes.func,
    organizations: PropTypes.instanceOf(Object),
    history: historyProp,
    userId: PropTypes.string, // eslint-disable-line
  };

  static defaultProps = {
    billingContactEmail: '',
    billingContactName: '',
    currentOrgId: '',
    fetchOrganizationApps: () => {},
    fetchOrganizations: () => {},
    organizations: {},
    history: {
      replace: () => {},
    },
    userId: '',
  };

  componentDidMount() {
    setPageTitleOnMount('Trial Expired', { oldTitle: 'Trial Expired | Alchemer' });

    this.buildSortedOrgs(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.buildSortedOrgs(nextProps);
  }

  buildSortedOrgs = (props) => {
    const { organizations, userId } = props;
    if ((!organizations || Object.keys(organizations).length === 0) && userId) {
      this.props.fetchOrganizations(userId);
    }
    this.sortedOrgs = sortBy(
      prop('name'),
      Object.keys(organizations).map((orgId) => ({
        id: orgId,
        name: organizations[orgId].name,
        apps: organizations[orgId].apps || [],
      })),
    );
    if (this.sortedOrgs.length) {
      this.sortedOrgs.forEach((org) => {
        if (!this.state.orgAppsFetched.includes(org.id) && userId) {
          props.fetchOrganizationApps(userId, org.id);
          this.setState({ orgAppsFetched: [...this.state.orgAppsFetched, org.id] });
        }
      });
    }
  };

  goToApp = (event) => {
    if (event) {
      event.preventDefault();

      if (event.target.value) {
        this.props.history.replace(`/apps/${event.target.value}/dashboard`);
      }
    }
  };

  render() {
    const { billingContactEmail, billingContactName, currentOrgId, organizations } = this.props;
    const totalApps = this.sortedOrgs.reduce((total, org) => total + org.apps.length, 0);
    return (
      <div className="onboarding-container">
        <div className="container">
          <div className="apptentive-logo">
            <Icon name="alchemerLogo" className="alchemer" />
          </div>
          <div className="trial-ended">
            <div className="trial-ended-header">
              <h1>The trial has ended.</h1>
            </div>
            <div className="trial-ended-body-content">
              <p>Trial for your app has expired.</p>
              <p>
                To reactivate please email <a href="mailto:sales@apptentive.com">sales@apptentive.com</a> or call{' '}
                <a href="tel:7742778368">(774) APP-TENT.</a>
              </p>
              <p className="billing-contact-container">
                Your internal billing contact is{' '}
                <a className="billing-contact fs-hide" href={`mailto:${billingContactEmail}`}>
                  {billingContactName}
                </a>
                . If you have a different account you may{' '}
                <a className="log-out" href="/logout">
                  log out
                </a>{' '}
                and log in to that account.
              </p>
              {Object.keys(organizations).length > 1 ? (
                <div className="multiple-apps">
                  <p>If you have other apps, you can switch to those below:</p>
                  <select id="app-list" onChange={this.goToApp} key={`apps-${totalApps}`}>
                    <option disabled defaultValue value="">
                      Select an App
                    </option>
                    {this.sortedOrgs.map((org) => (
                      <optgroup
                        key={`org-${org.id}-${org.apps.length}`}
                        label={org.name}
                        disabled={org.id === currentOrgId}
                      >
                        {org.apps.map((app) => (
                          <option key={`app-${app.id}`} value={app.id}>
                            {app.title} ({app.platform})
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  billingContactEmail: getCurrentBillingContactEmail(state),
  billingContactName: getCurrentBillingContactName(state),
  currentOrgId: getCurrentOrgId(state),
  organizations: getOrgs(state),
  userId: getCurrentUserId(state),
});

const mapDispatcherToProps = {
  fetchOrganizations,
  fetchOrganizationApps,
};

export default connect(mapStateToProps, mapDispatcherToProps)(TrialExpiredPresentational);
