import { API, Rest } from 'src/api';
import { NoteHeaderFile, NoteItem } from 'src/reducers/notes';
import { PromptMultiAppsItem } from 'src/reducers/prompts-multi-apps';

const PROMPTS_S3_BUCKET_NAME = process.env.PROMPTS_S3_BUCKET_NAME || '';

const getPromptsDownloadUrl = (url: string): string => {
  const bucketIndex = url.indexOf(PROMPTS_S3_BUCKET_NAME);
  const parsedUrl = new URL(`https://${url.substring(bucketIndex)}`);
  parsedUrl.searchParams.delete('x-amz-security-token');
  return parsedUrl.toString();
};

const getUpdatedPromptImage = (image: NoteHeaderFile, url: string): NoteHeaderFile => ({
  ...image,
  file: getPromptsDownloadUrl(url),
});

const isPromptMultiAppsItem = (note: NoteItem | PromptMultiAppsItem): note is PromptMultiAppsItem =>
  (note as PromptMultiAppsItem).organizationId !== undefined;

export const getNoteWithUploadedImage = async (
  id: string,
  note: NoteItem | PromptMultiAppsItem,
): Promise<NoteItem | PromptMultiAppsItem> => {
  if (!note.image) {
    return note;
  }

  const now = new Date().valueOf();
  const fileName = `${id}_${now}`;

  try {
    let urlDownload: string;
    let urlUpload: string;

    if (isPromptMultiAppsItem(note)) {
      const { url_download, url_upload } = await Rest.httpGet(
        API.S3.GET_PRESIGNED_URL_BY_ORG(id, PROMPTS_S3_BUCKET_NAME, fileName, note.image.file.type),
        {
          errorTitle: '[API] Error GET presigned url for multi-apps',
        },
      ).promise;

      urlDownload = url_download;
      urlUpload = url_upload;
    } else {
      const { url_download, url_upload } = await Rest.httpGet(
        API.S3.GET_PRESIGNED_URL(id, PROMPTS_S3_BUCKET_NAME, fileName, note.image.file.type),
        {
          errorTitle: '[API] Error GET presigned url',
        },
      ).promise;

      urlDownload = url_download;
      urlUpload = url_upload;
    }

    await fetch(urlUpload, {
      method: 'PUT',
      body: note.image.file,
      headers: {
        'Content-Type': note.image?.file.type,
      },
    });

    return {
      ...note,
      image: getUpdatedPromptImage(note.image as never, urlDownload),
    } as NoteItem | PromptMultiAppsItem;
  } catch (e) {
    return note;
  }
};
