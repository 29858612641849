/* eslint-disable */
import { Rest, API } from 'src/api';
import {
  SurveyTextAnswers,
  MultiAppsSurvey,
  FetchSurveysMultiAppsResp,
  InteractionDataResponseStat,
} from 'src/reducers/surveys-multi-apps';
import { MultiAppCloningResult } from 'src/types/core';

import { BaseService } from './base-service';

export const SURVEYS_PAGINATION_LIMIT = 20;

export class SurveyMultiAppsService extends BaseService {
  static create(orgId: string, survey: MultiAppsSurvey, apiVersion: string): Promise<MultiAppsSurvey> {
    return Rest.httpPost(API.SURVEY_MULTI_APPS.CREATE(orgId, apiVersion), {
      body: survey,
      errorTitle: '[API] Error POST multi-app survey item',
    }).promise;
  }

  static delete(orgId: string, surveyId: string, apiVersion: string): Promise<void> {
    return Rest.httpDelete(API.SURVEY_MULTI_APPS.DELETE(orgId, surveyId, apiVersion), {
      errorTitle: '[API] Error DELETE multi-app survey item',
    }).promise;
  }

  static fetchSurvey(orgId: string, surveyId: string): Promise<MultiAppsSurvey> {
    return Rest.httpGet(API.SURVEY_MULTI_APPS.GET(orgId, surveyId), {
      errorTitle: '[API] Error GET multi-app survey item',
    }).promise;
  }

  static update(orgId: string, survey: MultiAppsSurvey, apiVersion: string): Promise<MultiAppsSurvey> {
    return Rest.httpPut(API.SURVEY_MULTI_APPS.UPDATE(orgId, survey.id, apiVersion), {
      body: survey,
      errorTitle: '[API] Error PUT update multi-app survey item',
    }).promise;
  }

  static activate(appId: string, surveyId: string, apiVersion: string): Promise<MultiAppsSurvey> {
    return Rest.httpPut(API.SURVEY_MULTI_APPS.ACTIVATE(appId, surveyId, apiVersion), {
      body: {},
      errorTitle: '[API] Error PUT activate survey item',
    }).promise;
  }

  static deactivate(appId: string, surveyId: string, apiVersion: string): Promise<MultiAppsSurvey> {
    return Rest.httpPut(API.SURVEY_MULTI_APPS.DEACTIVATE(appId, surveyId, apiVersion), {
      body: {},
      errorTitle: '[API] Error PUT deactivate survey item',
    }).promise;
  }

  static activateAll(imtSurveyId: string, apiVersion: string): Promise<MultiAppsSurvey> {
    return Rest.httpPost(API.SURVEY_MULTI_APPS.IMT_ACTIVATE(imtSurveyId, apiVersion), {
      body: {},
      errorTitle: '[API] Error POST activate IMT survey item',
    }).promise;
  }

  static deactivateAll(imtSurveyId: string, apiVersion: string): Promise<MultiAppsSurvey> {
    return Rest.httpPost(API.SURVEY_MULTI_APPS.IMT_DEACTIVATE(imtSurveyId, apiVersion), {
      body: {},
      errorTitle: '[API] Error POST activate IMT survey item',
    }).promise;
  }

  static getSurveyStats(
    orgId: string,
    surveyId: string,
    apps: string[],
    start: string,
    end: string,
  ): Promise<InteractionDataResponseStat[]> {
    return Rest.httpPost(API.SURVEY_MULTI_APPS.STATS(orgId, surveyId, start, end), {
      body: { apps },
      errorTitle: '[API] Error GET multi-app survey stats',
    }).promise;
  }

  static getTextAnswers(
    orgId: string,
    surveyId: string,
    questionIds: string[],
    apps: string[],
    start: string,
    end: string,
    pagination?: { limit: number; page: number },
  ): Promise<Record<string, SurveyTextAnswers>> {
    const requests = questionIds.map(
      (id) =>
        Rest.httpPost(API.SURVEY_MULTI_APPS.TEXT_ANSWERS(orgId, surveyId, id, start, end), {
          body: { apps },
          query: pagination,
          errorTitle: '[API] Error GET multi-app survey text answers',
        }).promise,
    );

    return Promise.all(requests).then((results) =>
      results.reduce((acc, res, i) => ({ ...acc, [questionIds[i]]: res }), {}),
    );
  }

  static fetchSurveys(orgId: string, page: number = 1, isActive = true): Promise<FetchSurveysMultiAppsResp> {
    return Rest.httpGet(API.SURVEY_MULTI_APPS.ALL(orgId, page, isActive), {
      errorTitle: '[API] Error GET multi-app surveys list',
    }).promise;
  }

  static cloneSurveyToApp(
    orgId: string,
    surveyId: string,
    options: { cloneTargeting: boolean },
  ): Promise<{ app_title: string; survey: { active: boolean; id: string } }> {
    return Rest.httpPost(API.SURVEY_MULTI_APPS.CLONE(orgId, surveyId), {
      body: { options },
      errorTitle: '[API] Error POST clone multi-app survey to orgId',
    }).promise;
  }

  static cloneSurveyToApps({
    orgId,
    appIds,
    surveyId,
    options: { cloneTargeting, cloneOneToOne },
  }: {
    orgId: string;
    appIds: string[];
    surveyId: string;
    options: { cloneTargeting: boolean; cloneOneToOne: boolean };
  }): Promise<MultiAppCloningResult[]> {
    return Rest.httpPost(API.SURVEY_MULTI_APPS.CLONE(orgId, surveyId), {
      body: { clone_targeting: cloneTargeting, clone_one_to_one: cloneOneToOne, app_ids: appIds },
      errorTitle: '[API] Error POST clone survey to appIds',
    }).promise;
  }
}
