import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useIdleTimer } from 'react-idle-timer';
import jwt from 'src/utils/jwt';
import IdleModal from './idle_modal';
// AUTH0_INACTIVITY_TIMEOUT is legacy value, will be deprecated.
const SESSION_TIMEOUT = parseInt(
  process.env.SESSION_INACTIVITY_TIMEOUT_MS || (process.env.AUTH0_INACTIVITY_TIMEOUT as string),
  10,
);
// AUTH0_REAUTH_INTERVAL is legacy value, will be deprecated.
const PROMPT_BEFORE_TIMEOUT = parseInt(
  process.env.SESSION_PROMPT_BEFORE_TIMEOUT_MS || (process.env.AUTH0_REAUTH_INTERVAL as string),
  10,
);
// AUTH0_DEBOUNCE is legacy value, will be deprecated.
const THROTTLE = parseInt(process.env.SESSION_REFRESH_THROTTLE_MS || (process.env.AUTH0_DEBOUNCE as string), 10);

export const IdleDetector: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [idlePromptOpen, setIdlePromptOpen] = useState(false);

  const refresh = () => {
    getAccessTokenSilently({ cacheMode: 'off' }).then((token) => {
      jwt.setApiToken(token);
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: { returnTo: process.env.LOGIN_SPA_URL as string },
    });
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onPrompt: () => setIdlePromptOpen(true),
    onActive: () => {
      setIdlePromptOpen(false);
      refresh();
    },
    onAction: refresh,
    onIdle() {
      setIdlePromptOpen(false);
      handleLogout();
    },
    crossTab: true,
    syncTimers: 30000,
    // When do we declare a user as "idle"
    timeout: SESSION_TIMEOUT,
    // How frequently do we want to publish that the user is "active" (this will trigger a token refresh w/ auth0)
    // Throttle will immediately fire, then defer future events for X milliseconds
    throttle: THROTTLE,
    // How long before the user is idle do we want to present the idle prompt
    promptBeforeIdle: PROMPT_BEFORE_TIMEOUT,
  });

  return (
    <>
      <IdleModal
        getRemainingTime={getRemainingTime}
        isOpen={idlePromptOpen}
        stillHereHandler={activate}
        logoutNowHandler={handleLogout}
      />
      {children}
    </>
  );
};
