import { notify } from 'src/components/notification-toasts';

/**
 * Writes some text to the clipboard using modern browser capabilities.
 * @param text Text content to copy to the clipboard.
 * @param onError Optional void function to execute if the clipboard action fails. Common case for that is being on http connection instead of https.
 */
export const copyToClipboard = async (text: string, onError?: () => void) => {
  try {
    await navigator.clipboard.writeText(text);
    notify.success('Copied to clipboard.');
  } catch (err) {
    console.error('Unable to copy text to clipboard.', err);
    if (onError) {
      onError();
    }
  }
}
