import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { prop, uniqBy } from 'ramda';
import '../styles/molecules/attachment_drawer.scss';

class AttachmentDrawer extends Component {
  static propTypes = {
    children: PropTypes.node,
    description: PropTypes.string,
    fileLimit: PropTypes.number,
    input: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.instanceOf(Array),
    }),
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string,
    }),
    setFiles: PropTypes.func,
    setFileCount: PropTypes.func,
    title: PropTypes.string,
    customTitle: PropTypes.node,
    showPlusSign: PropTypes.bool,
    // Functions passed to Dropzone
    onClick: PropTypes.func,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragLeave: PropTypes.func,
    // Params passed to Dropzone
    className: PropTypes.string, // Optional className
    activeClassName: PropTypes.string, // className for accepted state
    rejectClassName: PropTypes.string, // className for rejected state
    disablePreview: PropTypes.bool, // Enable/disable preview generation
    disableClick: PropTypes.bool, // Disallow clicking on the dropzone container to open file dialog
    onFileDialogCancel: PropTypes.func, // Provide a callback on clicking the cancel button of the file dialog
    inputProps: PropTypes.instanceOf(Object), // Pass additional attributes to the <input type="file"/> tag
    name: PropTypes.string, // name attribute for the input tag
    multiple: PropTypes.bool, // Allow dropping multiple files
    accept: PropTypes.string, // Allow specific types of files. See https://github.com/okonet/attr-accept for more information
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    previewIsImages: PropTypes.bool, // Is the preview an image (true) or other file type (false)
  };

  static defaultProps = {
    accept: 'image/*',
    activeClassName: 'accepted',
    className: 'upload-file',
    fileLimit: 4,
    disableClick: false,
    disablePreview: false,
    input: {
      id: '',
      onChange: () => { },
    },
    maxSize: 1048576,
    minSize: 1,
    multiple: true,
    rejectClassName: 'rejected',
    title: 'Drop files here or click to select files.',
    showPlusSign: true,
    setFiles: () => { },
    setFileCount: () => { },
    previewIsImages: true,
  };

  static AttachmentFileItem = AttachmentFileItem;

  static readFileImage = (files = [], replaceBase64 = true) => {
    return new Promise((resolve, reject) => {
      if (files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          resolve({
            data: replaceBase64 ? reader.result.replace(/^data:image\/\w+;base64,/, '') : reader.result,
            name: files[0].name || 'icon',
            type: files[0].type,
          });
        });
        reader.addEventListener('error', (err) => {
          console.error('Error reading file: ', err);
          reject(err);
        });
        reader.readAsDataURL(files[0]);
      } else {
        resolve({ data: undefined });
      }
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      acceptedFiles: [],
      fileTooLarge: false,
    };
  }

  onDropRejected = rejectedFiles => {
    const acceptedFiles = this.state.acceptedFiles;
    const maxSize = this.props.maxSize;
    rejectedFiles.some(file => {
      if (file.size > maxSize && acceptedFiles.length === 0) {
        this.setState({ fileTooLarge: true });
        return true;
      }
      if (acceptedFiles.length === 0) {
        this.setState({ fileNotAccepted: true });
        return true;
      }
      return false;
    });
  };

  removeFile = index => (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    const { input, setFiles, setFileCount } = this.props;
    const files = [...this.state.acceptedFiles];
    files.splice(index, 1);
    this.setState({ fileTooLarge: false, acceptedFiles: files, fileNotAccepted: false });
    setFiles(files);
    setFileCount(files.length);
    input.onChange(files);
  };

  customDrop = (acceptedFiles, _rejectedFiles, _e) => {
    this.setState({ fileNotAccepted: false });
    if (!acceptedFiles.length) {
      return;
    }
    const { fileLimit, input, maxSize, setFiles, setFileCount } = this.props;
    const oldTotal = this.state.acceptedFiles.reduce((total, file) => file.size + total, 0);
    const newTotal = acceptedFiles.reduce((total, file) => file.size + total, 0);
    const totalSize = newTotal + oldTotal;
    if (totalSize >= maxSize) {
      this.setState({ fileTooLarge: true });
      return;
    }
    this.setState({ fileTooLarge: false });

    const files = uniqBy(prop('name'), this.state.acceptedFiles.concat(acceptedFiles)).slice(0, fileLimit);
    this.setState({ acceptedFiles: files });
    setFiles(files);
    setFileCount(files.length);
    input.onChange(files);
  };

  render() {
    // NOTE: To prevent props from getting passed into Dropzone, pull them out here: input, meta, setFiles, setFileCount.
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { input, meta, title, description, children, fileLimit, setFiles, setFileCount, maxSize, multiple, showPlusSign, customTitle, previewIsImages, ...rest } = this.props;
    const { fileTooLarge, fileNotAccepted } = this.state;
    const acceptedFiles = Array.isArray(input.value) ? input.value : this.state.acceptedFiles;

    const previewFigures = acceptedFiles.map((file, i) => <AttachmentFileItem
      previewIsImages={previewIsImages}
      file={file}
      onRemoveFile={this.removeFile(i)}
    />);

    const plusIcon = (
      <li className="drop">
        <div className="plus-icon">+</div>
      </li>
    );

    const uploadLimit = <div className="upload-limit">{acceptedFiles.length} / {fileLimit}</div>;

    return (
      <div>
        {rest.label ? <label htmlFor={input.name || 'file-upload'}>{rest.label}</label> : null}
        <Dropzone
          multiple={multiple}
          name={input.name || 'file-upload'}
          onDrop={this.customDrop}
          preventDropOnDocument
          onDropRejected={this.onDropRejected}
          {...rest}
        >
          <div className="how-to">
            <div className="uploader-view">
              {multiple ? uploadLimit : null}
              {title && !customTitle ? <h3>{title}</h3> : null}
              {customTitle}
              {description ? <p className="description">{description}</p> : null}
              {fileNotAccepted ? <p className="not-allowed">This selection is not accepted.</p> : null}
              {fileTooLarge ? <p className="too-large">Payload is too large, the total size of all files should be under {resolveLargeBytesText(maxSize)}.</p> : null}
              <ul className="files">
                {previewFigures}
                {acceptedFiles.length < fileLimit && showPlusSign ? plusIcon : null}
              </ul>
            </div>
            {children}
          </div>
        </Dropzone>
      </div>
    );
  }
}

export default AttachmentDrawer;

function resolveLargeBytesText(maxSize) {
  const Mb = 1024 * 1024;
  const mbSize = maxSize / Mb;
  return (mbSize >= 1) ? `${mbSize} MB` : `${maxSize} bytes`;
}

function AttachmentFileItem({ file, previewIsImages, onRemoveFile }) {
  return (
    <li className="thumbnail" key={`${file.name}-${Date.now()}`}>
      {previewIsImages ? (
        <>
          <figure>
            <img src={file.preview} alt="" title={file.name} />
          </figure>
          <div>{file.name}</div>
        </>
      ) : file.name}
      <button className="close-badge" onClick={onRemoveFile}>✕</button>
    </li>
  );
}

AttachmentFileItem.propTypes = {
  previewIsImages: PropTypes.bool,
  file: PropTypes.shape({
    name: PropTypes.string,
    preview: PropTypes.string,
  }),
  onRemoveFile: PropTypes.func,
};
