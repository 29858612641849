import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path, keys, prop, sortBy } from 'ramda';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

import { daysAgo } from 'src/utils/time';
import { localDateString } from 'src/utils/date_format';
import { AppMemberRole } from 'src/reducers/apps.types';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import { createDownload } from '../actions/downloads';
import { fetchOrganizations, fetchOrganizationApps } from '../actions/organizations';

import { getAppsList, getCurrentAppId, getCurrentAppMembershipRole } from '../selectors/current_app';
import { getCurrentUserId, getFeatures } from '../selectors/user';
import { getOrgs } from '../selectors/organizations';
import { getRollup, getRollupLoading } from '../selectors';
import { fetchRollup } from '../actions/rollup';

import '../components/styles/base.scss';
import './rollup.scss';

class Rollup extends Component {
  static filterForFeatures(features, integrated = true) {
    if (!features) {
      return [];
    }
    // eslint-disable-next-line
    return Object.keys(features).filter((feature) => (integrated ? features[feature] : !features[feature]));
  }

  static propTypes = {
    appIds: PropTypes.instanceOf(Object),
    createDownload: PropTypes.func,
    currentAppId: PropTypes.string,
    currentUserId: PropTypes.string,
    fetchOrganizationApps: PropTypes.func,
    fetchOrganizations: PropTypes.func,
    fetchRollup: PropTypes.func,
    loading: PropTypes.bool,
    role: PropTypes.string,
    organizations: PropTypes.instanceOf(Object),
    rollupData: PropTypes.instanceOf(Object),
    membershipData: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    createDownload: () => ({}),
    currentAppId: '',
    currentUserId: '',
    role: '',
    fetchOrganizationApps: () => ({}),
    fetchOrganizations: () => ({}),
  };

  state = { appsFetched: false };

  componentDidMount() {
    const { currentAppId, currentUserId, organizations } = this.props;

    setPageTitleOnMount('All Projects');

    this.props.fetchOrganizations(currentUserId).then(() => {
      if (organizations && !this.state.appsFetched) {
        const orgs = Object.keys(organizations).map((org) =>
          this.props.fetchOrganizationApps(currentUserId, org, currentAppId),
        );
        Promise.all(orgs).then(() => {
          this.props.fetchRollup();
          // eslint-disable-next-line
          this.setState({ appsFetched: true });
        });
      }
    });
  }

  handleCsvRequest = (ev) => {
    const { currentAppId, currentUserId, appIds } = this.props;
    ev.preventDefault();
    const download = {
      app_id: currentAppId,
      user_id: currentUserId,
      date_requested: new Date(),
      filename: `All App Snapshot ${Date.now()}.csv`,
      filesize: 0,
      parameters: JSON.stringify({
        target_id: currentAppId,
        report_params: {
          apps: appIds.filter(({ platform }) => platform !== 'Web').map((a) => a.id),
          start_date: localDateString(daysAgo(30)),
          end_date: localDateString(new Date()),
        },
      }),
      status: 'running',
      type: 'AppRollup',
    };
    this.props.createDownload(currentUserId, download);
  };

  static renderFeatures(features) {
    if (!features) {
      return '';
    }

    const featureLabelMap = {
      message_center_integrated: 'Message Center',
      rating_dialog_integrated: 'Rating Dialog',
      enjoyment_dialog_integrated: 'Love Dialog',
      survey_integrated: 'Surveys',
      text_modal_integrated: 'Prompts',
    };
    const featureLabels = features.map((feature) => featureLabelMap[feature] || feature);
    return featureLabels.join(', ');
  }

  renderOrg = (org) => {
    if (Array.isArray(this.props.organizations[org.id].apps)) {
      const sortedApps = sortBy(prop('title'), this.props.organizations[org.id].apps);

      return (
        <div className="org" key={org.id}>
          {sortedApps.map(this.renderApp)}
        </div>
      );
    }
    const { currentAppId, currentUserId } = this.props;
    this.props.fetchOrganizationApps(currentUserId, org.id, currentAppId);

    return null;
  };

  renderApp = (app = {}) => {
    const { rollupData, loading, role } = this.props;
    const appData = path([app.id], rollupData) || {};
    const nonInternational = !appData.international_data && app.platform === 'iOS' ? 'non-international' : null;
    const integratedFeatures = Rollup.filterForFeatures(appData.integrated_features);
    const nonintegratedFeatures = Rollup.filterForFeatures(appData.integrated_features, false);
    const loveRatio = appData.love_ratio || 'N/A';
    const mau = Number.isNaN(Number.parseFloat(appData.mau)) ? '0' : numeral(appData.mau).format('0,0');
    const appIcon = app.platform === 'iOS' ? 'itunes' : app.platform.toLowerCase();
    const isReporter = role === AppMemberRole.Reporter;

    let interactions;
    if (Number.isNaN(Number.parseFloat(appData.interactions))) {
      interactions = '0';
    } else {
      interactions = numeral(appData.interactions).format('0,0');
    }

    let currentRating = '—';
    let allRating = '—';
    if (!loading) {
      currentRating = numeral(appData.current_rating).format('0.00');
      allRating = numeral(appData.all_ratings).format('0.00');
    }
    if (app.platform === 'Web') {
      currentRating = 'N/A';
      allRating = 'N/A';
    }

    const title = isReporter ? (
      <span className="non-international-app-unlinked">RATINGS ARE US ONLY</span>
    ) : (
      <Link className="non-international-app" to={`/apps/${app.id}/settings/app_settings`}>
        RATINGS ARE US ONLY
      </Link>
    );

    return (
      <section className="app-summary-container full-width" key={app.id}>
        <header>
          <Link to={`/apps/${app.id}/dashboard`}>
            <img src={app.icon_url} alt={app.title || 'app store icon'} />
          </Link>
          <Link className="title-link link-text" to={`/apps/${app.id}/dashboard`}>
            {app.title}
          </Link>
          <div className={`icon ${appIcon}`} />
          {nonInternational && <>{title}</>}
        </header>
        <div className="app-details">
          <div className="app-stats">
            <div className="stat-tile">
              <div className="statistic">{loading ? '—' : loveRatio}</div>
              Love Ratio
            </div>
            <div className="stat-tile">
              <div className="statistic">{loading ? '—' : mau}</div>
              Monthly Active Users
            </div>
            {app.platform !== 'Web' && (
              <div className="stat-tile">
                <div className={`statistic ${nonInternational}`}>{currentRating}</div>
                {app.platform === 'Android' ? 'Current Period Rating' : 'Current Version Rating'}
              </div>
            )}
            {app.platform !== 'Web' && (
              <div className="stat-tile">
                <div className={`statistic ${nonInternational}`}>{allRating}</div>
                All-Time Rating
              </div>
            )}
            <div className="stat-tile">
              <div className="statistic">{loading ? '—' : interactions}</div>
              Total Interactions
            </div>
          </div>
          <div className="features-list-container">
            <h4>Interaction(s) Integrated</h4>
            {integratedFeatures.length > 0 ? (
              <div className="features-list">
                <div className="icon checkmark">&nbsp;</div>
                <div className="features">{Rollup.renderFeatures(integratedFeatures)}</div>
              </div>
            ) : null}
            {nonintegratedFeatures.length > 0 ? (
              <div className="features-list">
                <div className="icon x">&nbsp;</div>
                <div className="features">{Rollup.renderFeatures(nonintegratedFeatures)}</div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  };

  render() {
    const { organizations = {} } = this.props;
    const sortedOrgs = sortBy(
      prop('name'),
      keys(organizations).map((orgId) => ({
        id: orgId,
        name: organizations[orgId].name,
        apps: organizations[orgId].apps || [],
      })),
    );

    return (
      <div className="rollup">
        <div className="main-content">
          <header className="content-header">
            <h2>All Projects</h2>
            <button className="export-csv-button" onClick={this.handleCsvRequest}>
              EXPORT TO CSV
            </button>
            <p>
              Data is from the past 30 days, recent up to 24 hours ago. Projects show worldwide ratings unless noted.
            </p>
          </header>
          {sortedOrgs.map(this.renderOrg)}
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  appIds: getAppsList(state),
  currentAppId: getCurrentAppId(state),
  currentUserId: getCurrentUserId(state),
  features: getFeatures(state),
  loading: getRollupLoading(state),
  organizations: getOrgs(state),
  rollupData: getRollup(state),
  role: getCurrentAppMembershipRole(state),
});

/* istanbul ignore next */
const mapDispatchToProps = {
  createDownload,
  fetchOrganizations,
  fetchOrganizationApps,
  fetchRollup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rollup);
