import React, { Suspense, SuspenseProps } from 'react';
import { Loading } from '../../molecules';

import './suspense-component.scss';

interface SuspenseComponentProps extends Pick<SuspenseProps, 'children'> {
  fallback?: SuspenseProps['fallback'];
}

export const SuspenseComponent = ({ fallback, children }: SuspenseComponentProps) => (
  <Suspense fallback={fallback || <Loading className="suspense-loading" />}>{children}</Suspense>
);
