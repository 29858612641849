import React, { Component } from 'react';

import './styles/email_preferences.scss';
import '../../components/styles/molecules/tables.scss';
import { EmailFrequency } from '../../types/core';

interface EmailPreferencesProps {
  currentUserId: string;
  email_preferences_reviews: EmailFrequency;
  email_preferences_metrics_alerts: EmailFrequency;
  icon_url: string;
  id: string;
  new_message_notifications: boolean;
  platform: string;
  selected: boolean;
  setSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  unresponded_messages_enabled: boolean;
  unresponded_messages_frequency: number;
  updateUserEmailPreferences: (currentUserId: string, output: { [x: string]: Update }) => void;
}

interface IState {
  new_message_notifications: boolean;
  unresponded_messages_enabled: boolean;
  unresponded_messages_frequency: number;
  email_preferences_reviews: EmailFrequency;
  email_preferences_metrics_alerts: EmailFrequency;
}

// This interface would ideally be [field: keyof IState] however Typescript doesn't seem to allow this currently
interface Update {
  [field: string]: string | boolean | number;
}

class EmailPreferencesRow extends Component<EmailPreferencesProps, IState> {
  static displayName = 'EmailPreferencesRow';

  static defaultProps = {
    currentUserId: '',
    email_preferences_reviews: EmailFrequency.Weekly,
    icon_url: '',
    id: '',
    new_message_notifications: false,
    platform: '',
    selected: false,
    setSelected: () => {},
    title: '',
    unresponded_messages_enabled: false,
    unresponded_messages_frequency: 7,
    updateUserEmailPreferences: () => {},
  };

  state: IState = {
    new_message_notifications: this.props.new_message_notifications || false,
    unresponded_messages_enabled: this.props.unresponded_messages_enabled || false,
    unresponded_messages_frequency: this.props.unresponded_messages_frequency || 7,
    email_preferences_reviews: this.props.email_preferences_reviews || EmailFrequency.Weekly,
    email_preferences_metrics_alerts: this.props.email_preferences_metrics_alerts || EmailFrequency.Unsubscribed,
  };

  handleCheckedChange = (field: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked as boolean;
    const update: Update = {
      [field]: value,
    };
    this.updateStateAndStore(update);
  };

  handleSelectChange = (field: keyof IState) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as string;
    const update: Update = {
      [field]: value,
    };
    this.updateStateAndStore(update);
  };

  handleMessageFrequency = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target.value as unknown) as number;
    if (value > 99) {
      value = 99;
    }
    if (value < 0) {
      value = 0;
    }
    this.updateStateAndStore({ unresponded_messages_frequency: value });
  };

  updateStateAndStore(update: Update) {
    this.setState(update as any);
    this.props.updateUserEmailPreferences(this.props.currentUserId, { [this.props.id]: update });
  }

  renderEmailFrequencySelect(prop: 'email_preferences_metrics_alerts' | 'email_preferences_reviews') {
    return (
      <select name={prop} onChange={this.handleSelectChange(prop)} defaultValue={this.state[prop]}>
        <option disabled value="">
          Select email frequency...
        </option>
        <option value={EmailFrequency.Daily}>Daily</option>
        <option value={EmailFrequency.Weekly}>Weekly</option>
        <option value={EmailFrequency.Unsubscribed}>Unsubscribed</option>
      </select>
    );
  }

  render() {
    const { id, title, platform, icon_url, selected, setSelected } = this.props;
    return (
      <tr>
        <td className="center-align">
          <input
            className="select-app"
            name="select_app[]"
            type="checkbox"
            value={id}
            checked={selected}
            onChange={setSelected}
          />
        </td>
        <td className="icon">
          <img className="app-icon app-icon--ios" alt={title} title={title} src={icon_url} />
        </td>
        <td className="app-title">
          {title} ({platform})
        </td>
        <td className="center-align">
          <div className="row--2col">
            {this.props.platform === 'Web' ? 'N/A' : <div className="col">{this.renderEmailFrequencySelect('email_preferences_reviews')}</div>}
          </div>
        </td>
        <td className="center-align">
          <div className="row--2col">
            <div className="col">{this.renderEmailFrequencySelect('email_preferences_metrics_alerts')}</div>
          </div>
        </td>
        <td className="center-align">
          <input
            className="new_message_notifications"
            name="new_message_notifications"
            type="checkbox"
            checked={this.state.new_message_notifications}
            onChange={this.handleCheckedChange('new_message_notifications')}
          />
        </td>
        <td className="unresponded_messages_enabled center-align">
          <input
            className="inline"
            name="unresponded_messages_enabled"
            type="checkbox"
            checked={this.state.unresponded_messages_enabled}
            onChange={this.handleCheckedChange('unresponded_messages_enabled')}
          />
          Every&nbsp;
          <input
            className="inline"
            name="unresponded_messages_frequency"
            type="number"
            min="0"
            max="99"
            value={this.state.unresponded_messages_frequency}
            disabled={!this.state.unresponded_messages_enabled}
            onChange={this.handleMessageFrequency}
          />
          days
        </td>
      </tr>
    );
  }
}

export default EmailPreferencesRow;
