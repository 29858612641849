import React, { Children } from 'react';
import PropTypes from 'prop-types';

function renderChild(child, index, props) {
  if (!child) {
    return null;
  }
  const { interactive, toggleSelected } = props;
  return React.cloneElement(child, {
    index,
    interactive,
    toggleSelected,
  });
}

const TableBody = (props) => {
  const { children } = props;
  return (
    <tbody>
      {children && Children.map(children, (child, index) => renderChild(child, index, props))}
    </tbody>
  );
};

/* eslint-disable react/no-unused-prop-types */
TableBody.propTypes = {
  children: PropTypes.node,
  interactive: PropTypes.bool,
  toggleSelected: PropTypes.func,
};
/* eslint-enable react/no-unused-prop-types */

TableBody.defaultProps = {
  interactive: false,
  toggleSelected: () => {},
};

export default TableBody;
