import { createSelector } from 'reselect';
import { AppStats, MultiAppsSurvey } from 'src/reducers/surveys-multi-apps';

import { State } from '../reducers';

const withSurveyId = (_: State, surveyId: string) => surveyId;

export const getAllSurveysMultiApps = (state: State): MultiAppsSurvey[] => {
  let res: MultiAppsSurvey[] = [];
  if (state.surveysMultiApps.surveysActive && state.surveysMultiApps.surveysNotActive) {
    res = [...state.surveysMultiApps.surveysActive, ...state.surveysMultiApps.surveysNotActive];
  }
  if (!state.surveysMultiApps.surveysActive && state.surveysMultiApps.surveysNotActive) {
    res = state.surveysMultiApps.surveysNotActive;
  }
  if (state.surveysMultiApps.surveysActive && !state.surveysMultiApps.surveysNotActive) {
    res = state.surveysMultiApps.surveysActive;
  }
  return res;
};

export const getActiveSurveysMultiApps = (state: State): MultiAppsSurvey[] =>
  state.surveysMultiApps.surveysActive ? [...state.surveysMultiApps.surveysActive] : [];
export const getNotActiveSurveysMultiApps = (state: State): MultiAppsSurvey[] =>
  state.surveysMultiApps.surveysNotActive ? [...state.surveysMultiApps.surveysNotActive] : [];
export const getSurveysMultiAppsIsLoading = (state: State, isActive = true): boolean =>
  isActive ? state.surveysMultiApps.loadingActive : state.surveysMultiApps.loadingNotActive;
export const getSurveysMultiAppsIsBootstrapped = (state: State): boolean => state.surveysMultiApps.bootstrapped;

const getLoadingMultiAppsIds = (state: State): string[] =>
  state.surveysMultiApps.loadingIds ? [...state.surveysMultiApps.loadingIds] : [];
export const getActivationInteractionIds = (state: State): string[] =>
  state.surveysMultiApps.updatingSurveyActiveIds || [];
const getStatsMultiApps = (state: State) => state.surveysMultiApps.surveyStatistics;

export const getSurveyById = createSelector([getAllSurveysMultiApps, withSurveyId], (surveys, surveyId) =>
  surveys.find((item) => item.id === surveyId),
);

export const getSurveysMultiAppslWithInteractionTarget = createSelector([getAllSurveysMultiApps], (surveys) =>
  surveys.filter((item) => item.selfTargeting === false),
);

export const getLoadingSurveyV12ById = createSelector([getLoadingMultiAppsIds, withSurveyId], (ids, surveyId) =>
  ids.includes(surveyId),
);

export const getSurveyStats = createSelector(getStatsMultiApps, withSurveyId, (stats, surveyId) => {
  const statistics = stats[surveyId] as { data: AppStats };
  return (statistics?.data || []) as AppStats;
});

export const getIsSurveyActivation = createSelector([getSurveyById, getActivationInteractionIds], (survey, ids) => {
  if (!survey || !survey.interactionData || !ids || ids.length === 0) {
    return false;
  }

  let isSurveyActivation = false;
  const surveyInteractionIdsSet = new Set(survey.interactionData.map((item) => item.interactionId) || []);

  ids.forEach((id) => {
    if (surveyInteractionIdsSet.has(id)) {
      isSurveyActivation = true;
    }
  });

  return isSurveyActivation;
});
