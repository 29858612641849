import React, { Component } from 'react';
import { getApiKeys, createApiKey, deleteApiKey, renameApiKey, revokeApiKey } from 'src/api';
import { ApiKey, ApiKeyType } from 'src/types/api_key';

import { ApiKeyDisplay } from './api_key_display';

interface ApiKeyState {
  apiKeyFetchError: boolean;
  isFetchingApiKeys: boolean;
  apiKeys: ApiKey[];
}

interface ApiKeyProps {
  keyType: ApiKeyType;
  ownerId: string;
  userId: string | undefined;
}

export class ApiKeysContainer extends Component<ApiKeyProps, ApiKeyState> {
  static displayName = 'ApiKeys';

  state: ApiKeyState = {
    apiKeys: [],
    isFetchingApiKeys: true,
    apiKeyFetchError: false,
  };

  static defaultProps: ApiKeyProps = {
    keyType: ApiKeyType.user,
    ownerId: '',
    userId: undefined,
  };

  componentDidMount() {
    this.fetchKeys();
  }

  fetchKeys = async (): Promise<void> => {
    this.setState({ apiKeys: [], isFetchingApiKeys: true, apiKeyFetchError: false });
    try {
      const keys = (await getApiKeys(this.props.ownerId, this.props.keyType));
      const activeKeys = keys.filter(k => !k.expired_at);
      const revokedKeys = keys.filter(k => k.expired_at);
      // Want to show active before revoked, then sort by relevant dates for each (descending)
      const sorted = activeKeys.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        .concat(revokedKeys.sort((a, b) => new Date(b.expired_at ?? '').getTime() - new Date(a.expired_at ?? '').getTime()))
      this.setState({ apiKeys: sorted, isFetchingApiKeys: false });
    } catch (e: any) {
      this.setState({ apiKeys: [], isFetchingApiKeys: false, apiKeyFetchError: true });
    }
  }

  createKey = (description: string): Promise<ApiKey> => createApiKey(this.props.ownerId, this.props.keyType, this.props.userId ?? this.props.ownerId, description);

  renameKey = (keyId: string, description: string): Promise<void> => renameApiKey(keyId, description);

  revokeKey = (keyId: string): Promise<void> => revokeApiKey(keyId);

  deleteKey = (keyId: string): Promise<void> => deleteApiKey(keyId);

  render() {
    return (
      <ApiKeyDisplay
        apiKeys={this.state.apiKeys}
        isLoading={this.state.isFetchingApiKeys}
        isError={this.state.apiKeyFetchError}
        refreshKeysHandler={this.fetchKeys}
        createKeyHandler={this.createKey}
        deleteKeyHandler={this.deleteKey}
        revokeKeyHandler={this.revokeKey}
        renameKeyHandler={this.renameKey}
      />
    );
  }
}

export default ApiKeysContainer;
