import React, { useEffect, useState } from 'react';
import { Modal } from '../molecules';

interface IdleModalProps {
  isOpen: boolean;
  getRemainingTime: () => number;
  stillHereHandler: () => void;
  logoutNowHandler: () => void;
}

const IdleModal: React.FC<IdleModalProps> = ({ isOpen, getRemainingTime, stillHereHandler, logoutNowHandler }) => {
  const [remaining, setRemaining] = useState<number>(Math.ceil(getRemainingTime() / 1000));
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    }
  });

  return (
    <Modal data-testid="inactivity-modal" isOpen={isOpen} title="Are you still here?" className="inactivity-modal" cancelLabel="I'm still here" acceptLabel="Log out now" onCancel={stillHereHandler} onSubmit={logoutNowHandler}>
      <div className="inactivity-modal__message">For security, you will be logged out in {remaining} seconds.</div>
    </Modal>
  );
}

export default IdleModal;
