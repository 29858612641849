import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useDropdownProps } from 'src/hooks';
import { NavLink } from 'react-router-dom';
import { Button, Dialog, IconName } from 'src/components/molecules';
import { bemPrefix, useFeatureSettings } from 'src/utils';
import { isNetlifyStaging } from 'src/utils/environment';
import { Icon } from '../../molecules/icons';

import './alchemer-nav-menu.scss';

const bem = bemPrefix('global-nav');

export enum OptionsName {
  AlchemerSurvey = 'alchemerSurvey',
  AlchemerWorkflow = 'alchemerWorkflow',
  AlchemerDigital = 'alchemerDigital',
  AlchemerPulse = 'alchemerPulse',
}

interface ItemList {
  iconName: IconName;
  title: string;
  link?: string;
  isActive: boolean;
  key: OptionsName;
  route?: string;
}

const AlchemerNavMenu: React.FC<{ appId: string }> = ({ appId }) => {
  const { isOpenDropdown, viewId, closeDropdown, toggleDropdown } = useDropdownProps(false);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const { flag, hasFeature } = useFeatureSettings();

  const isAlchemerSurvey = hasFeature(flag.SURVEY);
  const isAlchemerWorkflow = hasFeature(flag.WORKFLOW);
  const isAlchemerPulse = hasFeature(flag.PULSE);

  const closeAlchemerNavMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, forwardRef: React.RefObject<any>) => {
      if (forwardRef.current && !forwardRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    },
    [],
  );

  const isStagingNewDomain = process.env.HOST_NAME === 'digital.staging.alchemer.com';

  const itemsList: ItemList[] = [
    {
      iconName: 'alchemerSurvey',
      title: 'Alchemer Survey',
      link: isNetlifyStaging() || isStagingNewDomain ? 'https://thrall-app.alchemer.com/' : 'https://app.alchemer.com/',
      isActive: isAlchemerSurvey,
      key: OptionsName.AlchemerSurvey,
      route: 'in-product-survey',
    },
    {
      iconName: 'alchemerWorkflow',
      title: 'Alchemer Workflow',
      link:
        isNetlifyStaging() || isStagingNewDomain
          ? 'https://thrall-app.alchemer.com/index/workflows'
          : 'https://app.alchemer.com/index/workflows',
      isActive: isAlchemerWorkflow,
      key: OptionsName.AlchemerWorkflow,
      route: 'in-product-workflow',
    },
    {
      iconName: 'alchemerDigital',
      title: 'Alchemer Digital',
      isActive: true,
      key: OptionsName.AlchemerDigital,
    },
    {
      iconName: 'alchemerPulse',
      title: 'Alchemer Pulse',
      link: process.env.PULSE_URL,
      isActive: isAlchemerPulse,
      key: OptionsName.AlchemerPulse,
      route: 'in-product-pulse',
    },
  ];

  const renderListItem = useCallback(
    ({ key, isActive, iconName, link, title, route }: ItemList) => {
      if (key === OptionsName.AlchemerDigital) {
        return (
          <NavLink
            className={classNames({
              [bem('product', 'items')]: true,
              'items-active': true,
            })}
            to={`/apps/${appId}/dashboard`}
            data-key={key}
            data-isActive={isActive}
          >
            <Icon name={iconName} className="item" />
            <p>{title}</p>
          </NavLink>
        );
      }
      if (isActive) {
        return (
          <a
            className={classNames({
              [bem('product', 'items')]: true,
              'items-active': true,
            })}
            href={link}
            target="_blank"
            rel="noreferrer"
            data-key={key}
            data-isActive={isActive}
          >
            <Icon name={iconName} className="item" />
            <p>{title}</p>
          </a>
        );
      }
      return (
        <NavLink
          className={classNames({
            [bem('product', 'items')]: true,
          })}
          to={`/apps/${appId}/${route}`}
          data-key={key}
          data-isActive={isActive}
        >
          <Icon name={iconName} className="item" />
          <p>{title}</p>
        </NavLink>
      );
    },
    [itemsList],
  );

  return (
    <div className={bem()}>
      <Button
        className={classNames('grid-nav-menu', { 'grid-nav-menu active': isOpenDropdown })}
        onClick={toggleDropdown}
        forwardRef={targetRef}
      >
        <Icon name="gridNav" className="grid-nav" />
      </Button>
      {isOpenDropdown && (
        <Dialog
          innerRef={dialogRef}
          isOpen={isOpenDropdown}
          viewId={viewId}
          targetEl={targetRef.current}
          onClose={(e) => closeAlchemerNavMenu(e as React.MouseEvent<HTMLDivElement>, targetRef)}
        >
          <>
            <div className={bem('header')}>
              <div className={bem('header', 'title-block')}>
                <Icon name="alchemerLogo" className="alchemer" />
                <p>Digital</p>
              </div>
              <div className={bem('header', 'help-block')}>
                <a href="https://help.alchemer.com/help" target="_blank" rel="noreferrer">
                  NEED HELP?
                </a>
              </div>
            </div>
            <div className={bem('product')}>
              {itemsList.map((option) => (
                <React.Fragment key={option.iconName}>{renderListItem(option)}</React.Fragment>
              ))}
            </div>
          </>
        </Dialog>
      )}
    </div>
  );
};

export default AlchemerNavMenu;
