import React from 'react';
import PropTypes from 'prop-types';

const Filter = ({ name, index, toggleSelected }) => (
  <button
    className={`filter__button--interactive${index !== undefined ? ` filter__button--${index}` : ''}`}
    onClick={() => toggleSelected(index)}
  >
    {name}
  </button>
);

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  toggleSelected: PropTypes.func.isRequired,
};

export default Filter;
