import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProfile } from 'src/selectors/dashboard';
import { DispatchFunc, State } from 'src/reducers';
import { fetchApp } from '../../actions/apps';
import { fetchNotices } from '../../actions';
import { acknowledgeDownloadCenterWelcome, updateUIState } from '../../actions/session';
import { fetchOrganization } from '../../actions/organizations';

import { getCurrentOrg } from '../../selectors/organizations';
import {
  getCurrentUserId,
  getFeatures,
  getHasDownloadedFsExport,
  getHasSeenDownloadCenter,
  getUiNumNewDownloads,
} from '../../selectors/user';
import {
  getUiDownloadsToastVisible,
  getUiDownloadsToastMessage,
  getDisplayDownloadCenterWelcome,
} from '../../selectors/ui';
import {
  getCurrentOrgId,
  getCurrentApp,
  getCurrentAppId,
  getCurrentAppIcon,
  getCurrentAppMembershipRole,
} from '../../selectors/current_app';
import { getNotifications, getNotificationsFetched } from '../../selectors/notifications';
import { personAvatar } from '../../utils/person_helpers';
import HeaderDisplay from './presentational/presentational';

interface IMapStateToProps {
  appIconUrl: string;
  appId: string;
  avatarUrl: string;
  currentApp: Record<string, any>;
  currentOrg: Record<string, any>;
  currentUserId: string;
  displayDownloadCenterWelcome: boolean;
  downloadsToastMessage: { msg: string; stats: string };
  downloadsToastVisible: boolean;
  features: string[];
  hasSeenDownloadCenter: boolean;
  notifications: { id: string }[];
  notificationsFetched: boolean;
  numNewDownloads: number;
  orgId: string;
  isUserLoading: boolean;
  hasNotDownloadedFsExport: boolean;
  role: string;
}

interface IMapDispatchToProps {
  acknowledgeDownloadCenterWelcome: () => void;
  appClick: () => void;
  fetchApp: (appId: string) => void;
  fetchNotices: (userId: string) => void;
  fetchOrganization: (orgId: string) => void;
  updateUIState: (key: string, value: string) => void;
  handleMouseLeave: () => void;
}

type IProps = IMapStateToProps & IMapDispatchToProps;

class Header extends Component<IProps> {
  componentDidMount() {
    const { appId, currentApp, currentOrg, notificationsFetched, currentUserId } = this.props;
    const { title } = currentApp || {};

    if (!title) {
      this.props.fetchApp(appId);
    }
    if (!currentOrg.name && this.props.orgId) {
      this.props.fetchOrganization(this.props.orgId);
    }
    if (!notificationsFetched) {
      this.props.fetchNotices(currentUserId);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.orgId && prevProps.orgId !== this.props.orgId) {
      this.props.fetchOrganization(this.props.orgId);
    }
    if (this.props.appId && prevProps.appId !== this.props.appId) {
      this.props.fetchApp(this.props.appId);
    }
  }

  render() {
    const { currentApp, currentOrg } = this.props;
    const { title, platform } = currentApp || {};
    const { name } = currentOrg || {};
    return (
      <HeaderDisplay
        acknowledgeDownloadCenterWelcome={this.props.acknowledgeDownloadCenterWelcome}
        appClick={this.props.appClick}
        appIconUrl={this.props.appIconUrl}
        appId={this.props.appId}
        appTitle={title}
        avatarUrl={this.props.avatarUrl}
        currentUserId={this.props.currentUserId}
        displayDownloadCenterWelcome={this.props.displayDownloadCenterWelcome}
        downloadsToastVisible={this.props.downloadsToastVisible}
        handleMouseLeave={this.props.handleMouseLeave}
        hasSeenDownloadCenter={this.props.hasSeenDownloadCenter}
        icon={this.props.avatarUrl}
        mode={''}
        notifications={this.props.notifications}
        orgName={name}
        platform={platform}
        downloadsToastMessage={this.props.downloadsToastMessage}
        numNewDownloads={this.props.numNewDownloads}
        isUserLoading={this.props.isUserLoading}
        hasNotDownloadedFsExport={this.props.hasNotDownloadedFsExport}
        updateUIState={this.props.updateUIState}
        role={this.props.role}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  appIconUrl: getCurrentAppIcon(state),
  appId: getCurrentAppId(state),
  avatarUrl: personAvatar(state.user),
  currentApp: getCurrentApp(state),
  currentOrg: getCurrentOrg(state),
  currentUserId: getCurrentUserId(state),
  displayDownloadCenterWelcome: getDisplayDownloadCenterWelcome(state),
  downloadsToastMessage: getUiDownloadsToastMessage(state),
  downloadsToastVisible: getUiDownloadsToastVisible(state),
  features: getFeatures(state),
  hasSeenDownloadCenter: getHasSeenDownloadCenter(state),
  notifications: getNotifications(state),
  notificationsFetched: getNotificationsFetched(state),
  numNewDownloads: getUiNumNewDownloads(state),
  orgId: getCurrentOrgId(state),
  isUserLoading: getProfile(state).updateUserLoading,
  hasNotDownloadedFsExport: getHasDownloadedFsExport(state),
  role: getCurrentAppMembershipRole(state),
});

let appClickTimeout: NodeJS.Timeout | null;
const handleAppClick = (dispatch: DispatchFunc) => () => {
  appClickTimeout = setTimeout(() => dispatch({ type: 'APP_DRAWER_TOGGLE' }), 200);
};
const resetDelta = () => {
  appClickTimeout && clearTimeout(appClickTimeout);
};

const mapDispatcherToProps = (dispatch: DispatchFunc) => ({
  acknowledgeDownloadCenterWelcome: () => dispatch(acknowledgeDownloadCenterWelcome()),
  appClick: handleAppClick(dispatch),
  handleMouseLeave: resetDelta,
  fetchApp: (appId: string) => {
    dispatch(fetchApp(appId));
  },
  fetchOrganization: (orgId: string) => {
    dispatch(fetchOrganization(orgId));
  },
  fetchNotices: (userId: string) => {
    dispatch(fetchNotices(userId));
  },
  updateUIState: (key: string, value: string) => dispatch(updateUIState(key, value)),
});

const HeaderContainer = connect(mapStateToProps, mapDispatcherToProps)(Header);

export default HeaderContainer;
